import React, { useState, useEffect, useRef } from "react";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import summaryImg from "../../Images/Frame.png";
import "./Css/SummaryTabs.css";
import { saveAs } from 'file-saver';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from "@mui/material/Checkbox";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField'
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import axios from 'axios'
import Dialog from '@mui/material/Dialog';
import { ToastContainer, toast } from 'react-toastify';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SignaturePad from 'react-signature-canvas'
import 'react-toastify/dist/ReactToastify.css';
import spinner from '../../Images/zurich.gif'
import awardIcon from '../../Images/award.png'
import ShowPdf from './ShowPdf'
import awardIconWhite from '../../Images/awardWhite.png'
import OutlinedInput from '@mui/material/OutlinedInput';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import BasicDocument from './BasicDocument'
import banknote from '../../Images/banknote.png'
import banknoteBlue from '../../Images/banknoteBlue.png'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactPDF, {
  PDFViewer,
  usePDF,
  pdf,
  PDFDownloadLink,
  Document, Page, StyleSheet, View, Image
} from "@react-pdf/renderer";
import {
  RadioGroup,
  Radio,
  FormLabel,
  Accordion,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  Divider,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { useSelector, useDispatch } from 'react-redux';
import * as DOMPurify from 'dompurify';
import { tab } from "@testing-library/user-event/dist/tab";
import { refreshSession } from "@okta/okta-auth-js";

const SummaryTabs = ({ isReGenerateSoluation, isFormSubmited, isClosedSession, selectedCurrency, _setPageID, _navigateToDashboard, _setProductDetails }: any) => {
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  let ocmKey = process.env.REACT_APP_OCM_KEY;
  let zproURL = process.env.REACT_APP_ZPRO_ENDPOINT;
  let zeproQuote = process.env.REACT_APP_QUOTE;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const agenciesList = useSelector((state: any) => state.genericDetails.agenciesList);

  const [activeTab, setActiveTab] = useState(1);
  const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);
  const [showPdf, setShowPdf] = useState(false)
  const [showGenerateQuoteDialog, setShowGenerateQuoteDialog] = useState(false)
  let navigate = useNavigate();
  const [isShowSignature, showSignature] = useState(false)
  const [investmentData, setInvestmentData] = React.useState<any>([])
  const [protactionData, setProtactionData] = React.useState<any>([])
  const [totalAmount, setTotalAmount] = React.useState<any>(0)
  const [investmentDataProtaction, setInvestmentDataProtaction] = React.useState<any>([])
  const [protactionDataProtaction, setProtactionDataProtaction] = React.useState<any>([])
  const [selectedSoluation, setSelectedSoluation] = React.useState('ShowSelectedSolution')
  const [selectedAgencies, setSelectedAgencies] = React.useState<any>()
  const [espSoluation, setEspSoluation] = React.useState('SelectAll')
  const [investmentDataSavings, setInvestmentDataSavings] = React.useState<any>([])
  const [protactionDataSavings, setProtactionDataSavings] = React.useState<any>([])
  const [investmentDataEducation, setInvestmentDataEducation] = React.useState<any>([])
  const [protactionDataEducation, setProtactionDataEducation] = React.useState<any>([])
  const [soluationDetails, setSoluationDetails] = React.useState<any>()
  const [opprtunityID, setOpprtunityId] = React.useState<any>()
  const [totalBudgetAmount, setTotalBudgetAmount] = React.useState<any>(0)
  const [budgetData, setBudgetData] = React.useState<any>({})
  const [showNotes, setShowNotes] = React.useState<boolean>(true)
  const [showSign, setShowSign] = React.useState<boolean>(true)
  const [productsData, setproductsData] = React.useState<any>()
  const [isEsp, setIsEsp] = React.useState<boolean>(false)
  const [espProduct, setEspProduct] = useState<any>([]);
  const [allproductsData, setAllproductsData] = React.useState<any>()
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const [showSpinner1, setShowSpinner1] = React.useState<boolean>(false)
  const [selectedSolution, setSelectedSolution] = useState<string>("");
  const [showSolution, setShowSolution] = useState(false);
  const [cartDetails, setCartDetails] = useState<any>([]);
  const [disabledQuoteButton, setDisabledQuoteButton] = useState<boolean>(isClosedSession === true);
  const [isQuoteDetailsAvailable, setIsQuoteAvailable] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')
  const [couserName, setcoUserName] = useState<string>('')
  const [illustrationDetails, setIllustrationDetails] = React.useState({})
  const [notesValue, setNotesValue] = React.useState<string>('')
  const [signDetails, setSignDetails] = React.useState<any>()
  const [signDetails1, setSignDetails1] = React.useState<any>()
  const [signDetails2, setSignDetails2] = React.useState<any>()
  const [clientSign, setClientSign] = React.useState<any>()
  const [advisorSign, setAdvisorDetail1] = React.useState<any>()
  const [coappSign, setCoappDetail] = React.useState<any>()
  const [applicantType, setApplicantType] = React.useState<string>('life1Sign')
  const [quotesDetails, setQuotesDetails] = React.useState([])
  const [selectedProduct, setSelectedProduct] = React.useState([])
  const [selectedUserDetails, setSelectedUserDetails] = React.useState<boolean>(false)
  const [idToken, setIdToken] = React.useState<string>('')
  const [reportData, setReportData] = React.useState()
  const [isSolutionGenerated, setIsSoluationGenerated] = React.useState<boolean | null>(null)
  const [blobDetails, setBlobDetails] = React.useState<any>()
  const countryDialCodeList = useSelector((state: any) => state.genericDetails.countryDialCodeList);
  const [storeNotesValue, setStoreNotesValue] = React.useState<string>();
  const [signDate, setSignDate] = React.useState<string>()
  const [reportUrl, setReportUrl] = React.useState<string>('')
  const [availableBudget, setAvailableBudget] = React.useState<number>()
  const [selectedRiskDetails, setSelectedRiskDetails] = React.useState({})
  const [espDisable, setEspDisable] = React.useState<boolean>(false)
  const [getDependents, setDependents] = React.useState<any>([])
  const [variantName, setVariantName] = React.useState<any>([])
  const [espillustrationDetails, setEspillustrationDetails] = useState<any>([])
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false)
  const [childUniversityList, setChildUniversityList] = React.useState<any>([])
  const [listOfESPProduct, setListOfESPProduct] = React.useState<any>([])
  const [childEspDialogOpen, setChildEspDialogOpen] = React.useState<boolean>(false)
  const [showSendSpinner, setShowSendSpinner] = React.useState<boolean>(false)
  const [isValidPdf, setIsValidPdf] = React.useState<boolean>(false)
  const [closedPdfData, setClosedPdfData] = React.useState<any>()
  let signPad = useRef()

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    if (tabNumber === 4)
      setChildEspDialogOpen(childUniversityList?.length > listOfESPProduct?.length && listOfESPProduct?.length > 0)
    setActiveTab(tabNumber);
    setShouldRefresh(true)
  };
  useEffect(() => {
    if (selectedAgencies && selectedAgencies !== '0') {
      let payload = {
        "sessionId": sessionStorage.getItem('sessiondetail'),
        "agencyNumber": selectedAgencies
      }
      const response = axios.post(baseUrl + '/users/selected/agency', payload, requestOptions)
        .then((response) => {

          // setShowNotes(false)
        }).catch(error => {
          setNotesDialogOpen(false)
          setShowSpinner1(false)
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        })
    }

  }, [selectedAgencies])
  useEffect(() => {

    if (isClosedSession && reportUrl) {
      _getClosedPdfData()
      //await fileReder(reportUrl, 'sendMail')

    }
  }, [isClosedSession, reportUrl])

  const _getClosedPdfData = async () => {
    try {
      const reportpdfUrl1 = reportUrl?.toString().replace(/fileType=download$/, "fileType=base64")
      const reportpdfUrl = reportpdfUrl1.toString().replace('http', 'https')
      const bearerToken = localStorage.getItem('bearerToken')
      const headers = {
        Authorization: `Bearer ${bearerToken}`,
      };
      const requestOptions = {
        headers: headers,
      };
      const response = await axios.get(reportpdfUrl ? reportpdfUrl : '', requestOptions);
      const responseDetails = await response?.data;
      if (responseDetails?.data?.length > 100) {
        setIsValidPdf(true)
        setClosedPdfData(responseDetails?.data)
      } else {
        setIsValidPdf(false)
      }
    } catch (error) {
      setIsValidPdf(false)
    }
  }
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const filterdValue = value.filter(
      (item: any) => variantName.findIndex((o: any) => o.goalId === item.goalId) >= 0
    );

    let duplicatesRemoved = value.filter((item: any, itemIndex: any) =>
      value.findIndex((o: any, oIndex: any) => o.goalId === item.goalId && oIndex !== itemIndex)
    );
    let duplicateRemoved: any = [];

    value.forEach((item: any) => {
      if (duplicateRemoved.findIndex((o: any) => o.goalId === item.goalId) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x: any) => x.goalId === item.goalId);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setVariantName(duplicateRemoved);
  };

  const handleEspArraySubmit = async () => {
    setShowSpinner(true)
    setSoluationDetails({})
    if (espProduct?.length > 0) {
      let espArray = espProduct?.map((element: any) => {
        let matchedArray = variantName?.filter((selectedElement: any) => selectedElement?.goalId === element?.goalId)
        let obj = { ...element }
        if (matchedArray?.length > 0) { obj.isSelected = true } else { obj.isSelected = false }
        return obj
      })
      let payload = {
        sessionId: sessionStorage.getItem('sessiondetail'),
        esps: [...espArray],
        solutionType: "p00" + activeTab
      }
      setShouldRefresh(false)
      setSelectedSoluation("")
      try {
        const response = await axios.post(baseUrl + '/calculate/v1/solutions/recalculate', payload, requestOptions).
          then((response) => {
            setShouldRefresh(true)
            setSelectedSoluation("ShowSelectedSolution")
            setSoluationDetails({ ...response.data.outPutData })
            if (response?.data?.advisorSign || response?.data?.life1Sign || response?.data?.life2Sign) {
              setClientSign(response?.data?.life1Sign?.base64 ? response?.data?.life1Sign?.base64 : null)
              setAdvisorDetail1(response?.data?.advisorSign?.base64 ? response?.data?.advisorSign?.base64 : null)
              setCoappDetail(response?.data?.life2Sign?.base64 ? response?.data?.life2Sign?.base64 : null)
              setShowSign(false)
            } else {
              setShowSign(true)
            }
            setAvailableBudget(response?.data?.availableMonthlyBudget)
            if (response?.data?.factFindReport) {
              setReportUrl(response?.data?.factFindReport)
            }
            if (response?.data?.selectedSolution) {
              let tabId = (response?.data?.selectedSolution?.charAt(response?.data?.selectedSolution?.length - 1))
              setActiveTab(Number(tabId))
              setShowSolution(true)
              setShouldRefresh(true)
              if (tabId === 4) {
                setChildEspDialogOpen(childUniversityList?.length > listOfESPProduct?.length && listOfESPProduct?.length > 0)
                handleSelectSolution("Education Focused")
              } else if (tabId === 1) {
                handleSelectSolution("Recommended Solution")
              } else if (tabId === 2) {
                handleSelectSolution("Protection Focused")
              } else if (tabId === 3) {
                handleSelectSolution("Savings Focused")
              }

            }
            if (response?.data?.factFindNote) {
              //setShowNotes(false)
              setNotesValue(response?.data?.factFindNote)
            }
            else {
              setShowNotes(true)
            }
            setSelectedProduct(response?.data?.selectedProducts?.products)
            if (response?.data?.isQuoteGenerated === true) {
              setIsQuoteAvailable(true)
              setQuotesDetails(response?.data?.quotes)
            } else {
              setIsQuoteAvailable(false)
            }
            setOpprtunityId(response?.data?.opportunityId)

          }).catch(error => {
            setShowSpinner(false)
            toast.error('Something went wrong please try later!', {
              position: toast.POSITION.BOTTOM_CENTER
            });
          })

        setShowSpinner(false)
      } catch (error) {
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        setShowSpinner(false)
      }
    }
  }
  const roundOffAndFormat = (res: any) => {
    const comma = res.toString().replace(/,/g, '')
    const roundNum = Math.round(Number(comma))
    return roundNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
    let { name, value } = e.target

    setNotesValue(value)
  }
  const getRspQuoteResponse = async () => {
    let isRSPProduct = cartDetails?.filter((element: any) => ((element?.name === 'Regular Saving Plan' || element?.name === 'GlobalChoice') && element !== null))
    if (isRSPProduct?.length > 0) {
      let details = cartDetails?.filter((element: any) => element !== null)
      //Call API to get the Benefit Illustration Details
      const rspQuoteResponse = await axios.post(baseUrl + '/pricingEngine/getRSPBenefitIllustrations', { products: details });
      const data = rspQuoteResponse.data;
      //setRspQuoteResponse(data)
      if (data && data.BITableValues &&
        data.BITableValues.illustrationTableDict) {
        let growthRate = Number(data.inputDict['Growth Rate']);
        let keyName = growthRate / 100;
        let identifyIllustrationAsGrowthRate = data.BITableValues.illustrationTableDict[keyName];
        setIllustrationDetails(identifyIllustrationAsGrowthRate);
      }
      sendProductDetails()
    } else {
      setIllustrationDetails({})
      sendProductDetails()
    }
  }

  const sendProductDetails = async () => {
    if (cartDetails) {
      let prodDetails = cartDetails?.filter((element: any) => { return (element !== null && element !== '' && element !== undefined && JSON.stringify(element) !== '{}') })
      const payload = {
        solutionType: 'p00' + activeTab,
        sessionId: sessionStorage.getItem('sessiondetail'),
        products: prodDetails
      };
      try {
        const response = await axios.post(baseUrl + '/calculate/generate-report', payload, requestOptions);
        setReportData(response?.data)
        let espProd = response?.data?.products?.filter((element: any) => element?.name === 'Education Saving Plan')
        if (espProd?.length > 0) {
          let espbiTable: any = []
          espProd?.forEach((element: any) => {
            if (element?.biTable?.illustrationTableDict) {
              let growthRate = Number(element?.growthRate);
              let keyName = growthRate / 100;
              let identifyIllustrationAsGrowthRate = element?.biTable?.illustrationTableDict[keyName];
              identifyIllustrationAsGrowthRate.dependentName = element?.dependentName;
              espbiTable.push(identifyIllustrationAsGrowthRate)
            }
          })
          setEspillustrationDetails(espbiTable)
        }

        setShowPdf(true)
        setShowSpinner1(false)
      } catch (error) {
        setShowPdf(false)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        setShowSpinner1(false)
      }

    }
  }
  const saveNotes = async () => {
    setShowSpinner1(true)
    let details = {
      "sessionId": sessionStorage.getItem('sessiondetail'),
      "note": notesValue
    }
    setStoreNotesValue(notesValue)
    const response = await axios.post(baseUrl + '/customers/update-factfind-report-note', details, requestOptions)
      .then((response) => {
        setNotesDialogOpen(false)
        setShowSpinner1(false)
        toast.success('Notes added successfully!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        // setShowNotes(false)
      }).catch(error => {
        setNotesDialogOpen(false)
        setShowSpinner1(false)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }
  const _getDocument = () => {
    return <BasicDocument
      reportData={reportData}
      espillustrationDetails={espillustrationDetails}
      clientSign={clientSign}
      advisorSign={advisorSign}
      signDate={signDate}
      coappSign={coappSign}
      activeTab={activeTab}
      notesValue={storeNotesValue}
      espProducts={listOfESPProduct}
      countryDialCodeList={countryDialCodeList}
      illustrationDetails={illustrationDetails} />
  }

  useEffect(() => {
    if (isClosedSession === false || (isClosedSession && isValidPdf === false)) {
      const getReport = async () => {
        setShowSpinner1(true);
        const doc = blobDetails;
        await fileReder(doc, 'storeReport')
      };
      if (blobDetails) {
        getReport();
      }
    }
  }, [blobDetails])
  const sendReport = async () => {
    setShowSendSpinner(true)
    if (isClosedSession === false) {
      const doc = blobDetails;
      await fileReder(doc, 'sendMail')
    } else {
      if (isClosedSession && reportUrl && isValidPdf) {
        //await fileReder(reportUrl, 'sendMail')
        try {
          const details = {
            sessionId: sessionStorage.getItem('sessiondetail'),
            pdf: closedPdfData
          };
          sendMail(details);
        } catch (error) {
          setShowSendSpinner(false)
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      }
    }
  };

  const fileReder = (fileDetail: any, action: string) => {
    const fr = new FileReader()
    fr.readAsDataURL(fileDetail)
    fr.addEventListener('load', () => {
      const result = fr.result;
      if (action === 'sendMail') {
        const details = {
          sessionId: sessionStorage.getItem('sessiondetail'),
          pdf: result
        };
        sendMail(details);
      } else {
        const details = {
          sessionId: sessionStorage.getItem('sessiondetail'),
          pdfFile: result
        };
        sendPdfToBackend(details);
      }
    })
  }
  const sendMail = async (details: {}) => {
    const response = await axios.post(baseUrl + '/customers/send-report-mail-base64', details, requestOptions)
      .then((response) => {
        setShowSendSpinner(false)
        toast.success('Email sent successfully!', {
          position: toast.POSITION.BOTTOM_CENTER
        });

      }).catch(error => {
        setShowSendSpinner(false)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }

  const sendPdfToBackend = async (details: {}) => {
    const response = await axios.post(baseUrl + '/customers/store/report-pdf/base64', details, requestOptions)
      .then((response) => {
        setShowSpinner1(false)
      }).catch(error => {
        setShowSpinner1(false)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }
  React.useEffect(() => {
    fetchData();
  }, [])
  React.useEffect(() => {
    setShowSpinner(true)
    if (isSolutionGenerated !== null && isSolutionGenerated !== undefined) {
      let id = sessionStorage.getItem('sessiondetail')
      let url = baseUrl + '/calculate/v1/solutions?id=' + id
      if (isReGenerateSoluation === true) {
        url = baseUrl + '/calculate/v1/solutions?id=' + id + '&type=regenerate'
      }
      axios.get(url, requestOptions).then((response: any) => {
        setShowSpinner(false);
        if (response?.data?.advisorSign || response?.data?.life1Sign || response?.data?.life2Sign && (response?.data?.advisorSign !== '' && response?.data?.life1Sign !== '' && response?.data?.life2Sign !== '')) {
          setClientSign(response?.data?.life1Sign?.base64 ? response?.data?.life1Sign?.base64 : null)
          setAdvisorDetail1(response?.data?.advisorSign?.base64 ? response?.data?.advisorSign?.base64 : null)
          setCoappDetail(response?.data?.life2Sign?.base64 ? response?.data?.life2Sign?.base64 : null)
          setShowSign(false)
        } else {
          setShowSign(true)
        }
        setAvailableBudget(response?.data?.availableMonthlyBudget)
        if (response?.data?.factFindReport) {
          setReportUrl(response?.data?.factFindReport)
        }
        if (response?.data?.selectedSolution) {
          let tabId = (response?.data?.selectedSolution?.charAt(response?.data?.selectedSolution?.length - 1))
          if (tabId === 4) {
            setChildEspDialogOpen(childUniversityList?.length > listOfESPProduct?.length && listOfESPProduct?.length > 0)
          }
          setActiveTab(Number(tabId))
          setShouldRefresh(true)
        }
        if (response?.data?.selectedAgency) {
          setSelectedAgencies(response?.data?.selectedAgency)
        } else {
          let agencyValue = agenciesList?.length > 0 ? agenciesList[0]['agentNumber'] : '0'
          setSelectedAgencies(agencyValue)
        }
        if (response?.data?.factFindNote) {
          //setShowNotes(false)
          setNotesValue(response?.data?.factFindNote)
        }
        else {
          setShowNotes(true)
        }
        setSelectedProduct(response?.data?.selectedProducts?.products)
        if (response?.data?.isQuoteGenerated === true) {
          setIsQuoteAvailable(true)
          setQuotesDetails(response?.data?.quotes)
        } else {
          setIsQuoteAvailable(false)
        }
        setOpprtunityId(response?.data?.opportunityId)
        setSoluationDetails(response.data.outPutData)
      }).then((error: any) => {
        setShowSpinner(false)
      })
    }
  }, [selectedCurrency, isSolutionGenerated, isReGenerateSoluation])
  useEffect(() => {
    if (soluationDetails?.p001?.dataset?.length > 0) {
      let investment: any[] = [];
      let protaction: any[] = [];
      soluationDetails?.p001?.dataset?.forEach((element: any) => {
        if (element.type === "investment") {
          investment.push(element)

        } else {
          protaction.push(element)
        }
      })
      setInvestmentData([...investment])
      setProtactionData([...protaction])
    }
    if (soluationDetails?.p002?.dataset?.length > 0) {
      let investment: any[] = [];
      let protaction: any[] = [];
      soluationDetails?.p002?.dataset?.forEach((element: any) => {
        if (element.type === "investment") {
          investment.push(element)

        } else {
          protaction.push(element)
        }
      })
      setInvestmentDataProtaction([...investment])
      setProtactionDataProtaction([...protaction])
    }
    if (soluationDetails?.p003?.dataset?.length > 0) {
      let investment: any[] = [];
      let protaction: any[] = [];
      soluationDetails?.p003?.dataset?.forEach((element: any) => {
        if (element.type === "investment") {
          investment.push(element)

        } else {
          protaction.push(element)
        }
      })
      setInvestmentDataSavings([...investment])
      setProtactionDataSavings([...protaction])
    }
    if (soluationDetails?.p004?.dataset?.length > 0) {
      let investment: any[] = [];
      let protaction: any[] = [];
      soluationDetails?.p004?.dataset?.forEach((element: any) => {
        if (element.type === "investment") {
          investment.push(element)

        } else {
          protaction.push(element)
        }
      })
      soluationDetails?.p004?.products?.length > 0 ? setEspDisable(false) : setEspDisable(true)
      setInvestmentDataEducation([...investment])
      setProtactionDataEducation([...protaction])
    }
    let tokenDetails = localStorage.getItem('okta-token-storage')
    let idTokenDetail = tokenDetails ? JSON.parse(tokenDetails) : {}
    setIdToken(idTokenDetail?.idToken?.idToken)
  }, [soluationDetails])


  useEffect(() => {
    if (selectedSolution === 'Recommended Solution') {
      getDetails(soluationDetails?.p001)
      setTotalBudgetAmount((soluationDetails?.p001?.budget?.fulFillment?.totalBudgetAmount) ? (soluationDetails?.p001?.budget?.fulFillment?.totalBudgetAmount).toFixed(2) : 0)
    } else if (selectedSolution === 'Protection Focused') {
      getDetails(soluationDetails?.p002)
      setTotalBudgetAmount((soluationDetails?.p002?.budget?.fulFillment?.totalBudgetAmount) ? (soluationDetails?.p002?.budget?.fulFillment?.totalBudgetAmount).toFixed(2) : 0)
    } else if (selectedSolution === 'Savings Focused') {
      getDetails(soluationDetails?.p003)
      setTotalBudgetAmount((soluationDetails?.p003?.budget?.fulFillment?.totalBudgetAmount) ? (soluationDetails?.p003?.budget?.fulFillment?.totalBudgetAmount).toFixed(2) : 0)
    }
    else {
      getDetails(soluationDetails?.p004)
      setTotalBudgetAmount((soluationDetails?.p004?.budget?.fulFillment?.totalBudgetAmount) ? (soluationDetails?.p004?.budget?.fulFillment?.totalBudgetAmount).toFixed(2) : 0)
    }


  }, [selectedSolution, soluationDetails])

  useEffect(() => {
    let quotes = getQuotesArray(quotesDetails)
    if (quotes?.length > 0) {
      setDisabledQuoteButton(true)
      let array: any = [];
      quotes?.map((quoteElement: any) => {
        let cartArray = productsData?.map((element: any) => {
          if (element?.name === quoteElement?.Name && ((element?.name === 'Education Saving Plan' && element?.goalId === quoteElement?.goalId) || (element?.name !== 'Education Saving Plan' && quoteElement?.goalId === ''))) {
            let elementObject = { ...element }
            elementObject.application_ID = quoteElement?.application_ID
            elementObject.referenceID = quoteElement?.referenceID
            elementObject.referencePDF = quoteElement?.referencePDF
            elementObject.agency_Number = quoteElement?.agency_Number
            elementObject.productCode = quoteElement?.productCode
            elementObject.product_Version = quoteElement?.product_Version
            array.push(elementObject)
            setCartDetails(array)
            return elementObject
          }
        })
      })
    }
    else if (selectedProduct?.length > 0 && isClosedSession) {
      setCartDetails(selectedProduct)
    }
    else {
      setDisabledQuoteButton(false)
    }
  }, [productsData, quotesDetails])

  const getDetails = (detailsObject: any) => {
    if (detailsObject?.budgetData) {
      setBudgetData(detailsObject?.budgetData)
    }

    if (detailsObject?.products) {
      let espProducts = detailsObject?.products?.filter((element: any) => element?.name === 'Education Saving Plan')
      setListOfESPProduct(espProducts)
      setIsEsp(detailsObject?.isESPMax)
      if (detailsObject?.isESPMax) {
        setEspProduct(detailsObject?.espProducts)
        const espProducts = detailsObject?.espProducts?.filter((element: any) => element.isSelected == true)
        setVariantName(espProducts)
      }
      setproductsData(detailsObject?.products)
    }
    if (detailsObject?.allProducts) {
      let array: any[] = []
      detailsObject?.allProducts?.forEach((element: any) => {
        const filtered = detailsObject?.products?.filter((product: any) => (product.name === element.name));
        if (filtered?.length === 0) {
          array.push(element)
        }
      });
      setAllproductsData(array);
    }
  }

  function handleSelectSolution(tabTitle: React.SetStateAction<string>) {
    setSelectedSolution(tabTitle);
    setShowSolution(true);
  }
  useEffect(() => {
    let sum = Math.round((cartDetails.reduce((accumulator: number, object: any) => { return Number(accumulator + Number(object?.monthlyPremium)); }, 0)))
    let formatsum = (sum)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    setTotalAmount(formatsum)
  }, [cartDetails])
  const handleAdd = (item: any, index: any) => {
    let cartDetailsArray = [...cartDetails]
    let array = []
    let cartprod = cartDetailsArray?.filter((element: any) => element?.fullName === item?.fullName && element?.dependentName === item?.dependentName)
    if (cartprod?.length <= 0) {
      cartDetailsArray.push({ ...item })
    }
    setCartDetails(cartDetailsArray)
  };

  const handleDelete = (index: number) => {
    if (!isClosedSession) {
      const updatedHeadings = [...cartDetails];
      updatedHeadings.splice(index, 1);
      setCartDetails(updatedHeadings);
    }
  };
  const _salesForceAPI = async (details: any) => {
    const response = await axios.post(baseUrl + '/salesforce/pushRecommendedProductsToSF', details, requestOptions)
      .then((response) => response.data).catch(error => {
      })
    return response
  }

  const getQuotesArray = (quotesArray: any) => {
    let quotes = quotesArray?.filter((element: any) => element !== null && element !== undefined && element !== '')
    return quotes;
  }
  const _generateQuoteId = async (details: any) => {
    setShowSpinner1(true)

    const headers = {
      'Ocp-Apim-Subscription-Key': ocmKey || '',
      Authorization: `Bearer ${bearerToken}`,
    };
    const requestOptions = {
      headers: headers,
    };
    const response = await axios.post(zproURL + '/zproIntegration/getquote', details, requestOptions)
      .then((response) => {
        setDisabledQuoteButton(true)
        let responseData = response.data
        setQuotesDetails(responseData)
        responseData?.map((element: any) => {
          setTimeout(() => {
            window.open(element?.referencePDF, '_blank');
          }, 2000)
        })
        setShowSpinner1(false)
        toast.success('Quote id generated successfully!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }).catch(error => {
        setShowSpinner1(false)
        setDisabledQuoteButton(false)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }

  const goToDocumentPage = () => {
    if (isClosedSession === false || (isClosedSession && isValidPdf === false)) {
      setShowSpinner1(true)
      let detailsList = cartDetails?.filter((element: any) => element !== null)
      getRspQuoteResponse()
      let obj = { 'opportunityId': opprtunityID, 'products': detailsList }
      _salesForceAPI(obj)
    } else {
      setShowPdf(true)
    }

  }

  const sendPdf = async (data: any) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
    };
    const requestOptions = {
      method: 'POST',
      headers: headers,
    };
    const obj = {
      pdfFile: data,
      sessionId: sessionStorage.getItem('sessiondetail')
    }
    const response = await axios.post(baseUrl + `/customers/storeGeneratedPdf`, obj, requestOptions)
      .then((response) => {
        toast.success('File Uploaded successfully!', {
          position: toast.POSITION.BOTTOM_CENTER
        });

      }).catch(error => {
        //_setPageID(2)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }
  const togglePDF = () => {
    setShowPdf(!showPdf)
  }
  const toggleGenerateQuote = () => {
    setShowGenerateQuoteDialog(!showGenerateQuoteDialog)
  }

  const generateQuoteId = () => {
    setShowGenerateQuoteDialog(true)
  }

  const continueGetQuote = (continueGetQuote: boolean) => {
    if (continueGetQuote === true && selectedAgencies !== '0') {
      setShowGenerateQuoteDialog(false)
      const detailsObj = localStorage.getItem('userDetails')
      const parseDetails: any = detailsObj && JSON.parse(detailsObj);
      let obj = {
        "sessionId": sessionStorage.getItem('sessiondetail'),
        "userId": parseDetails?.zioId,//ZIOUserID
        "agentEmail": parseDetails?.email,
        "opportunityId": opprtunityID, 'products': cartDetails?.filter((element: any) => element !== null),
        "selectedSolution": "p00" + activeTab,
        "agencyNumber": Number(selectedAgencies)
      }
      _generateQuoteId(obj)
    } else {
      toast.error('Please select agency!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      setShowGenerateQuoteDialog(false)
    }
  }
  const _getPriority = (newValue: any) => {
    if (newValue === 1) {
      return 'LOW'
    }
    else if (newValue === 2) {
      return 'LOW_MEDIUM'
    }
    else if (newValue === 3) {
      return 'MEDIUM'
    }
    else if (newValue === 4) {
      return 'MEDIUM HIGH'
    }
    else if (newValue === 5) {
      return 'HIGH'
    }
    else if (newValue > 5) {
      return 'HIGH'
    }
    else if (newValue < 1) {
      return 'LOW'
    }
    else {
      return 'LOW'
    }
  }
  const [gapSummaryDetails, setSummaryDetails] = React.useState<any>()

  const _getColor = (value: any) => {
    let color;

    const fulfillmentRate = Number(value);
    if (fulfillmentRate < 50 && fulfillmentRate > 0) {
      color = '#FFFF00'; // yellow
    } else if (fulfillmentRate === 100) {
      color = '#34A853'; // green
    } else if (fulfillmentRate < 1) {
      color = '#FF7B6F'; // pink
    } else if (fulfillmentRate === 0) {
      color = '#FF7B6F'; // pink
    }
    else {
      color = '#21AFE5'; // blue
    }
    return color

  }
  const _showProducts = (details: any) => {
    _setProductDetails(details, totalAmount, availableBudget, userName, selectedSolution, selectedRiskDetails)
    _setPageID(9)
  }
  const fetchData = async () => {

    const response = await axios.get(baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions);
    const reponseDetails = response.data;
    let childUniList = reponseDetails?.fourR?.dts?.futureExpenses?.filter((element: any) => element?.type === 'UNIVERSITY' && element?.dependentId);
    setChildUniversityList(childUniList)
    setDependents(reponseDetails?.personalDetails?.dependents)
    setSelectedUserDetails(reponseDetails?.personalDetails?.coApplicant?.firstName)
    setUserName(reponseDetails?.personalDetails?.firstName + " " + reponseDetails?.personalDetails?.lastName)
    setcoUserName(reponseDetails?.personalDetails?.coApplicant?.firstName + " " + reponseDetails?.personalDetails?.coApplicant?.lastName)
    setIsSoluationGenerated(reponseDetails?.isSolutionGenerated)
    if (reponseDetails?.riskProfile?.customerRiskProfile?.isDifferentRiskLevel === true) {
      let label = _getRiskLabel(reponseDetails?.riskProfile?.customerRiskProfile?.choosenRiskAppetite)
      setSelectedRiskDetails(label)
    } else {
      let label = _getRiskLabel(reponseDetails?.riskProfile?.customerRiskProfile?.riskProfileIndex)
      setSelectedRiskDetails(label)
    }
  }
  const _getRiskLabel = (RiskIndexDetails: any) => {
    if (RiskIndexDetails === 3 || RiskIndexDetails === 'MEDIUM') {
      return 'Balanced'
    } else if (RiskIndexDetails === 4 || RiskIndexDetails === 'MEDIUM_HIGH') {
      return 'Performance'
    }
    else if (RiskIndexDetails === 2 || RiskIndexDetails === 'LOW_MEDIUM') {
      return 'Cautious'
    }
    else if (RiskIndexDetails === 1 || RiskIndexDetails === 'LOW') {
      return 'Defensive'
    }
    else if (RiskIndexDetails === 5 || RiskIndexDetails === 'HIGH') {
      return 'Adventurous'
    }
    else {
      return 'Performance'
    }

  }
  const handleClear = () => {
    signDetails?.clear()
  }
  const handleSave = () => {
    setClientSign(signDetails?.getTrimmedCanvas().toDataURL('image/png'))
    setAdvisorDetail1(signDetails1?.getTrimmedCanvas().toDataURL('image/png'))
    setCoappDetail(signDetails2?.getTrimmedCanvas().toDataURL('image/png'))
    const timeString = new Date().getFullYear() + "-" + (Number(new Date().getMonth()) + 1) + "-" + new Date().getDate() + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds();
    setSignDate(timeString)
    let userDetails = {
      sessionId: sessionStorage.getItem('sessiondetail'),
      life1Sign: signDetails?.getTrimmedCanvas().toDataURL('image/png'),
      life2Sign: signDetails2?.getTrimmedCanvas().toDataURL('image/png'),
      advisorSign: signDetails1?.getTrimmedCanvas().toDataURL('image/png'),
      life1SignTime: timeString,
      life2SignTime: timeString,
      advisorSignTime: timeString
    }
    _signDocument(userDetails)
  }
  const _signDocument = async (userDetails: {}) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
    };
    const requestOptions = {
      method: 'POST',
      headers: headers,
    };

    const response = await axios.post(baseUrl + `/customers/store/reportsign/base64`, userDetails, requestOptions)
      .then((response) => {
        setShowSign(false)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        showSignature(false)
      }).catch(error => {
        //_setPageID(2)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        showSignature(true)
      })
    return response
  }

  const navigatePreviousPage = () => {
    setCartDetails([])
    setShowSolution(false)
  }
  return (
    <>
      <Box className={(isClosedSession === true || isQuoteDetailsAvailable === true) ? 'lock' : ''}>
        <Dialog className='spinner1' open={showSpinner1}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
        <Dialog className='spinner' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
        <Box className="heading">For {userName}</Box>
        {(showSolution) ? (
          <>
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#005EB8",
                }}
              >
                <ArrowBackIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigatePreviousPage()}
                />
                <SecurityOutlinedIcon sx={{ ml: 1, mr: 1 }} />
                {selectedSolution}
              </Box>
              <Typography sx={{ fontWeight: "400" }}>
                <Select
                  labelId="selectedAgencies-label"
                  id="selectedAgencies-select"
                  name="selectedAgencies"
                  value={selectedAgencies}
                  disabled={isClosedSession === true || isQuoteDetailsAvailable === true}
                  variant='standard'
                  className="noBorder showall-select"
                  onChange={(e) => setSelectedAgencies(e.target.value)}
                  sx={{ color: '#003399', background: '#f6f9f8', width: '350px' }}
                >
                  <MenuItem value='0'>Select agency</MenuItem>
                  {
                    agenciesList?.map((element: any) => <MenuItem key={element?.agentNumber} value={element?.agentNumber} selected>{element?.agentNumber + " - " + element?.agentName}</MenuItem>
                    )
                  }
                </Select>
              </Typography>
            </Box>
            <Grid container sx={{ marginTop: "25px", alignItems: 'center' }}>
              <Grid item xs={3} sx={{ textAlign: "left" }}>
                <Typography sx={{ fontWeight: "400" }}>
                  <Select
                    labelId="selectedSoluation-label"
                    id="selectedSoluation-select"
                    name="selectedSoluation"
                    value={selectedSoluation}
                    variant='standard'
                    className="noBorder showall-select"
                    onChange={(e) => setSelectedSoluation(e.target.value)}
                    sx={{ color: '#003399', background: '#f6f9f8' }}
                  >
                    <MenuItem value='ShowSelectedSolution' selected>Show Selected Solution</MenuItem>
                    <MenuItem value='ShowAll'>Show all</MenuItem>
                  </Select>
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "left" }}>
                {(espProduct?.length > 0 && isEsp) &&
                  <FormControl sx={{ m: 1, width: 220 }} className="noBorder showall-select">

                    <Select
                      labelId="ESP-multiple-checkbox-label"
                      id="ESP-multiple-checkbox"
                      multiple
                      disabled={isClosedSession === true || isQuoteDetailsAvailable === true}
                      value={variantName.length > 0 ? variantName : ['deselect']}
                      onChange={handleChange}
                      variant='standard'
                      input={<OutlinedInput sx={{ border: '0 solid #fff !important', background: '#fff', "& fieldset": { border: '0 solid #fff !important' } }} />}
                      renderValue={(selected) => {
                        let valArray = selected.map((x: any) => x.dependentName);
                        let value = (valArray?.length === 1 && valArray[0] === undefined) ? 'Deselect ESP' : 'Deselect ESP'
                        return value
                      }
                      }
                      className="noBorder showall-select"
                      sx={{ color: '#003399', background: '#fff', border: '0 solid #fff !important', height: '30px !important' }}
                    >
                      <MenuItem value={'deselect'}>Deselect ESP</MenuItem>
                      {espProduct?.map((variant: any) => (

                        <MenuItem key={variant.goalId} value={variant}>
                          <Checkbox
                            checked={
                              variantName.findIndex((item: any) => item.goalId === variant.goalId) >= 0
                            }
                          />
                          <ListItemText primary={variant.dependentName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                }
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "left" }}>

                {(espProduct?.length > 0 && isEsp) &&
                  <Button disabled={isClosedSession === true || isQuoteDetailsAvailable === true} sx={{ borderRadius: "10px" }} variant="outlined" onClick={() => handleEspArraySubmit()}>
                    Re-Evaluate
                  </Button>
                }
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <Button disabled={isClosedSession === true || isQuoteDetailsAvailable === true} sx={{ borderRadius: "10px" }} variant="outlined">
                  <SecurityOutlinedIcon sx={{ mr: 1 }} />
                  Protection
                </Button>{" "}
                <Button disabled={isClosedSession === true || isQuoteDetailsAvailable === true} sx={{ borderRadius: "10px" }} variant="outlined">
                  <img src={banknoteBlue} style={{ width: '30px', paddingRight: '20px' }} alt='saving' />
                  Investment
                </Button>
              </Grid>
            </Grid>
            {(productsData?.length < 1 && selectedSoluation === 'ShowSelectedSolution') && <Grid item xs={12} sx={{ background: '#f6f9f8', padding: '10px', textAlign: 'left' }} > <Typography sx={{ color: '#ea635c', fontSize: '12px', fontWeight: '600', textTransform: 'none' }}> No valid products for you </Typography></Grid>}
            {
              (productsData?.length > 0) &&
              productsData?.map((element: any, index: any) => {
                let flagArray = variantName?.filter(((velement: any) => element?.dependentName === velement?.dependentName))
                return (flagArray?.length > 0 && element?.fullName === 'Education Saving Plan' || (element?.fullName !== 'Education Saving Plan') || (isEsp === false)) &&
                  (
                    <Paper
                      key={index}
                      elevation={4}
                      sx={{
                        mt: 4,
                        position: "relative",
                        height: "200px",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          left: 0,
                          background: (element?.isModified === true) ? 'rgb(249, 188, 62)' : "#005EB8",
                          color: "white",
                          width: "200px",
                          borderTopLeftRadius: "8px",
                        }}
                      >
                        <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                          {(element?.isModified === true) ? 'Modified' : 'Optimized'}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          pt: 1,
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ marginLeft: "60px", textAlign: "left", width: '65%' }} onClick={() => _showProducts(element)}>
                          <Box sx={{ display: 'flex' }}><Typography sx={{ fontWeight: '700', color: '#005EB8' }}>{element?.fullName + "  "} </Typography>
                            <Typography sx={{ fontWeight: '700', color: '#666666', paddingLeft: '5px' }}>{element?.type}</Typography>
                            <Typography sx={{ fontWeight: '700', color: '#005EB8' }}>{element?.dependentName ? " - " + element?.dependentName : ''} </Typography>
                          </Box>

                          <Typography>{element?.shortDescription}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "35%",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "#000000",
                              }}
                            >
                              Premium (monthly)
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "30px",
                                color: "#005EB8",
                                mt: -1,
                              }}
                            >
                              {" "}
                              {selectedCurrency} {Number(element?.monthlyPremium).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                          </Box>
                          <Box sx={{ mr: 2 }}>
                            <Button
                              sx={{ borderRadius: "10px", width: "150px", margin: '0 15px' }}
                              variant="outlined"
                              onClick={() => handleAdd(element, index)}
                              disabled={(disabledQuoteButton === true || isClosedSession === true) ? true : false}
                            >
                              <ControlPointRoundedIcon sx={{ mr: 1 }} />
                              Add
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  )
              }
              )

            }
            {
              (selectedSoluation === 'ShowAll') &&
              allproductsData?.map((element: any, index: any) => (
                <Paper
                  key={index}
                  elevation={4}
                  sx={{
                    mt: 4,
                    position: "relative",
                    height: "200px",
                    borderRadius: "8px",
                  }}
                ><Box
                  sx={{
                    pt: 1,
                    height: "100%",
                    alignItems: "center",
                    display: 'flex',
                    justifyContent: "space-between",
                  }}
                >
                    <Box sx={{ marginLeft: "60px", textAlign: "left", width: '65%' }}>
                      <Box sx={{ display: 'flex' }}><Typography sx={{ fontWeight: '700', color: '#005EB8' }}>{element?.name} </Typography>
                        <Typography sx={{ fontWeight: '700', color: '#666666', paddingLeft: '5px' }}>{element?.type}</Typography></Box>
                      <Typography>{element?.shortDescription}</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        textAlign: "left",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "35%",
                        paddingLeft: '3%'
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "#000000",
                          }}
                        >
                          {element?.name === 'Simple Wealth' ? 'Single Premium' : 'Premium (monthly)'}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "30px",
                            color: "#005EB8",
                            mt: -1,
                          }}
                        >
                          {" "}
                          {selectedCurrency} {element ? Number(element?.monthlyPremium).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                        </Typography>
                      </Box>
                      <Box sx={{ mr: 2 }}>
                        <Button
                          sx={{ borderRadius: "10px", width: "150px", margin: '0 15px', display: 'none' }}
                          variant="outlined"
                          onClick={() => handleAdd(element, index)}
                          disabled={disabledQuoteButton === true ? true : false}
                        >
                          <ControlPointRoundedIcon sx={{ mr: 1 }} />
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              ))
            }

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 3,
                background: "#012169",
                color: "white",
                minHeight: '230px',
                maxHeight: "400px",
                pl: 3,
                pr: 3,
                borderRadius: "8px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <ShoppingBagOutlinedIcon sx={{ fontSize: "25px", mr: 3 }} />
                </Box>
                <Box>
                  {cartDetails?.map((item: any, index: number) => (
                    <>
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "590px",
                        }}
                      >
                        <Box>
                          <Typography sx={{ display: 'flex' }}>{item?.fullName} <Typography sx={{ color: '#005EB8', paddingLeft: '8px' }}> {item?.type}</Typography><Typography sx={{ color: '#fff', paddingLeft: '8px' }}>{item?.dependentName ? " - " + item?.dependentName : ''} </Typography> </Typography>
                        </Box>
                        <Box>
                          <DeleteOutlineOutlinedIcon
                            sx={{ color: "#FF7B6F", cursor: disabledQuoteButton === true ? 'notAllowed' : "pointer" }}
                            onClick={() => handleDelete(index)}
                          />
                        </Box>

                        {disabledQuoteButton === true && item?.referenceID &&
                          <Box><Typography sx={{ color: '#fff' }}>Quote Id: <a rel="noreferrer" style={{ color: '#fff' }} href={DOMPurify.sanitize(zeproQuote + '#id_token=' + idToken + "&state=" + item?.application_ID + "," + item?.agency_Number + "," + item?.agency_Number + "," + item?.productCode + "," + item?.product_Version)} target="_blank">{item?.referenceID}</a> </Typography></Box>
                        }

                      </Box>
                      <Divider sx={{ background: "white" }} />
                    </>
                  ))}
                </Box>
              </Box>
              <Box sx={{ width: "40%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography>Monthly Budget Available</Typography>
                  </Box>
                  <Box><Typography>{roundOffAndFormat(totalAmount)}/{roundOffAndFormat(availableBudget)} {selectedCurrency}</Typography></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography>Single Contribution Available</Typography>
                  </Box>
                  <Box><Typography>0/0 {selectedCurrency}</Typography></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box>
                    <Button
                      className='soluationButton'
                      sx={{
                        width: "200px",
                        color: "#fff",
                        borderColor: "#fff",
                        borderRadius: "8px",
                      }}
                      onClick={generateQuoteId}
                      disabled={((cartDetails?.length <= 0 || disabledQuoteButton === true))}
                      variant="outlined"
                    >
                      Generate Quote ID
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className='soluationButton'
                      disabled={((cartDetails?.length <= 0) === true)}
                      onClick={goToDocumentPage}
                      sx={{
                        width: "200px",
                        color: "#fff",
                        borderColor: "#fff",
                        borderRadius: "8px",

                      }}
                      variant="outlined"
                    >
                      Generate Report
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box className="tab-list">
              <button
                disabled={isClosedSession === true || (isQuoteDetailsAvailable === true && activeTab !== 1)}
                className={activeTab === 1 ? "active tabbutton" : "tabbutton"}
                onClick={() => handleTabClick(1)}
              >
                {activeTab === 1 ?
                  <img src={awardIconWhite} style={{ width: '25px', paddingRight: '20px' }} alt='Recommended soluation' />
                  : <img src={awardIcon} style={{ width: '25px', paddingRight: '20px' }} alt='Recommended soluation' />
                }
                Recommended Solution
              </button>
              <button
                disabled={isClosedSession === true || (isQuoteDetailsAvailable === true && activeTab !== 2)}
                className={activeTab === 2 ? "active tabbutton" : "tabbutton"}
                onClick={() => handleTabClick(2)}
              >
                <GppGoodOutlinedIcon sx={{ mr: 2 }} />
                Protection Focused
              </button>
              <button
                disabled={isClosedSession === true || (isQuoteDetailsAvailable === true && activeTab !== 3)}
                className={activeTab === 3 ? "active tabbutton" : "tabbutton"}
                onClick={() => handleTabClick(3)}
              >
                {activeTab === 3 ? <img src={banknoteBlue} style={{ width: '30px', paddingRight: '20px' }} alt='saving' /> :
                  <img src={banknoteBlue} style={{ width: '30px', paddingRight: '20px' }} alt='saving' />}
                Savings Focused
              </button>
              <button
                disabled={(isClosedSession === true || (isQuoteDetailsAvailable === true && activeTab !== 4))}
                className={activeTab === 4 ? "active tabbutton" : "tabbutton"}
                onClick={() => { handleTabClick(4) }}
              >
                {activeTab === 4 ? <img src={banknoteBlue} style={{ width: '30px', paddingRight: '20px' }} alt='saving' /> :
                  <img src={banknoteBlue} style={{ width: '30px', paddingRight: '20px' }} alt='saving' />}
                Education Focused
              </button>

            </Box>
            <Box className="tab-content">
              {activeTab === 1 && (
                <Box
                  style={{
                    border: "none",
                    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                    borderRadius: "21px",
                    backgroundColor: "#012169",
                    color: "#fff",
                    paddingBottom: "60px",
                    backgroundImage: `url(${summaryImg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right bottom",
                    backgroundSize: "12%",
                  }}
                >
                  <Box style={{ display: "flex", minHeight: "30vh" }}>
                    <Box style={{ width: "50%" }}>
                      <Box style={{ width: "100%", margin: "30px" }}>
                        {protactionData?.map((element: any, index: number) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "20px",
                              }}
                            >
                              <CircularProgressbar
                                value={(element?.fulfillmentRate).toFixed(0)}
                                text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                                background={true}
                                strokeWidth={15}
                                styles={{
                                  // Customize the root svg element
                                  root: {},
                                  // Customize the path, i.e. the "completed progress"
                                  path: {
                                    // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                    stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Customize transition animation
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    // Rotate the path
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the circle behind the path, i.e. the "total progress"
                                  trail: {
                                    // Trail color - set to transparent if percentage is 0, blue otherwise
                                    stroke: (element?.fulfillmentRate) === 0 ? '#FF7B6F' : '#005EB8',
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Rotate the trail
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the text
                                  text: {
                                    // Text color
                                    fill: '#232323',
                                    // Text size
                                    fontSize: '26px',
                                  },
                                  // Customize background - only used when the `background` prop is true
                                  background: {
                                    fill: 'white',
                                  },
                                }}
                              />
                            </Box>
                            <Box style={{ width: "100%" }}>

                              <Box
                                style={{
                                  borderBottom: "1px solid white",
                                  display: "flex",
                                  width: "70%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                  {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                                </Box>
                                {(element?.fulfillmentRate <= 0 && (element?.name === 'CI' || element?.name === 'PTD')) ? '' : <Box>Priority- {_getPriority(element?.priority)}</Box>}
                              </Box>
                              <Box
                                style={{ textAlign: "left", marginTop: "5px" }}
                              >
                                Gap fulfillment rate -{Number(element?.fulfillmentRate).toFixed(0)}%
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ width: "50%", margin: "30px" }}>
                      {investmentData.map((element: any, index: any) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              height: "80px",
                              width: "80px",
                              margin: "20px",
                            }}
                          >
                            <CircularProgressbar
                              value={(element?.fulfillmentRate).toFixed(0)}
                              text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                              background={true}
                              strokeWidth={15}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                  stroke:
                                    _getColor((element?.fulfillmentRate).toFixed(0)),
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Customize transition animation
                                  transition: 'stroke-dashoffset 0.5s ease 0s',
                                  // Rotate the path
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color - set to transparent if percentage is 0, blue otherwise
                                  stroke: (element.fulfillmentRate).toFixed(0) == 0 ? '#FF7B6F' : '#005EB8',
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Rotate the trail
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: '#232323',
                                  // Text size
                                  fontSize: '26px',
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: 'white',
                                },
                              }}
                            />
                          </Box>
                          <Box style={{ width: "100%" }}>

                            <Box
                              style={{
                                borderBottom: "1px solid white",
                                display: "flex",
                                width: "70%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                              </Box>
                              <Box>Priority -  {_getPriority(element?.priority)}</Box>
                            </Box>
                            <Box style={{ textAlign: "left", marginTop: "5px" }}>
                              Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >

                    <button
                      onClick={() => handleSelectSolution("Recommended Solution")}
                      className="select-button tabbutton"
                    >
                      Select Solution
                    </button>
                  </Box>
                </Box>
              )}
              {activeTab === 2 && (
                <Box
                  style={{
                    border: "none",
                    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                    borderRadius: "21px",
                    backgroundColor: "#012169",
                    color: "#fff",
                    paddingBottom: "60px",
                    backgroundImage: `url(${summaryImg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right bottom",
                    backgroundSize: "12%",
                  }}
                >
                  <Box style={{ display: "flex", minHeight: "30vh" }}>
                    <Box style={{ width: "50%" }}>
                      <Box style={{ width: "100%", margin: "30px" }}>
                        {protactionDataProtaction?.map((element: any, index: any) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "20px",
                              }}
                            >
                              <CircularProgressbar
                                value={(element?.fulfillmentRate).toFixed(0)}
                                text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                                background={true}
                                strokeWidth={15}
                                styles={{
                                  // Customize the root svg element
                                  root: {},
                                  // Customize the path, i.e. the "completed progress"
                                  path: {
                                    // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                    stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Customize transition animation
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    // Rotate the path
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the circle behind the path, i.e. the "total progress"
                                  trail: {
                                    // Trail color - set to transparent if percentage is 0, blue otherwise
                                    stroke: (element.fulfillmentRate).toFixed(0) == 0 ? '#FF7B6F' : '#005EB8',
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Rotate the trail
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the text
                                  text: {
                                    // Text color
                                    fill: '#232323',
                                    // Text size
                                    fontSize: '26px',
                                  },
                                  // Customize background - only used when the `background` prop is true
                                  background: {
                                    fill: 'white',
                                  },
                                }}
                              />
                            </Box>
                            <Box style={{ width: "100%" }}>

                              <Box
                                style={{
                                  borderBottom: "1px solid white",
                                  display: "flex",
                                  width: "70%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                  {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                                </Box>
                                {(element?.fulfillmentRate <= 0 && (element?.name === 'CI' || element?.name === 'PTD')) ? '' : <Box>Priority- {_getPriority(element?.priority)}</Box>}
                              </Box>
                              <Box
                                style={{ textAlign: "left", marginTop: "5px" }}
                              >
                                Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ width: "50%", margin: "30px" }}>
                      {investmentDataProtaction?.map((element: any, index: any) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              height: "80px",
                              width: "80px",
                              margin: "20px",
                            }}
                          >
                            <CircularProgressbar
                              value={(element?.fulfillmentRate).toFixed(0)}
                              text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                              background={true}
                              strokeWidth={15}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                  stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Customize transition animation
                                  transition: 'stroke-dashoffset 0.5s ease 0s',
                                  // Rotate the path
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color - set to transparent if percentage is 0, blue otherwise
                                  stroke: (element.fulfillmentRate).toFixed(0) == 0 ? '#FF7B6F' : '#005EB8',
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Rotate the trail
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: '#232323',
                                  // Text size
                                  fontSize: '26px',
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: 'white',
                                },
                              }}
                            />
                          </Box>
                          <Box style={{ width: "100%" }}>

                            <Box
                              style={{
                                borderBottom: "1px solid white",
                                display: "flex",
                                width: "70%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                              </Box>
                              <Box>Priority - {_getPriority(element?.priority)}</Box>
                            </Box>
                            <Box style={{ textAlign: "left", marginTop: "5px" }}>
                              Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => handleSelectSolution("Protection Focused")}
                      className="select-button tabbutton"
                    >
                      Select Solution
                    </button>
                  </Box>
                </Box>
              )}
              {activeTab === 3 && (
                <Box
                  style={{
                    border: "none",
                    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                    borderRadius: "21px",
                    backgroundColor: "#012169",
                    color: "#fff",
                    paddingBottom: "60px",
                    backgroundImage: `url(${summaryImg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right bottom",
                    backgroundSize: "12%",
                  }}
                >
                  <Box style={{ display: "flex", minHeight: "30vh" }}>
                    <Box style={{ width: "50%" }}>
                      <Box style={{ width: "100%", margin: "30px" }}>
                        {protactionDataSavings.map((element: any, index: any) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "20px",
                              }}
                            >
                              <CircularProgressbar
                                value={(element?.fulfillmentRate).toFixed(0)}
                                text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                                background={true}
                                strokeWidth={15}
                                styles={{
                                  // Customize the root svg element
                                  root: {},
                                  // Customize the path, i.e. the "completed progress"
                                  path: {
                                    // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                    stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Customize transition animation
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    // Rotate the path
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the circle behind the path, i.e. the "total progress"
                                  trail: {
                                    // Trail color - set to transparent if percentage is 0, blue otherwise
                                    stroke: (element.fulfillmentRate).toFixed(0) == 0 ? '#FF7B6F' : '#005EB8',
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Rotate the trail
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the text
                                  text: {
                                    // Text color
                                    fill: '#232323',
                                    // Text size
                                    fontSize: '26px',
                                  },
                                  // Customize background - only used when the `background` prop is true
                                  background: {
                                    fill: 'white',
                                  },
                                }}
                              />
                            </Box>
                            <Box style={{ width: "100%" }}>

                              <Box
                                style={{
                                  borderBottom: "1px solid white",
                                  display: "flex",
                                  width: "70%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                  {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                                </Box>
                                {(element?.fulfillmentRate <= 0 && (element?.name === 'CI' || element?.name === 'PTD')) ? '' : <Box>Priority- {_getPriority(element?.priority)}</Box>}
                              </Box>
                              <Box
                                style={{ textAlign: "left", marginTop: "5px" }}
                              >
                                Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ width: "50%", margin: "30px" }}>
                      {investmentDataSavings?.map((element: any, index: any) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              height: "80px",
                              width: "80px",
                              margin: "20px",
                            }}
                          >
                            <CircularProgressbar
                              value={(element?.fulfillmentRate).toFixed(0)}
                              text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                              background={true}
                              strokeWidth={15}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                  stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Customize transition animation
                                  transition: 'stroke-dashoffset 0.5s ease 0s',
                                  // Rotate the path
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color - set to transparent if percentage is 0, blue otherwise
                                  stroke: (element.fulfillmentRate).toFixed(0) == 0 ? '#FF7B6F' : '#005EB8',
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Rotate the trail
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: '#232323',
                                  // Text size
                                  fontSize: '26px',
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: 'white',
                                },
                              }}
                            />

                          </Box>
                          <Box style={{ width: "100%" }}>

                            <Box
                              style={{
                                borderBottom: "1px solid white",
                                display: "flex",
                                width: "70%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                              </Box>
                              {element?.priority > 0 && <Box>Priority - {_getPriority(element?.priority)}</Box>}
                            </Box>
                            <Box style={{ textAlign: "left", marginTop: "5px" }}>
                              Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => handleSelectSolution("Savings Focused")}
                      className="select-button tabbutton"
                    >
                      Select Solution
                    </button>
                  </Box>
                </Box>
              )}



              {activeTab === 4 && (
                (espDisable || !soluationDetails?.p004) ? (
                  <div>
                    <Box
                      style={{
                        border: "none",
                        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                        borderRadius: "21px",
                        height: "350px",
                        backgroundColor: "#012169",
                        color: "#fff",
                        paddingBottom: "60px",
                        backgroundImage: `url(${summaryImg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right bottom",
                        backgroundSize: "12%",
                      }}
                    >
                      {/* <Box className="esp-text">
                        Dear customer with the budget you have allocated we are unable to provide you with Education focused products.
                    </Box>*/}
                      <Box className="esp-text">
                        <Box className="esp-text-title">
                          Dear Customer, Education Plan is not recommended due to any one of the following reason,
                        </Box>
                        <Box className="esp-text-content">
                          1. ESP is applicable for UAE residents only
                        </Box>
                        <Box className="esp-text-content">
                          2. PPT has not satisfied
                        </Box>
                        <Box
                          className="esp-text-content">
                          3. Maximum ESP age is 15 Years
                        </Box>
                        <Box
                          className="esp-text-content">
                          4. Insufficient Budget
                        </Box>
                        <Box
                          className="esp-text-content">
                          5. Asset allocation
                        </Box>

                      </Box>

                      {/* <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "170px",
                        }}
                      >
                        <button
                          onClick={() => handleSelectSolution("Education Focused")}
                          disabled={espDisable}
                          className="select-button tabbutton"
                        >
                          Select Solution
                        </button>
                      </Box> */}
                    </Box>

                  </div>

                ) : (
                  <div>
                    <Box
                      style={{
                        border: "none",
                        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                        borderRadius: "21px",
                        backgroundColor: "#012169",
                        color: "#fff",
                        paddingBottom: "60px",
                        backgroundImage: `url(${summaryImg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right bottom",
                        backgroundSize: "12%",
                      }}
                    >
                      <Box style={{ display: "flex", minHeight: "30vh" }}>
                        <Box style={{ width: "50%" }}>
                          <Box style={{ width: "100%", margin: "30px" }}>
                            {protactionDataEducation.map((element: any, index: any) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  style={{
                                    height: "80px",
                                    width: "80px",
                                    margin: "20px",
                                  }}
                                >
                                  <CircularProgressbar
                                    value={(element?.fulfillmentRate).toFixed(0)}
                                    text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                                    background={true}
                                    strokeWidth={15}
                                    styles={{
                                      // Customize the root svg element
                                      root: {},
                                      // Customize the path, i.e. the "completed progress"
                                      path: {
                                        // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                        stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Customize transition animation
                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                        // Rotate the path
                                        // transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                      },
                                      // Customize the circle behind the path, i.e. the "total progress"
                                      trail: {
                                        // Trail color - set to transparent if percentage is 0, blue otherwise
                                        stroke: (element.fulfillmentRate).toFixed(0) == 0 ? '#FF7B6F' : '#005EB8',
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Rotate the trail
                                        // transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                      },
                                      // Customize the text
                                      text: {
                                        // Text color
                                        fill: '#232323',
                                        // Text size
                                        fontSize: '26px',
                                      },
                                      // Customize background - only used when the `background` prop is true
                                      background: {
                                        fill: 'white',
                                      },
                                    }}
                                  />
                                </Box>
                                <Box style={{ width: "100%" }}>

                                  <Box
                                    style={{
                                      borderBottom: "1px solid white",
                                      display: "flex",
                                      width: "70%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                      {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                                    </Box>
                                    {(element?.fulfillmentRate <= 0 && (element?.name === 'CI' || element?.name === 'PTD')) ? '' : <Box>Priority- {_getPriority(element?.priority)}</Box>}
                                  </Box>
                                  <Box
                                    style={{ textAlign: "left", marginTop: "5px" }}
                                  >
                                    Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box style={{ width: "50%", margin: "30px" }}>
                          {investmentDataEducation?.map((element: any, index: any) => (
                            <Box
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  margin: "20px",
                                }}
                              >
                                <CircularProgressbar
                                  value={(element?.fulfillmentRate).toFixed(0)}
                                  text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                                  background={true}
                                  strokeWidth={15}
                                  styles={{
                                    // Customize the root svg element
                                    root: {},
                                    // Customize the path, i.e. the "completed progress"
                                    path: {
                                      // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                      stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                      strokeLinecap: 'butt',
                                      // Customize transition animation
                                      transition: 'stroke-dashoffset 0.5s ease 0s',
                                      // Rotate the path
                                      // transform: 'rotate(0.25turn)',
                                      transformOrigin: 'center center',
                                    },
                                    // Customize the circle behind the path, i.e. the "total progress"
                                    trail: {
                                      // Trail color - set to transparent if percentage is 0, blue otherwise
                                      stroke: (element.fulfillmentRate).toFixed(0) == 0 ? '#FF7B6F' : '#005EB8',
                                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                      strokeLinecap: 'butt',
                                      // Rotate the trail
                                      // transform: 'rotate(0.25turn)',
                                      transformOrigin: 'center center',
                                    },
                                    // Customize the text
                                    text: {
                                      // Text color
                                      fill: '#232323',
                                      // Text size
                                      fontSize: '26px',
                                    },
                                    // Customize background - only used when the `background` prop is true
                                    background: {
                                      fill: 'white',
                                    },
                                  }}
                                />

                              </Box>
                              <Box style={{ width: "100%" }}>

                                <Box
                                  style={{
                                    borderBottom: "1px solid white",
                                    display: "flex",
                                    width: "70%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                    {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                                  </Box>
                                  {element?.priority > 0 && <Box>Priority - {_getPriority(element?.priority)}</Box>}
                                </Box>
                                <Box style={{ textAlign: "left", marginTop: "5px" }}>
                                  Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          onClick={() => handleSelectSolution("Education Focused")}
                          disabled={espDisable}
                          className="select-button tabbutton"
                        >
                          Select Solution
                        </button>
                      </Box>
                    </Box>
                  </div>
                )

              )}








              {/* {activeTab === 4 && (

                <Box
                  style={{
                    border: "none",
                    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                    borderRadius: "21px",
                    backgroundColor: "#012169",
                    color: "#fff",
                    paddingBottom: "60px",
                    backgroundImage: `url(${summaryImg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right bottom",
                    backgroundSize: "12%",
                  }}
                >
                  <Box style={{ display: "flex", minHeight: "30vh" }}>
                    <Box style={{ width: "50%" }}>
                      <Box style={{ width: "100%", margin: "30px" }}>
                        {protactionDataEducation.map((element: any, index: any) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                height: "80px",
                                width: "80px",
                                margin: "20px",
                              }}
                            >
                              <CircularProgressbar
                                value={(element?.fulfillmentRate).toFixed(0)}
                                text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                                background={true}
                                strokeWidth={15}
                                styles={{
                                  // Customize the root svg element
                                  root: {},
                                  // Customize the path, i.e. the "completed progress"
                                  path: {
                                    // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                    stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Customize transition animation
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    // Rotate the path
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the circle behind the path, i.e. the "total progress"
                                  trail: {
                                    // Trail color - set to transparent if percentage is 0, blue otherwise
                                    stroke: (element.fulfillmentRate).toFixed(0) === 0 ? '#FF7B6F' : '#005EB8',
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    // Rotate the trail
                                    // transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                  },
                                  // Customize the text
                                  text: {
                                    // Text color
                                    fill: '#232323',
                                    // Text size
                                    fontSize: '26px',
                                  },
                                  // Customize background - only used when the `background` prop is true
                                  background: {
                                    fill: 'white',
                                  },
                                }}
                              />
                            </Box>
                            <Box style={{ width: "100%" }}>

                              <Box
                                style={{
                                  borderBottom: "1px solid white",
                                  display: "flex",
                                  width: "70%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                  {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                                </Box>
                                {(element?.fulfillmentRate <= 0 && (element?.name === 'CI' || element?.name === 'PTD')) ? '' : <Box>Priority- {_getPriority(element?.priority)}</Box>}
                              </Box>
                              <Box
                                style={{ textAlign: "left", marginTop: "5px" }}
                              >
                                Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box style={{ width: "50%", margin: "30px" }}>
                      {investmentDataEducation?.map((element: any, index: any) => (
                        <Box
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              height: "80px",
                              width: "80px",
                              margin: "20px",
                            }}
                          >
                            <CircularProgressbar
                              value={(element?.fulfillmentRate).toFixed(0)}
                              text={`${(element?.fulfillmentRate).toFixed(0)}%`}
                              background={true}
                              strokeWidth={15}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color - set to green if percentage is 100%, pink if percentage is 0, and blue otherwise
                                  stroke: _getColor((element?.fulfillmentRate).toFixed(0)),
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Customize transition animation
                                  transition: 'stroke-dashoffset 0.5s ease 0s',
                                  // Rotate the path
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color - set to transparent if percentage is 0, blue otherwise
                                  stroke: (element.fulfillmentRate).toFixed(0) === 0 ? '#FF7B6F' : '#005EB8',
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Rotate the trail
                                  // transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: '#232323',
                                  // Text size
                                  fontSize: '26px',
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: 'white',
                                },
                              }}
                            />

                          </Box>
                          <Box style={{ width: "100%" }}>

                            <Box
                              style={{
                                borderBottom: "1px solid white",
                                display: "flex",
                                width: "70%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box style={{ marginBottom: "5px", textAlign: 'left' }}>
                                {element?.name}{element?.description ? " (" + element.description?.substring(0, 15) + ")" : ""}
                              </Box>
                              {element?.priority > 0 && <Box>Priority - {_getPriority(element?.priority)}</Box>}
                            </Box>
                            <Box style={{ textAlign: "left", marginTop: "5px" }}>
                              Gap fulfillment rate -{(element?.fulfillmentRate).toFixed(0)}%
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => handleSelectSolution("Education Focused")}
                      disabled={espDisable}
                      className="select-button tabbutton"
                    >
                      Select Solution
                    </button>
                  </Box>
                </Box>
              )} */}

            </Box>
            <Grid item xs={12} sx={{ marginTop: "25px" }}>
              <Button
                disabled={isClosedSession === true || isQuoteDetailsAvailable === true}
                type="submit"
                startIcon={<ArrowBackIcon />}
                variant="contained"
                sx={{ background: "#005EB8", float: "left", width: "100px" }}
              >
                Back
              </Button>
              <Button
                type="submit"
                disabled={isClosedSession === true || isQuoteDetailsAvailable === true}
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                sx={{ background: "#005EB8", float: "right", width: "100px" }}
              >
                Next
              </Button>
            </Grid>
          </>
        )}
      </Box>
      <Dialog onClose={togglePDF} open={showPdf} className='pdfBox'>

        <DialogContent sx={{ minWidth: '100%', overFlow: 'hidden' }}>
          <DialogContentText id="alert-dialog-description">

            {isShowSignature === true ?
              <Grid container sx={{ alignContent: 'center', margin: 'auto', overflow: 'hidden' }}>
                <Grid item xs={10} sx={{ textAlign: 'center' }}>Signature</Grid>
                <Grid xs={selectedUserDetails ? 3 : 5} sx={{ border: '3px solid #000', padding: '15px', margin: '15px' }}>
                  <DialogContentText id="alert-dialog-description">Client's name</DialogContentText>
                  <SignaturePad canvasProps={{ height: 200, width: 240, className: 'signCanvas' }} ref={(data) => setSignDetails(data)} />
                  <Button onClick={handleClear} autoFocus variant="contained">
                    clear
                  </Button>
                </Grid>
                <Grid item xs={selectedUserDetails ? 3 : 5} sx={{ border: '3px solid #000', padding: '15px', margin: '15px' }}>
                  <DialogContentText id="alert-dialog-description">Advisor</DialogContentText>
                  <SignaturePad canvasProps={{ height: 200, width: 240, className: 'signCanvas' }} ref={(data) => setSignDetails1(data)} />
                  <Button onClick={()=>signDetails1?.clear()} autoFocus variant="contained">
                    clear
                  </Button>
                </Grid>
                {selectedUserDetails  &&
                  <Grid xs={3} sx={{ border: '3px solid #000', padding: '15px', margin: '15px' }}>
                    <DialogContentText id="alert-dialog-description">Co-Applicant's name</DialogContentText>
                    <SignaturePad canvasProps={{ height: 200, width: 240, className: 'signCanvas' }} ref={(data) => setSignDetails2(data)} />
                    <Button onClick={ ()=>signDetails2?.clear()} autoFocus variant="contained">
                      clear
                    </Button>
                  </Grid>
                }
                <Grid sx={{padding:'10px 15px'}}>
                <FormHelperText sx={{ color: '#ea635c' }}>
                {((signDetails?.isEmpty && signDetails?.isEmpty()) || (signDetails1?.isEmpty && signDetails1?.isEmpty()) || ((selectedUserDetails && signDetails2?.isEmpty && signDetails2?.isEmpty())) ) ? 'Please provide signature ':''}
                </FormHelperText>
               
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleSave} autoFocus variant="outlined" disabled={((signDetails?.isEmpty && signDetails?.isEmpty()) || (signDetails1?.isEmpty && signDetails1?.isEmpty()) || ((selectedUserDetails && signDetails2?.isEmpty && signDetails2?.isEmpty())) )} >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
              :
              <Grid container sx={{ height: '1000px', minHeight: '1000px' }}>
                <Grid item xs={12}>
                  {((isClosedSession === true || isQuoteDetailsAvailable === true) && (isValidPdf)) ?
                    reportUrl && <ShowPdf reportUrl={reportUrl} />
                    : <PDFViewer className="pdfViewer">{_getDocument()}</PDFViewer>
                  }
                </Grid>
              </Grid>
            }
          </DialogContentText>

        </DialogContent>
        <DialogActions>

          {(showSign === true && (isClosedSession === false)) ?
            <Button sx={{ margin: '15px' }} disabled={isClosedSession === true || isQuoteDetailsAvailable === true} onClick={() => setNotesDialogOpen(true)} autoFocus variant="outlined" endIcon={<NoteAltOutlinedIcon />}>
              Note
            </Button> : ''
          }
          {(showSign === true && isClosedSession === false && (!clientSign || !advisorSign || (selectedUserDetails && !coappSign))) ?
            <Button sx={{ margin: '15px' }} disabled={isClosedSession === true || isQuoteDetailsAvailable === true} onClick={() => showSignature(true)} autoFocus variant="outlined" endIcon={<EditOutlinedIcon />}>
              Sign
            </Button> : ''
          }
          {(isClosedSession === false || (isClosedSession && isValidPdf === false)) &&
            <Button sx={{ margin: '15px 15px 15px 10px' }} autoFocus variant="outlined" endIcon={<FileDownloadOutlinedIcon />}>


              <PDFDownloadLink document={_getDocument()} fileName="Fact Find Report.pdf">
                {({ blob, url, loading, error }) => {
                  setBlobDetails(blob)
                  setShowSpinner1(loading)
                  return (loading ? 'Loading document...' : 'Download')
                }
                }
              </PDFDownloadLink>
            </Button>
          }
          <Button sx={{ margin: '15px' }} disabled={showSendSpinner} onClick={sendReport} autoFocus variant="outlined" endIcon={showSendSpinner ? <CircularProgress size={'15px'} /> : <SendOutlinedIcon />}>
            {showSendSpinner ? 'sending...' : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={toggleGenerateQuote} open={showGenerateQuoteDialog} sx={{ 'width': '100%', overFlow: 'hidden' }}>
        <DialogTitle id="alert-dialog-title">
          Action Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              A quote will be generated via Zpro, please check that all details are correct as you will not be able to change it after this confirmation. Proceed?
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleGenerateQuote} autoFocus>
            No
          </Button>
          <Button onClick={() => continueGetQuote(true)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={notesDialogOpen}>
        <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '250px', background: '#f6f9f8' }}>
          <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Financial Advisor's notes on Recommendation</Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            value={notesValue}
            rows={5}
            inputProps={{ maxLength: 2000 }}
            name="notes"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNotesChange(e, '')}
            sx={{ height: 'auto !important', width: '450px' }}
          />
          {notesValue?.length >= 2000 && <FormHelperText>Maximum length is 2000 charchters.</FormHelperText>}
          <Box sx={{ textAlign: 'right' }}>
            <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { saveNotes(); setNotesDialogOpen(false) }}>
              Save
            </Button>
            <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => setNotesDialogOpen(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={childEspDialogOpen}>
        <Box
          sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '325px', background: '#f6f9f8' }}
        >
          <Box sx={{
            display: "flex"
          }}>
            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>
              Anyone of the below criteria is not matching for few children,</Typography>
          </Box>
          <Box sx={{
            display: "flex"
          }}>
            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>

              1. ESP is applicable for UAE residents only</Typography>
          </Box>
          <Box sx={{
            display: "flex"
          }}>
            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>
              2. PPT has not satisfied </Typography>
          </Box>
          <Box
            sx={{
              display: "flex"
            }}>
            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>
              3. Maximum ESP age is 15 Years </Typography>
          </Box>
          <Box
            sx={{
              display: "flex"
            }}>
            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>
              4. Insufficient Budget
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex"
            }}>
            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>
              5. Due to Asset allocation
            </Typography>
          </Box>
          <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>
            Hence they are not Eligible for ESP
          </Typography>
          <Box sx={{ textAlign: 'right' }}>
            <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { setChildEspDialogOpen(false) }}>
              OK
            </Button>
          </Box>
        </Box>
      </Dialog>

      <ToastContainer />
    </>
  );
};

export default SummaryTabs;

