import ReactPDF, {
    Document,
    Image,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
    Link,
    PDFViewer,
} from "@react-pdf/renderer";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import firstpage from "./asserts/image1.png";
import tickimage from "./asserts/tickimage.png";
import dayjs, { Dayjs } from 'dayjs';
import logo from './asserts/zurichLogoReport.png';
import index3 from './asserts/index3.png';
import index4 from './asserts/index4.png';
import index5 from './asserts/index5.png';
import index2 from './asserts/index2.png';
import index1 from './asserts/index1.png';
import star5 from './asserts/rating5.png';
import star4 from './asserts/rating4.png';
import star3 from './asserts/rating3.png';
import star2 from './asserts/rating2.png';
import star1 from './asserts/rating1.png';
import selectedImage from './asserts/selectedImage.png';
import selectedRiskProfileIcon from './asserts/selectedRiskProfileIcon.png';
import img5 from './asserts/img5.jpg';
import img6 from './asserts/img6.jpg';
import fourstar from './asserts/4star.png';
import zurichFundAllocation from './asserts/zurichFundAllocation.png';
import balanced from './asserts/balanced.png';
import adventurous from './asserts/adventours.png';
import cutious from './asserts/cutious.png';
import defensive from './asserts/defensive.png';
import performance from './asserts/performance.png';
import balancedESP from './asserts/ESPBalanced.png';
import adventurousESP from './asserts/ESPAgressive.png';
import defensiveESP from './asserts/ESPCautious.png';
import performanceESP from './asserts/ESPInternational.png';
import balancedRsp from './asserts/balanced.png';
import adventurousRsp from './asserts/adventours.png';
import cutiousRsp from './asserts/cutious.png';
import defensiveRsp from './asserts/defensive.png';
import performanceRsp from './asserts/performance.png';
import { buildQueries } from "@testing-library/react";
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'

let currentYear = new Date().getFullYear();
let previousYear = currentYear - 1;

// Create styles
const styles = StyleSheet.create({
    body: {
        backgroundColor: "white",
        color: 'black'
    },
    page: {
        backgroundColor: "white",
        color: 'black',
        margin: 10
    },
    noTable: {
        color: '#fff',
        marginBottom: 25
    },
    pageHeader: {
        color: "#1a2480",
        fontSize: 9,
        marginLeft: 40,
        padding: 5,
        marginRight: 40,
        fontFamily: 'Helvetica-Bold'
    },
    viewer: {
        width: '93%',//'570px', //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
    tick: {
        width: 20,
        height: 20,
        textAlign: "right",
    },
    footer: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        paddingBottom: '60px'
    },
    tick1: {
        width: 10,
        height: 10,
    },
    star: {
        width: 100,
        height: 30,
        textAlign: "left",
        merginLeft: 5,
        marginTop: 5,
        marginBottom: 5
    },
    logo: {
        width: 84,
        height: 70,
        textAlign: "left",
    },
    image: {
        width: 84,
        height: 70,

    },
    //Page4
    tableCol3Green: {
        marginTop: "10",
        marginLeft: "30",
        width: "25%",
        marginRight: 20,
        borderStyle: "solid",
        backgroundColor: "#ffffff",
        borderColor: "grey",
        borderBottom: 4,
        borderLeftWidth: 0.5,
        borderTopWidth: .5,
        borderRightWidth: 0.5,
        borderBottomColor: '#44beb8'
    },
    clientSelectedProduct: {
        width: '100%',
        fontSize: 9,
        textAlign: 'right',
        flexDirection: "row",
        alignItems: 'flexEnd',
        justifyContent: 'end'
    },
    tableCol3Red: {
        marginTop: "10",
        marginRight: 20,
        width: "27%",
        borderStyle: "solid",
        backgroundColor: "#ffffff",
        borderColor: "grey",
        borderBottom: 4,
        borderLeftWidth: 0.5,
        borderTopWidth: .5,
        borderRightWidth: 0.5,
        borderBottomColor: '#d81619',

    },
    tableCol3Yellow: {
        marginTop: "10",
        marginRight: 30,
        width: "25%",
        borderStyle: "solid",
        backgroundColor: "#ffffff",
        borderColor: "grey",
        borderBottom: 4,
        borderLeftWidth: 0.5,
        borderTopWidth: .5,
        borderRightWidth: 0.5,
        borderBottomColor: '#f6c90c',

    },
    tableCell3find: {

        marginTop: 10,
        fontSize: 8,
        color: "grey",
        textAlign: "center"
    },
    tableCell3p: {
        marginBottom: 10,
        marginTop: 0,
        fontSize: 8,
        color: "grey",
        textAlign: "center"
    },
    tableCell3Green: {

        marginTop: 0,
        fontSize: 30,
        color: "#44beb8",
        textAlign: "center"
    },
    tableCell3Red: {

        marginTop: 0,
        fontSize: 30,
        color: "#d81619",
        textAlign: "center"
    },
    tableCell3Yellow: {

        marginTop: 0,
        fontSize: 30,
        color: "#f6c90c",
        textAlign: "center"
    },
    text: {
        fontSize: 8,
        marginTop: 20,
        textcolor: "#1F85DE",
    },
    textpage7: {
        fontSize: 9,
        marginTop: 20,
        marginLeft: 30,
        textcolor: "#005EB8",
        fontFamily: 'Helvetica-Bold'
    },

    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 15,
        marginRight: 15,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableFnDetail: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 25,
        marginRight: 28,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tablefinobj: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 0,
        marginRight: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tablePeraonalLife: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 3,
        marginRight: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    riskProfileTable: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 20,
        marginRight: 20,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    riskProfileTable1: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 0,
        marginRight: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableFinObjectivesPage4: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 3,
        marginRight: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableDetails: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 5,
        marginRight: 8,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    planningNeedsTable: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 0,
        marginRight: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableFinObjectives: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 40,
        marginRight: 40,
        borderRightWidth: 0,
        borderBottomWidth: 0,

    },
    table9: {
        display: "table",
        width: "50%",
        borderStyle: "solid",
        marginLeft: 2,
        marginRight: 2,
        borderRightWidth: 0,
        borderBottomWidth: 0,

    },
    table1: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        marginLeft: 10,
        marginRight: 10,
        padding: '5px 35px',
        borderRightWidth: 0,
        borderBottomWidth: 0,

    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableRowFnDetail: {
        margin: "auto 0",
        flexDirection: "row"
    },
    tableRowDeclaration: {
        margin: 'auto 20',
        flexDirection: "row",
        marginLeft: 45
    },
    tableRowDeclaration1: {
        margin: 'auto 20',
        flexDirection: "row",
        marginLeft: 41
    },
    tableCol19: {
        width: "20%",
        textAlign: 'left',
        borderStyle: "solid",
        borderColor: "#1a2480",
        borderBottom: 0,
        marginTop: '3px',
        marginBottom: '3px',
        fontFamily: 'Helvetica-Bold',
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol191: {
        width: "20%",
        textAlign: 'left',
        borderStyle: "solid",
        borderColor: "#1a2480",
        borderBottom: 0,
        marginTop: '3px',
        fontFamily: 'Helvetica-Bold',
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginLeft: 20
    },
    tableCol9: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#1a2480",
        borderBottomWidth: 1,
        borderBottomColor: '#005EB8',
        borderBottom: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#1a2480",
        borderBottomWidth: 1,
        borderBottomColor: '#005EB8',
        borderLeftWidth: 0,
        borderTopWidth: 0
    },

    tableColheaderpage9: {
        width: 258,

        borderStyle: "solid",
        backgroundColor: "#012169"
    },

    tableColpage9: {
        width: "258",
        borderStyle: "solid",
        borderBottomWidth: 1,

        borderBottomColor: '#005EB8',
        // borderColor: "#012169",
        // borderBottom: 2,
        // borderLeftWidth: 0,
        // borderTopWidth: 0
    },

    tableColpage9R: {
        width: "260",
        // height:"80",
        marginRight: 30,
        borderStyle: "solid",
        borderBottomWidth: 1,

        borderBottomColor: '#005EB8',
        // borderColor: "#012169",
        // borderBottom: 2,
        // borderLeftWidth: 0,
        // borderTopWidth: 0
    },

    tableCellpage9: {
        marginLeft: "12",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: '10',
        paddingBottom: '10',
        fontSize: 8
    },

    tableColsmall: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#1a2480",
        textAlign: "left",
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    table3Col: {
        width: "35%",
        borderStyle: "solid",
        textAlign: "left",
        borderBottom: 2,
        // borderBottom: 2,
        borderBottomWidth: 1,
        borderBottomColor: '#005EB8',

        borderLeftWidth: 0,
        borderTopWidth: 0
    },

    tableColheader: {
        width: "25%",
        borderStyle: "solid",
        backgroundColor: "#012169",
        marginRight: 0,
    },
    tableCol3header: {
        width: "35%",
        borderStyle: "solid",
        backgroundColor: "#012169"
    },
    tableCellHeader: {
        marginLeft: "12",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 8,
        color: "#ffffff",
        fontFamily: 'Helvetica-Bold'
    },
    tableCellHeaderNeeds: {
        marginLeft: "5",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 8,
        color: "#ffffff",
        fontFamily: 'Helvetica-Bold'
    },
    tableCellHeader199: {
        margin: 'auto',
        fontSize: 8,
        color: "#ffffff",
        fontFamily: 'Helvetica-Bold'
    },
    tableCellHeader1991: {
        margin: '10 auto',
        fontSize: 8,
        color: "#ffffff",
        fontFamily: 'Helvetica-Bold'
    },
    tableCellHeader1: {
        marginTop: 5,
        fontSize: 8,
        color: "#ffffff",
        fontFamily: 'Helvetica-Bold',
        textAlign: 'center'
    },
    tableCellHeader101: {
        fontSize: 8,
        color: "#ffffff",
        fontFamily: 'Helvetica-Bold',
        textAlign: 'center'
    },
    tableCell: {
        // margin: "12",
        marginTop: 5,
        fontSize: 8,
    },
    tableCellchoice: {
        // margin: "12",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 8,
    },
    tableCellNeeds: {
        marginLeft: 5,
        marginTop: 5,
        fontSize: 8,
    },
    tableCellBold: {
        // margin: "12",
        marginTop: 5,
        fontSize: 8,
        fontFamily: 'Helvetica-Bold'
    },
    tableCellBoldNeeds: {
        marginLeft: 5,
        marginTop: 5,
        fontSize: 8,
        fontFamily: 'Helvetica-Bold'
    },
    tableCellProtectionGap: {
        fontFamily: 'Helvetica-Bold',
        marginTop: 5,
        fontSize: 8,
    },

    tableColpage7: {


    },
    tableCellpage7: {
        width: 520,
        height: 'auto',
        minHeight: 170,
        maxHeight: 330,
        margin: '0 auto',
        fontSize: 8,
        padding: 5,
        border: 1,
        borderColor: "#005EB8",
    },

    tableColLong: {
        width: "83%",
        borderStyle: "solid",
        borderColor: "#005EB8",
        borderBottomWidth: 0.5,
        borderLeftWidth: 0,
        borderRightWidth: 1,
        borderTopWidth: 1,
        marginLeft: 0,
        marginRight: 10
    },
    tableCell8: {
        margin: "auto 5",
        paddingTop: 3,
        paddingBottom: 3,
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#005EB8",
        textAlign: 'left'
    },
    tableRow8: {
        // margin: 25,
        marginleft: 15,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#005EB8"
    },

    tableCellHeader8: {
        margin: "12",
        marginTop: 5,
        fontSize: 8,
        color: "#ffffff",
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold'
    },
    tableRow14: {
        margin: 25,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#1a2480",

    },
    tableRow141: {
        margin: 10,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#1a2480",

    },
    tableCell14: {
        marginLeft: "12",
        marginBottom: 'auto',
        marginTop: 'auto',
        paddingTop: '2px',
        paddingBottom: '2px',
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480",
        textAlign: 'left'
    },
    tableCell1495: {
        marginLeft: "12",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: '5',
        paddingBottom: '5',
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480"
    },
    tableCell149: {
        marginLeft: 8,
        marginTop: 5,
        marginBottom: 5,
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480"
    },
    tableCell141: {
        margin: "auto",
        paddingTop: '2px',
        paddingBottom: '2px',
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480",
        textAlign: 'left'
    },
    tableCell14RSP: {
        margin: "12",
        marginTop: 3,
        marginBottom: 5,
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480"
    },
    tableCellHeader14: {
        marginLeft: "12",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: '5',
        paddingBottom: '5',
        fontSize: 8,
        color: "#ffffff",
        fontFamily: 'Helvetica-Bold'
    },
    tableCellHeader19: {
        margin: "12",
        marginTop: 5,
        fontSize: 8,
        color: "#000",
        marginBottom: 7
    },
    tableColheader14: {
        width: "47%",
        borderStyle: "solid",
        backgroundColor: "#012169",
        marginLeft: 12,
    },
    tableColheaderRiskProfile14: {
        width: "47%",
        borderStyle: "solid",
        backgroundColor: "#012169",
        marginLeft: 10,
    },
    tableColheaderRiskProfile15: {
        width: "48%",
        borderStyle: "solid",
        backgroundColor: "#012169",
        marginLeft: 2,
    },
    tableColheader14R: {
        width: "47%",
        borderStyle: "solid",
        backgroundColor: "#012169",
        marginRight: 10
    },

    tableColLong14: {
        width: "60%",
        borderStyle: "solid",
        borderColor: "#1a2480",
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0
    },
    tableColheader19: {
        width: "70%",
        borderStyle: "solid",
        backgroundColor: "#e6e6e6",
        textAlign: 'left',
        fontSize: '10px',
        color: 'black'
    },
    tableColheader191: {
        width: "30%",
        borderStyle: "solid",
        backgroundColor: "#e6e6e6",
        textAlign: 'left',
        fontSize: '10px',
        color: 'black'
    },
    tableColLong19: {
        width: "60%",
        borderStyle: "solid",
        borderColor: "#fff",
        borderBottom: 1,
        borderLeftWidth: 0,
        borderRightWidth: 1,
        borderTopWidth: 0,
        textAlign: 'center',
        fontSize: '10px'
    },
    img3: {
        width: 200,
        height: 35,
        margin: 20,
        marginTop: 0,
        marginBottom: 0
    },
    img33: {
        width: 100,
        height: 12,
        margin: 'auto 10',
    },
    tableCell3: {
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: '5px',
        fontSize: 8,
    },
    tableColLong3p2: {
        width: "31.5%",
        borderStyle: "solid",
        borderBottomColor: "#005EB8",
        borderBottomWidth: 0.5,
        // borderBottom: 0.5,
        // borderLeftWidth: 0,
        // borderRightWidth: 0,
        // borderTopWidth: 0
    },


    textP: {
        fontSize: 8,
        margin: 20,
        marginTop: 10,
        textcolor: "black",
    },
    tableRowInside: {
        margin: 25,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,

    },
    tableColInside: {
        width: "60%",
        backgroundColor: "#c5c9c9",
    },
    tableCellInside: {
        margin: "12",
        marginTop: 5,
        fontSize: 8,
        color: "black"
    },

    textR4: {
        position: 'absolute',
        fontSize: 7,
        bottom: 30,
        left: 0,
        right: 0,
        fontsize: 8,
        textAlign: "right",
        // marginLeft: 45,

        marginRight: 60,
    },
    textl4: {
        position: 'absolute',
        fontSize: 7,
        bottom: 30,
        left: 0,
        right: 0,
        fontsize: 8,
        textAlign: "left",
        marginLeft: 60,

        marginRight: 40,
    },
    sign4: {
        position: 'absolute',
        fontSize: 8,
        bottom: 50,
        left: 0,
        right: 0,
        fontsize: 8,
        textAlign: "left",
        marginLeft: 60,

        marginRight: 40,

    }, sign41: {
        position: 'absolute',
        fontSize: 8,
        bottom: 50,
        left: 0,
        right: 0,
        fontsize: 8,
        textAlign: "left",
        marginLeft: 60,
        marginRight: 40,
        borderBottom: '1px solid #000'

    },
    signl5: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        marginLeft: 5,
        marginRight: 5,

    },
    sign5: {
        position: 'absolute',
        fontSize: 8,
        bottom: 50,
        left: 0,
        right: 0,
        textAlign: "center",
        marginLeft: 65,

        marginRight: 30,

    },
    signatureSize: {
        position: 'absolute',
        bottom: 60,
        width: 80,
        height: 30,
        left: 0,
        right: 0,
        textAlign: "left",
        marginLeft: 40,
        marginRight: 40,
    },
    mainApplicantSign: {
        position: 'absolute',
        top: 260,
        width: 80,
        height: 30,
        left: 40,
        right: 0,
        textAlign: "left",
        marginLeft: 40,
        marginRight: 40,
        fontFamily: 'Helvetica-Bold',
    },
    mainApplicantSign1: {
        position: 'absolute',
        top: 10,
        width: 80,
        height: 30,
        left: 40,
        right: 0,
        textAlign: "left",
        marginLeft: 40,
        marginRight: 40,
        fontFamily: 'Helvetica-Bold',
        fontSize: 8,
    },
    advisorSign: {
        position: 'absolute',
        top: 10,
        width: 80,
        height: 30,
        left: 0,
        right: 0,
        textAlign: "left",
        marginLeft: 50,
        marginRight: 40,

    },
    coApplicantSign: {
        position: 'absolute',
        top: 190,
        width: 80,
        height: 30,
        left: 350,
        right: 20,
        textAlign: "center",
        marginLeft: 40,
        marginRight: 40,
    },
    declarationsignatureSize: {
        position: 'absolute',
        bottom: 10,
        width: 80,
        height: 30,
        left: 10,
        right: 20,
        textAlign: "right",
        marginLeft: 5,
        marginRight: 0,
    },
    CoAppsignatureSize: {
        position: 'absolute',
        width: 80,
        height: 30,
        fontSize: 8,
        bottom: 60,
        left: 50,
        right: 50,
        textAlign: "center",
        marginLeft: 200,
        marginRight: 0,
    },
    AdvisorsignatureSize: {
        position: 'absolute',
        bottom: 60,
        width: 80,
        height: 30,
        left: 0,
        right: 0,
        textAlign: "right",
        marginLeft: 40,
        marginRight: 40,
    },
    image4: {
        width: 100,
        height: 70,
        marginLeft: 35,
        marginTop: 20,
        marginRight: 40,
    },
    image4Logo: {
        width: 100,
        height: 80,
        marginLeft: 35,
        marginTop: 20,
        marginRight: 40,
    },
    image5: {
        width: 84,
        height: 70,
        marginLeft: 31,
        //  marginTop:20,

        marginRight: 40,

    },
    image94: {
        width: 84,
        height: 70,
        marginLeft: 0,

        marginRight: 0,

    },
    text4: {
        fontSize: 9,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 40,
        marginBottom: 5,

        marginRight: 40

    },
    text4114: {
        fontSize: 9,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 41,
        marginBottom: 5,
        fontFamily: 'Helvetica-Bold',
        marginRight: 40

    },
    text41141: {
        fontSize: 9,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 41,
        marginBottom: 10,
        fontFamily: 'Helvetica-Bold',
        marginRight: 40

    },
    text4RSP: {
        fontSize: 9,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 45,
        marginBottom: 2,

        marginRight: 40

    },
    text44: {
        fontSize: 9,
        textAlign: "right",
        marginLeft: 40,
        padding: 5,

        marginRight: 40

    },
    image1: {
        marginTop: 5,
        marginLeft: 45,
        marginRight: 40,
        width: 500,
        height: 550,
        // marginLeft: 20,
        // marginRight:20,
    },
    text2: {

        fontSize: 9,

        textAlign: "left",

        marginLeft: 40,

        padding: 5,
        marginRight: 40
    },
    imageOne: {
        width: 84,
        height: 70,
        marginLeft: 40,
        textAlign: 'right',
        marginRight: 40,
    },
    imageOneright: {
        width: "75",
        height: "70",
        marginLeft: "80",
        marginTop: 10,
    },
    textp1: {
        fontSize: 25,
        color: "#012169",
        textAlign: "left",
        fontFamily: 'Helvetica-Bold',
        marginLeft: 40,
        marginTop: "2%",
        marginRight: 40,
        padding: 5,
        fontFamily: 'Helvetica-Bold'
    },
    textp2: {
        // marginTop: "3%",
        fontSize: 10,
        color: "#005EB8",
        textAlign: "left",
        marginLeft: 45,
        marginRight: 40,
        fontFamily: 'Helvetica-Bold'

    },
    textp3: {
        // marginTop: "3%",
        fontSize: 10,
        color: '#005EB8',
        textAlign: "left",
        marginLeft: 45,
        marginBottom: 10,
        marginRight: 40,
        fontFamily: 'Helvetica-Bold'



    },
    textTitleForDisclaimers: {
        fontSize: 10,
        color: '#2b81bd',
        textAlign: 'left',
        marginLeft: '40px',
        paddingTop: '30px'
        //margin:'20px'
    },
    textParaStyle: {
        marginLeft: '40px', marginRight: '40px',
        fontSize: 8,
        color: 'black',
        fontFamily: 'Helvetica-Bold'

    },
    textParaStyleOthers1: {
        marginLeft: '40px', marginRight: '40px',
        // paddingTop: '14px',
        fontSize: 8,
        color: 'black',
        fontFamily: 'Helvetica-Bold'
    },
    textParaStyleOthers: {
        marginLeft: '40px', marginRight: '40px',
        paddingTop: '14px',
        fontSize: 8,
        color: 'black',
        fontFamily: 'Helvetica-Bold'
    },
    protectionGaptableRow1: {
        //margin: "20px",
        flexDirection: "row",
        //fontFamily: 'Helvetica-Bold'
    },
    protectionGaptableRow1Content: {
        // marginLeft: 20, marginRight: 20,
        flexDirection: "row",
        //backgroundColor: "#012169",
        fontFamily: 'Helvetica-Bold'
    },
    protectionGaptableCol1Content: {
        width: "32%",
        textAlign: "left",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        // borderLeftWidth: 0,
        // borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        // paddingBottom: "4px", 
    },
    protectionGaptableCol2Content: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        paddingBottom: "4px",
        marginRight: "2px"
    },
    protectionGaptableCol3Content: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        paddingBottom: "4px",
        marginRight: "2px",
        marginLeft: '2'
    },
    protectionGaptableCol4Content: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        paddingBottom: "4px",
        marginRight: "1px",
        marginLeft: '1'
    },

    protectionGaptableCol5Content: {
        width: "16%",
        marginRight: 20,
        borderStyle: "solid",
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        paddingBottom: "4px"

    },

    protectionGaptableCell1Content: {

        margin: "auto 5",
        // marginTop: 5,
        fontSize: 8,
        textAlign: 'left',
    },

    benefittableCol1: {
        width: "32%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        // paddingBottom: "4px",
        backgroundColor: "#012169",
        marginRight: "2px"
    },
    benefitTableCell1: {
        margin: "auto 5",
        paddingTop: '5',
        paddingBottom: '5',
        fontSize: 8,
        fontFamily: 'Helvetica-Bold',
        color: 'white',
        textAlign: 'left',
    },
    protectionGaptableCol2: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingBottom: "4px",
        backgroundColor: "#012169",
        marginRight: "2px"
    },
    protectionGaptableCol3: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingBottom: "4px",
        backgroundColor: "#012169",
        marginRight: "2px"
    },
    protectionGaptableCol4: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingBottom: "4px",
        backgroundColor: "#012169",
        // paddingRight: "1px"
    },
    table199: {
        display: "table",
        width: "auto",
        marginLeft: 40,
        marginRight: 10,
        borderStyle: "none",
        borderWidth: 0,
        // borderRightWidth: 0,
        //borderBottomWidth: 0 ,
        //borderTopWidth: 0,
        marginTop: 0,
        align: "left"
    },
    tableCell199: {
        marginTop: 5,
        fontSize: 8,
        textAlign: 'left',
        marginLeft: 5
    },
    tableCell199Blue: {
        marginTop: 5,
        fontSize: 8,
        textAlign: 'left',
        marginLeft: 5,
        fontFamily: 'Helvetica-Bold',
        color: '#005EB8'
    },
    tableCell199BoldFont: {
        marginTop: 5,
        fontSize: 8,
        textAlign: 'left',
        marginLeft: 5,
        fontFamily: 'Helvetica-Bold'
    },
    tableCell1991: {
        margin: "auto 10",
        marginTop: 5,
        fontSize: 8,
        textAlign: 'left'
    },
    tableCell1991Blue: {
        margin: "auto 10",
        marginTop: 5,
        fontSize: 8,
        color: '#005EB8',
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold'
    },
    tableCell1991BoldFont: {
        margin: "auto 10",
        marginTop: 5,
        fontSize: 8,
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold'
    },
    textMajorTitle: {
        fontSize: 10,
        color: '#2b81bd',
        textAlign: 'left',
        margin: '20px',
        // paddingTop:'20px',
    },
    textTitle: {
        fontSize: 10,
        color: '#2b81bd',
        textAlign: 'left',
        marginLeft: '20px'
        //margin:'20px'
    },
    textTitle1: {
        fontSize: 10,
        color: '#2b81bd',
        textAlign: 'left',
        marginLeft: '20px',
        marginTop: 10
        //margin:'20px'
    },


    budgettableCol: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingBottom: "4px"

    },
    budgettableColContent: {
        width: "65%",
        borderStyle: "solid",
        // borderWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        paddingBottom: "4px",
        textAlign: 'left'
    },
    budgettableColContent1: {
        width: "35%",
        borderStyle: "solid",
        // borderWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        paddingBottom: "4px",
        textAlign: 'left'
    },
    GapTableCol: {
        width: "50%",
        borderStyle: "solid",
        // borderWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#005EB8',
        paddingBottom: "4px",
        textAlign: 'left',
        marginBottom: 20
    },
    tableRow199: {
        margin: "0px 20px",
        flexDirection: "row"
        // backgroundColor: "blue"
    },
    tableRow1199: {
        margin: "0px 20px",
        flexDirection: "row",
        backgroundColor: "#012169",
        fontFamily: 'Helvetica-Bold'
    },
    tableCell133: {
        margin: "auto 7",
        paddingTop: '5px',
        paddingBottom: '5px',
        marginTop: 5,
        fontSize: 8,
        fontFamily: 'Helvetica-Bold',
        color: 'white',
        textAlign: 'left'
    },
    budgettable: {
        display: "table",
        width: "auto",
        borderStyle: "none",
        borderWidth: 0,
        marginLeft: 17,
        marginRight: 20,
        // borderRightWidth: 0, 
        //borderBottomWidth: 0 ,
        //borderTopWidth: 0,

        marginTop: 20,
        textAlign: 'center'
    },

    tableRow555: {
        margin: "auto",
        flexDirection: "row"
        // backgroundColor: "blue"
    },
    tableRow1555: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#012169",
        fontFamily: 'Helvetica-Bold'
    },

    tableCol555: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomColor: '#012169',
        paddingBottom: "4px"
    },
    tableCol1555: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingBottom: "4px"
    },
    tableCell1555: {
        margin: "auto",
        marginTop: 5,
        fontSize: 8,
        fontFamily: 'Helvetica-Bold',
        color: 'white'
    },
    table555: {
        display: "table",
        width: "100%",
        borderStyle: "none",
        borderWidth: 0,
        // borderRightWidth: 0, 
        //borderBottomWidth: 0 ,
        //borderTopWidth: 0,

        marginTop: 10,
        align: "left"
    },
    table5550: {
        display: "table",
        width: "100%",
        borderStyle: "none",
        borderWidth: 0,
        // borderRightWidth: 0, 
        //borderBottomWidth: 0 ,
        //borderTopWidth: 0,

        marginTop: 10,
        align: "left"
    },
    textTitle555: {
        fontSize: 10,
        color: '#2b81bd',
        //textAlign: 'left',
        marginLeft: '20px',
        marginTop: 20,
        //margin:'20px'
    },

    img5: {
        width: "90%",
        height: "15%",
        margingLeft: 40,
        margin: 5

    },
    img6: {
        width: "90%",
        height: "35%",
        marginLeft: 20
    },
    text15: {
        fontSize: 8,
        textcolor: "black",
        marginLeft: 40,
        marginTop: 10,
        marginRight: 40,
    },
    text151: {
        fontSize: 8,
        textcolor: "black",
        marginLeft: 40,
        marginTop: '1px',
        marginRight: 40,
        marginBottom: '3px'
    },
    text15Sign: {
        fontSize: 10,
        textcolor: "black",
        marginLeft: 40,
        marginTop: 10,
        marginRight: 40,
        fontFamily: 'Helvetica-Bold'
    },
    text15x: {
        fontSize: 8,
        // margin:20,
        // margin:20,
        // marginTop:10,
        // marginTop:10,
        marginBottom: 0,
        textcolor: "black",
        marginLeft: 40,
        marginLeft: 41,
        marginTop: 7,
        marginBottom: 7,
        marginRight: 40,

    },
    text4declaration: {
        fontSize: 10,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 45,
        marginBottom: 5,
        marginRight: 40,
        fontFamily: 'Helvetica-Bold',
        color: 'black'
    },
    text4declarationsub: {
        fontSize: 9,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 45,
        marginBottom: 5,
        marginRight: 40,
        color: 'black',
        fontFamily: 'Helvetica-Bold',
    },
    text4declarationsubNew: {
        fontSize: 9,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 50,
        marginBottom: 5,
        marginRight: 40,
        color: 'black',
        fontFamily: 'Helvetica-Bold',
    },
    text4declarationsub1: {
        fontSize: 9,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 43,
        marginBottom: 5,
        marginRight: 40,
        color: 'black'
    },
    text4declarationsub12: {
        fontSize: 8,
        textAlign: "left",
        marginTop: 5,
        marginLeft: 40,
        marginBottom: 5,
        marginRight: 40,
        color: 'black',
    },
    tableColheader15declarationcheckbox: {
        borderStyle: "solid",
        backgroundColor: "#D9D9D6",
        textAlign: "left",
        marginTop: 5,
        marginLeft: 0,
        marginBottom: 5,
        marginRight: 5,
        width: 10,
        height: 10,
    },
    text15s: {
        fontSize: 8,
        // margin:20,
        marginTop: 10,
        textcolor: "black",
        marginLeft: 40,
        marginBottom: 2,
        marginRight: 40
    },
    tableCellpage15: {
        width: 520,
        height: 'auto',
        minHeight: 170,
        maxHeight: 330,
        marginLeft: 40,
        padding: 5,
        fontSize: 8,
        border: 1,
        borderColor: "#D9D9D6",
    },
    tableColheader15: {
        // marginTop:10,
        borderStyle: "solid",
        backgroundColor: "#D9D9D6",
        // marginLeft: 15,
        // width: "25%", 
        width: 250,
        height: 50,
    },
    tableColheader16: {
        // marginTop:10,
        borderStyle: "solid",
        // backgroundColor:"#D9D9D6",
        marginLeft: 15,
        // width: "25%", 
        width: 250,
        height: 50,
        borderColor: "#D9D9D6"
    },
    tableRow15: {
        margin: 25,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#D9D9D6"
    },
    // tableColLong15: { 
    //   width: "50%", 
    //   borderStyle: "solid", 
    //   borderColor:"grey",
    //   borderBottom: 1,
    //   borderLeftWidth: 1, 
    //   borderRightWidth:1,
    //   borderTopWidth: 1
    // }, 
    tableColLong16day2: {
        width: "50%",
        height: 25,
        borderStyle: "solid",
        border: "3px solid #dedcdc",
        marginRight: 0,
        marginLeft: 10,
        marginTop: 5,
    },
    tableColLong16day: {
        width: "50%",
        borderStyle: "solid",
        border: "3px solid #dedcdc",
        marginRight: 5,
        marginLeft: 12,
        marginTop: 5
    },
    tableColLong16X: {
        width: "50%",
        height: 50,
        marginLeft: 10,
        borderStyle: "solid",
        borderColor: "#D9D9D6",
        borderBottom: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        marginRight: 10,
        // marginLeft: 10
    },
    tableRow8t1: {
        margin: 25,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#D9D9D6",
        marginLeft: 12

    },
    text5: {
        fontSize: 8,
        textcolor: "#dedcdc",
        marginLeft: 40,
        marginTop: 10,
        marginRight: 40
    },
    text51: {
        fontSize: 8,
        textcolor: "#dedcdc",
        marginLeft: 39,
        marginTop: 10,
        marginRight: 40
    },
    text511: {
        fontSize: 8,
        textcolor: "#dedcdc",
        marginLeft: 10,
        marginTop: '3px',
        marginRight: 40
    },
    text5113: {
        fontSize: 8,
        textcolor: "#dedcdc",
        marginLeft: 10,
        marginTop: '10px',
        marginRight: 40
    },
    text512: {
        fontSize: 8,
        textcolor: "#dedcdc",
        marginLeft: 39,
        marginTop: 15,
        marginRight: 40
    },
    text5s: {
        fontSize: 8,
        // margin:20,
        marginTop: 10,
        textcolor: "black",
        marginLeft: 40,
        marginBottom: 2,
        marginRight: 40
    },
    tableColLong16: {
        width: "50%",
        height: 50,
        // marginLeft:10,
        border: '3px solid #dedcdc',
        marginRight: 5,
        marginLeft: 12,
        marginTop: 5
    },
    tableColLong162: {
        width: "50%",
        height: 50,
        // marginLeft:10,
        border: '3px solid #dedcdc',
        marginRight: 0,
        marginLeft: 5,
        marginTop: 5
    },
    tableColLong161: {
        width: "50%",
        height: 50,
        // marginLeft:10,
        border: '3px solid #dedcdc',
        marginRight: 0,
        marginLeft: 12
    },
    tableRow8: {
        margin: 25,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#D9D9D6",
        marginLeft: 38

    },
    tableRow8declaration: {
        margin: 25,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#D9D9D6",
        marginLeft: 25
    },
    tableRow8declarationSign: {
        margin: 25,
        flexDirection: "row",
        marginTop: 0,
        marginBottom: 0,
        borderStyle: "solid",
        borderColor: "#D9D9D6",
        marginLeft: 25,
        marginRight: 20
    },

    tableCellHeader15: {
        margin: "12",
        marginTop: 5,
        fontSize: 11,
        color: "black"
    },
    imageOneright: {
        width: "20%",
        height: "10%",
        marginLeft: "75%",
        marginTop: 10,
    },

    tableCell13p1: {
        // margin: "12",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480",
        width: "50%",
        marginLeft: 5
    },

    tableCell13p2: {
        margin: "12",
        marginTop: 0,
        marginBottom: 0,
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480",
        alignContent: 'flex-end',
        textAlign: 'right'
    },
    tableCell13p29: {
        margin: "12",
        marginTop: 3,
        marginBottom: 3,
        fontSize: 8,
        borderStyle: "solid",
        borderColor: "#1a2480",
        alignContent: 'flex-end',
        textAlign: 'right',
        borderBottom: '1px',
    },
    tableCell13p21: {
        margin: 12,
        marginTop: 2,
        marginBottom: 10,
        fontSize: 8,
        width: '50%',
        borderStyle: "solid",
        borderColor: "#1a2480",
        alignContent: 'flex-end',
        textAlign: 'right',
        borderBottom: '1px'
    },
    tableColLong13: {
        width: "47%",
        borderStyle: "solid",
        // borderColor: "#1a2480",
        borderBottomWidth: 1,

        borderBottomColor: '#005EB8',
        marginLeft: 12
    },

    tableColLong13R: {
        margin: 12,
        width: "47%",
        borderStyle: "solid",

        borderBottomWidth: 1,


        borderBottomColor: '#005EB8',

        marginRight: 10,

    },

    tableCell13p1blue: {
        // margin: "12",
        marginTop: 7,
        marginBottom: 0,
        fontSize: 8,
        color: '#1D8EBF',
        borderStyle: "solid",
        borderColor: "#1a2480",
        width: "40%"
    },

    tableCell13p2blue: {
        margin: "12",
        marginTop: 0,
        marginBottom: '0%',
        fontSize: 8,
        color: '#1D8EBF',
        borderStyle: "solid",
        borderColor: "#1a2480",
        width: "20%",
        marginLeft: "75%"
    },

    tableColLong3: {
        width: "30%",
        marginLeft: 15,

    },
    tableColLong3p2: {
        width: "31.5%",
        borderStyle: "solid",
        borderColor: "#1a2480",
        borderBottomWidth: 1,
        marginLeft: 10,
        borderBottomColor: '#005EB8',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0
    },
    tableColLong3p3: {
        width: "31.5%",
        borderStyle: "solid",
        borderBottomWidth: 1,
        // marginLeft:10,
        borderBottomColor: '#005EB8',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0
    },
    tableColheader3: {
        width: "31.5%",
        borderStyle: "solid",
        backgroundColor: "#012169",
        marginLeft: 0
    },
    tableColLong3p9: {
        width: "50%",
        borderStyle: "solid",
        borderColor: "#005EB8",
        borderBottomWidth: 0.5,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0
    },
    tableColheader9: {
        width: "20%",
        borderStyle: "solid",
        backgroundColor: "#012169"
    },
    tableColLong3p1: {
        width: "30%",

    },
    tableColLong3p19: {
        width: "50%",
    },
    tableCellHeader3: {
        marginLeft: "7",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: '5',
        paddingBottom: '5',
        fontSize: 8,
        color: "#ffffff"
    },
    tableColLong13First: {
        width: "60%",

    },
    tableRow10: {

        margin: "auto",

        flexDirection: "row"

    },

    tableCellHeader10: {
        marginLeft: "5",
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 8,
        color: "white",
        fontFamily: 'Helvetica-Bold'
    },
    tableColheader10b: {
        width: "60%",
        marginLeft: 40,



        borderStyle: "solid",

        backgroundColor: "#012169"

    },
    tableColheader10br: {

        width: "55%",
        // marginLeft:40,



        borderStyle: "solid",

        backgroundColor: "white"

    },


    tableCol3header10: {

        width: "35%",

        borderStyle: "solid",

        backgroundColor: "#012169"

    },
    tableColheader10: {

        width: "25%",

        borderStyle: "solid",

        backgroundColor: "#012169"

    },

    tableCellpage10: {
        width: 520,
        height: 'auto',
        minHeight: 170,
        maxHeight: 330,
        marginLeft: "7%",
        marginRight: 20,
        fontSize: 8,
        padding: 5,
        border: 1,
        borderColor: "#136898",
    },
    pageNumber: {
        marginLeft: "92%",
        marginRight: 0,
        marginTop: 30,
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        position: 'absolute',
        background: 'red'
    }

});

// Create Document Component
const BasicDocument = ({ countryDialCodeList, espillustrationDetails, signDate, reportData, activeTab, notesValue, clientSign, coappSign, advisorSign, illustrationDetails, setIsetIllustrationDetails, llustrationDetails, espProducts }: any) => {
    const [clientSignature, setClientSignature] = useState('')
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))
    const [notesDetails, setNotesDetails] = useState('')
    const [advisorName, setAdvisorName] = useState(userDetails?.name)
    const [advisorSignature, setAdvisorSignature] = useState('')
    const [coApplicantSign, setCoApplicantSignature] = useState('')
    const [totalprevYear, setTotalprevYear] = useState(0)
    const [totalcurrentYear, setTotalcurrentYear] = useState(0)
    const [totalcurrentPortfolioAndAssets, setTotalcurrentPortfolioAndAssets] = useState(0)
    const [totalgapAmount, setTotalgapAmount] = useState(0)
    const [showAdditionalChoicePage, setAdditionalChoicePage] = useState(false)
    const [latestDate, setLatestDate] = useState()
    const [iscoapRSPPage, setIscoapRSPPage] = useState(false)
    const [iscoapITAPage, setIscoapITAPage] = useState(false)
    const [mainAppSignDate, setMainAppSignDate] = useState(signDate)
    const [coAppSignDate, setCoAppSignDate] = useState(signDate)
    const [advisorSignDate, setAdvisorSignDate] = useState()
    const [espToDepBenefitMap, setEspToDepBenefitMap] = useState()

    React.useEffect(() => {
        let date = new Date()
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: 'numeric', month: 'long', year: 'numeric'
        }).replace(/ /g, ' ');
        setLatestDate(formattedDate)
        setNotesDetails(notesValue)
    }, [notesValue])
    React.useEffect(() => {
        if (espProducts.length) {
            const mapDep = new Map();
            espProducts.forEach((esp) => {
                if (!mapDep.has(esp?.educationNeed?.dependentId)) {
                    mapDep.set(esp.educationNeed.dependentId, esp.benefits[0])
                }
            })
            setEspToDepBenefitMap(mapDep);
        }
    }, [espProducts])
    const _getFormatedDob = (dob) => {
        if (dob) {
            let date = new Date(dob)
            const formattedDate = date.toLocaleDateString('en-GB', {
                day: 'numeric', month: 'long', year: 'numeric'
            }).replace(/ /g, ' ');
            return formattedDate;
        }
    }
    React.useEffect(() => {
        const objectURL = clientSign ? clientSign : null;
        const objectURL1 = advisorSign ? advisorSign : null;
        setClientSignature(objectURL)
        setAdvisorSignature(objectURL1)
        setCoApplicantSignature(coappSign ? coappSign : null)
    }, [clientSign, advisorSign, coappSign])
    const _getMarginTop = (length) => {
        if (length >= 0 && length <= 4) {
            return '53%'
        }
        else if (length > 4 && length < 8) {
            return '48%'
        } else if (length === 8) {
            return '43%'
        }
        else if (length >= 9) {
            return '36%'
        }
        else {
            return '63%'
        }
    }
    const _getPriorityImage = (priority) => {
        if (priority === 'LOW') {
            return <Image
                style={styles.star}
                src={star1} alt="low medium" />
        }
        else if (priority === 'LOW_MEDIUM') {
            return <Image
                style={styles.star}
                src={star2} alt="medium" />
        }
        else if (priority === 'MEDIUM') {
            return <Image
                style={styles.star}
                src={star3} alt="medium" />
        }
        else if (priority === 'MEDIUM_HIGH') {
            return <Image
                style={styles.star}
                src={star4} alt="medium high" />
        }
        else {
            return <Image
                style={styles.star}
                src={star5} alt="high" />
        }

    }
    const getRiskIndex = (index) => {
        if (index === 'MEDIUM' || index === 'Balanced') {
            return 3

        } else if (index === 'MEDIUM_HIGH' || index === 'Performance Driven') {
            return 4

        } else if (index === 'LOW_MEDIUM' || index === 'Cautious') {
            return 2

        } else if (index === 'LOW' || index === 'Defensive') {
            return 1

        } else if (index === 'HIGH') {
            return 5
        }
    }

    const _marginBottomChoice = (element) => {
        let arrayBenifits = element?.benefits?.filter(ele => ele.isSelected === true)
        let length = arrayBenifits?.length;
        /*if (length >= 8) {
            return '10%'
        }
        else if (length > 6 && length < 8) {
            return '40%'
        } else {
            return '47%'
        }*/
        return '0'
    }
    const _getCountry = (code) => {
        let countryDetail = countryDialCodeList?.find((element) => element.isoCode === code)
        return countryDetail?.name
    }
    const _getRiskProfilerIndex = (index) => {
        if (index <= 1 || index === 'LOW') {
            return 'low'
        } else if (index <= 3 && index >= 2 || index === 'LOW_MEDIUM' || index === 'MEDIUM_HIGH' || index === 'MEDIUM') {
            return 'medium'
        } else if (index === 4 || index === 'MEDIUM_HIGH') {
            return 'Medium to high.'
        }
        else {
            return 'high'
        }
    }
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const bearerToken = localStorage.getItem('bearerToken')
    const headers = {
        Authorization: `Bearer ${bearerToken}`,
    };
    const requestOptions = {
        headers: headers,
    };

    const [details, setDetails] = React.useState({})

    const [detailsObject, setDetailsObject] = React.useState();
    const [fourRiskSummaryDetails, setfourRiskSummaryDetails] = React.useState();
    const [portFolioSummaryDetails, setportFolioSummaryDetails] = React.useState();
    const [planningNeeds, setPlanningNeeds] = React.useState([]);
    const [selectedCurrency, setselectedCurrency] = React.useState();
    const [gapCurrency, setGapCurrency] = React.useState('USD');
    const [budgetDetailsObject, setbudgetDetailsObject] = React.useState();
    const [expensePercentageDetails, setexpensePercentageDetails] = React.useState();
    const [expensePercentage, setExpensePercentage] = React.useState(0);
    const [riskProfilerpayload, setriskProfilerpayload] = React.useState();
    const [financialObjectivObject, setfinancialObjectivObject] = React.useState();
    const [getDependents, setDependents] = React.useState();
    const [fourCsDetails, setFourCsDetails] = React.useState()
    const [income, setIncome] = React.useState(0)
    const [expense, setExpense] = React.useState(0)
    const [savings, setSavings] = React.useState(0)
    const [productDescription, setProductDescription] = React.useState()
    const [productsArray, setProducts] = React.useState([])
    const [prevYearDetails, setPrevYearDetails] = React.useState()
    const [financialObjectivesGap, setFinancialObjectivesGap] = React.useState()
    const [isCoApplicant, setIsCoApplicant] = React.useState(false)
    const [retirementDetails, setRetirementDetails] = React.useState({})
    const [GapDetailsFinancialObjectives, setGapDetailsFinancialObjectives] = React.useState([])
    const [gapAnalysis, setGapAnalysis] = React.useState()
    const [showPlanningNeeds, setShowPlanningNeeds] = React.useState(false)
    const [showEducationNeeds, setShowEducationNeeds] = React.useState(false)
    const [showProtectionNeeds, setShowProtectionNeeds] = React.useState(false)
    const [totalSaving, setTotalSaving] = React.useState(0)
    const [flagToShowSAP,setFlagToShowSAP]= React.useState(false)
    const pageno = useRef(0)
    const _getLabel = (value) => {
        let actualValue = '';
        if (value === 'LOW' || value === 'Low') {
            actualValue = 'Not at all'
        } else if (value === 'LOW_MEDIUM' || value === 'Low Medium') {
            actualValue = 'Slightly'
        } else if (value === 'MEDIUM_HIGH' || value === 'Medium High') {
            actualValue = 'Moderately'
        } else if (value === 'HIGH' || value === 'High') {
            actualValue = 'Severely'
        } else {
            actualValue = value
        }
        return actualValue
    }
    const _getESPNotes = (dependentName) => {
        let details = productsArray?.filter(element => element?.dependentName === dependentName && element?.fullName === 'Education Saving Plan')
        if (details?.length > 0) {
            return details[0]['adviserNotes']
        } else {
            return ''
        }
    }
    const checkKeysExist = (obj) => {
        if (financialObjectivesGap !== undefined && financialObjectivesGap?.headers?.length > 0) {
            if (obj === previousYear && financialObjectivesGap?.headers?.length > 4) {
                return true
            } else if (obj === currentYear && financialObjectivesGap?.headers?.length > 3) {
                return true
            }
            else {
                return false
            }

        }
    }


    useEffect(() => {
        setDetailsObject(details)

        let amount = Number(details?.budget?.amount)
        const expensePercentage = details?.customerExpenses?.reduce((accumulator, element) => {
            return accumulator + Number(element?.percentage);
        }, 0);
        const totalIncomePer = details?.customerIncomeSources?.reduce((accumulator, element) => {
            return accumulator + Number(element?.percentage);
        }, 0);
        const expense = Math.round((Number(amount) * expensePercentage) / 100)
        const savings = Math.round(Number(amount) - Math.round((Number(amount) * expensePercentage) / 100))
        if (totalIncomePer && expensePercentage)
            setTotalSaving(Number(totalIncomePer) - Number(expensePercentage))
        setIncome(amount)
        setExpense(expense)
        setSavings(savings)


        if (typeof details?.incomeContinuation?.value === 'string') {
            let parsefcValues = JSON.parse(details?.incomeContinuation?.value)
            setFourCsDetails(parsefcValues)
        }
        else if (typeof details?.incomeContinuation?.value === 'object') {
            setFourCsDetails(details?.incomeContinuation?.value)
        } else {
            setFourCsDetails([])
        }
        setfinancialObjectivObject(details?.financialObjectives?.financialObjectives)
        setriskProfilerpayload(details?.riskProfile)
        setexpensePercentageDetails(details?.fourC?.expenses)
        setselectedCurrency(details?.currency)
        if (details?.gapCurrency) {
            setGapCurrency(details?.gapCurrency)
        }
    }, [details])
    const _getLifeStyle = (lifeStyle) => {
        if (lifeStyle === 'frugal') {
            return 'Frugal'
        } else if (lifeStyle === 'stressfree') {
            return 'Stress-free'
        } else {
            return 'Nothing but the best'
        }
    }
    const getGrowthRatePercent = (value) => {
        if (value) {
            if (value === 'MEDIUM' || value === 3) {
                return '5.00'
            } else if (value === 'MEDIUM_HIGH' || value === 4) {
                return '5.50'
            } else if (value === 'HIGH' || value === 5) {
                return '6.00'
            }
            else if (value === 'LOW_MEDIUM' || value === 2) {
                return '4.50'
            }
            else if (value === 'LOW' || value === 1) {
                return '4.00'
            } else {
                return '4.00'
            }
        } else {
            return '4.00'
        }
    }
    const _getGrowthRate = () => {
        let rate = 0;
        if (details?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true) {
            if (details?.coApplicant?.coApplicantRiskProfile?.isDifferentRiskLevel === true) {
                rate = getGrowthRatePercent(details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite)
            } else {
                rate = getGrowthRatePercent(details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex)
            }
        }
        else {
            if (details?.customerRiskProfile?.isDifferentRiskLevel === true) {
                rate = getGrowthRatePercent(details?.customerRiskProfile?.choosenRiskAppetite)
            } else {
                rate = getGrowthRatePercent(details?.customerRiskProfile?.riskProfileIndex)
            }
        }
        return rate
    }
    const _getFundAllocationImageSL = (isEsp = false) => {
        if (details?.customerRiskProfile?.isDifferentRiskLevel === true) {
            if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM') {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? balancedESP : balanced}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH') {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? adventurousESP : performance}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW') {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? defensiveESP : defensive}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM') {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? balancedESP : cutious}
                    alt="Zurich_Logo"
                />
            }
            else {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? performanceESP : adventurous}
                    alt="Zurich_Logo"
                />
            }
        }
        else {
            if (details?.customerRiskProfile?.riskProfileIndex === 3) {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? balancedESP : balanced}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.riskProfileIndex === 4) {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? adventurousESP : performance}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.riskProfileIndex === 2) {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? balancedESP : cutious}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.riskProfileIndex === 1) {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? defensiveESP : defensive}
                    alt="Zurich_Logo"
                />
            }
            else {
                return <Image
                    style={{ width: '53%', marginLeft: '40', marginRight: '40' }}
                    src={isEsp ? performanceESP : adventurous}
                    alt="Zurich_Logo"
                />
            }
        }
    }


    const _getFundAllocationImageCoApp = (isESP = false) => {
        if (details?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true) {
            if (details?.coApplicant?.coApplicantRiskProfile?.isDifferentRiskLevel === true) {
                if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : balanced}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? adventurousESP : performance}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? defensiveESP : defensive}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : cutious}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? performanceESP : adventurous}
                        alt="Zurich_Logo"
                    />
                }
            }
            else {
                if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 3) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : balanced}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 4) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? adventurousESP : performance}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 2) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : cutious}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 1) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? defensiveESP : defensive}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? performanceESP : adventurous}
                        alt="Zurich_Logo"
                    />
                }
            }
        } else {
            if (details?.customerRiskProfile?.isDifferentRiskLevel === true) {
                if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : balanced}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? adventurousESP : performance}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? defensiveESP : defensive}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : cutious}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? performanceESP : adventurous}
                        alt="Zurich_Logo"
                    />
                }
            }
            else {
                if (details?.customerRiskProfile?.riskProfileIndex === 3) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : balanced}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.riskProfileIndex === 4) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? adventurousESP : performance}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.riskProfileIndex === 2) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? balancedESP : cutious}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.riskProfileIndex === 1) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? defensiveESP : defensive}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isESP ? performanceESP : adventurous}
                        alt="Zurich_Logo"
                    />
                }
            }
        }
    }
    const _getFundAllocationImageSLRSP = (isRSP) => {
        if (details?.customerRiskProfile?.isDifferentRiskLevel === true) {
            if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM') {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? balancedESP: balancedRsp}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH') {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? adventurousESP : performanceRsp}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW') {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? defensiveESP : defensiveRsp}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM') {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? balancedESP :cutiousRsp}
                    alt="Zurich_Logo"
                />
            }
            else {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? performanceESP :adventurousRsp}
                    alt="Zurich_Logo"
                />
            }
        }
        else {
            if (details?.customerRiskProfile?.riskProfileIndex === 3) {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? balancedESP: balancedRsp}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.riskProfileIndex === 4) {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? adventurousESP : performanceRsp}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.riskProfileIndex === 2) {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? balancedESP :cutiousRsp}
                    alt="Zurich_Logo"
                />
            } else if (details?.customerRiskProfile?.riskProfileIndex === 1) {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? defensiveESP :defensiveRsp}
                    alt="Zurich_Logo"
                />
            }
            else {
                return <Image
                    style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                    src={isRSP ? performanceESP :adventurousRsp}
                    alt="Zurich_Logo"
                />
            }
        }
    }
    const _getFundAllocationImageCoAppRSP = (isRSP) => {
        if (details?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true) {
            if (details?.coApplicant?.coApplicantRiskProfile?.isDifferentRiskLevel === true) {
                if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP: balancedRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? adventurousESP : performanceRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? defensiveESP :defensiveRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP :cutiousRsp}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? performanceESP :adventurousRsp}
                        alt="Zurich_Logo"
                    />
                }
            }
            else {
                if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 3) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP: balancedRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 4) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? adventurousESP : performanceRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 2) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP :cutiousRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 1) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? defensiveESP :defensiveRsp}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? performanceESP :adventurousRsp}
                        alt="Zurich_Logo"
                    />
                }
            }
        } else {
            if (details?.customerRiskProfile?.isDifferentRiskLevel === true) {
                if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP:balancedRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? adventurousESP :performanceRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? defensiveESP :defensiveRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM') {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP :cutiousRsp}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? performanceESP :adventurousRsp}
                        alt="Zurich_Logo"
                    />
                }
            }
            else {
                if (details?.customerRiskProfile?.riskProfileIndex === 3) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP:balancedRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.riskProfileIndex === 4) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? adventurousESP :performanceRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.riskProfileIndex === 2) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? balancedESP :cutiousRsp}
                        alt="Zurich_Logo"
                    />
                } else if (details?.customerRiskProfile?.riskProfileIndex === 1) {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? defensiveESP :defensiveRsp}
                        alt="Zurich_Logo"
                    />
                }
                else {
                    return <Image
                        style={{ width: '50%', marginLeft: '40', marginRight: '40' }}
                        src={isRSP ? performanceESP :adventurousRsp}
                        alt="Zurich_Logo"
                    />
                }
            }
        }
    }
    const _getFinObjSpace = (type, index) => {
        if (type === 'PROPERTY') {
            return '70%'
        } else if (type === 'GENERAL_SAVINGS' || type === 'OTHERS') {
            return '77%'
        }
        else if (index === GapDetailsFinancialObjectives?.length - 1 && type === 'UNIVERSITY') {
            return '13%'
        }
        else if (index === GapDetailsFinancialObjectives?.length - 1) {
            return '75%'
        }
        else {
            return '78%'
        }
    }
    useEffect(() => {
        if (reportData) {
            try {

                setProductDescription(reportData);
                let signatureDate = signDate ? signDate : new Date()
                reportData?.life1SignTime ? setMainAppSignDate(reportData?.life1SignTime) : setMainAppSignDate(signatureDate);
                reportData?.life2SignTime ? setCoAppSignDate(reportData?.life2SignTime) : setCoAppSignDate(signatureDate);
                reportData?.advisorSignTime ? setAdvisorSignDate(reportData?.advisorSignTime) : setAdvisorSignDate(signatureDate);
                if (reportData?.factFindNote) {
                    setNotesDetails(reportData?.factFindNote)
                }
                else {
                    notesValue ? setNotesDetails(notesValue) : setNotesDetails('');
                }
                let dependentName = reportData?.customerSessionData?.dependents
                setDependents(dependentName)
                let sessionStatus = ((new Date() >= new Date(process.env.REACT_APP_IMAGE_DATE)) &&  (reportData?.customerSessionData?.status === 'MEETING_2' || reportData?.customerSessionData?.status === 'MEETING_1')) ||  (reportData?.customerSessionData?.status === 'APPLICATION_SUBMITTED' && (new Date(reportData?.quoteCreatedAt) >= new Date(process.env.REACT_APP_IMAGE_DATE)))
                setFlagToShowSAP(sessionStatus)
                setIsCoApplicant((reportData?.customerSessionData?.coApplicant !== undefined && reportData?.customerSessionData?.coApplicant !== null) ? true : false)
                setShowPlanningNeeds(reportData?.isPlanningNeedsVisible)
                setShowProtectionNeeds(reportData?.isProtectionNeedsVisible)
                setShowEducationNeeds(reportData?.isEducationNeedsVisible)
                setProducts(reportData?.products)
                let productsList = reportData?.products?.filter(element => element?.name !== 'Regular Saving Plan')
                setAdditionalChoicePage(productsList?.length > 0);
                setDetails(reportData?.customerSessionData)
                setportFolioSummaryDetails(reportData?.portfolioAssetsSummary)
                setPlanningNeeds(reportData?.planningNeeds)
                setfourRiskSummaryDetails(reportData?.fourRSummary?.calculatedResult)
                setPrevYearDetails(reportData?.protectionGaps)
                setFinancialObjectivesGap(reportData?.financialObjectiveGaps)
                setbudgetDetailsObject(reportData?.budgetSummary)
                setGapDetailsFinancialObjectives(reportData?.gapAnalysis?.financialObjectives)
                // setEspTableBi(reportdata?.products)
                setRetirementDetails(reportData?.gapAnalysis?.retirement)
                setGapAnalysis(reportData?.gapAnalysis)
                let rspproducts = reportData?.products?.filter(element => element?.name === 'Regular Saving Plan')
                setIscoapRSPPage(rspproducts?.length > 0);
                let itaproducts = reportData?.products?.filter(element => element?.name === 'ITA')
                let notRSP = reportData?.products?.filter(element => element?.name !== 'Regular Saving Plan')
                setIscoapITAPage((notRSP?.length === 1 && itaproducts?.length === 1));
                let financialObjGap = reportData?.financialObjectiveGaps;
                if (financialObjGap.data?.length > 0) {
                    let prevYearAmount = 0;
                    let currentYearAmount = 0;
                    let pfAmountTotal = 0;
                    let totalGapAmount = 0;

                    financialObjGap?.data?.forEach(element => {
                        prevYearAmount = prevYearAmount + element[previousYear]
                        currentYearAmount = currentYearAmount + element[currentYear]
                        pfAmountTotal = pfAmountTotal + element?.currentPortfolioAndAssets
                        totalGapAmount = totalGapAmount + element?.gapAmount
                    })
                    setTotalprevYear(prevYearAmount)
                    setTotalcurrentYear(currentYearAmount)
                    setTotalcurrentPortfolioAndAssets(pfAmountTotal)
                    setTotalgapAmount(totalGapAmount)
                }
            } catch (error) {
                // Handle error
                console.error(error);
            }
        }
    }, [reportData]);

    const _shouldDisplay = (list) => {
        let outputArray = list.filter(element => element.isSelected === true)
        return outputArray?.length > 0 ? true : false
    }


    const _getSavingSource = (id) => {
        if (id === 1) {
            return 'Bank'
        } else if (id === 2) {
            return 'Investment Vehicles'
        } else if (id === 3) {
            return 'Others'
        }
        else {
            return 'Others'
        }
    }
    const _getExpenseSource = (id) => {
        if (id === 1) {
            return 'Rent'
        } else if (id === 2) {
            return 'Household expenses'
        }
        else if (id === 3) {
            return 'Schooling'
        }
        else if (id === 4) {
            return 'University'
        }
        else if (id === 5) {
            return 'Lifestyle'
        }
        else if (id === 6) {
            return 'Debt Repayment'
        }
        else if (id === 7) {
            return 'Debt Repayment - Mortgage'
        }
        else if (id === 8) {
            return 'Remitted abroad'
        }
        else if (id === 9) {
            return 'Life Insurance Premium'
        }
        else if (id === 10) {
            return 'Others'
        }
        else {
            return 'Others'
        }
    }
    const _getIncomeSource = (id) => {
        if (id === 1) {
            if (detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) {
                return 'Salary - Life 1'
            } else {
                return 'Salary'
            }
        } else if (id === 2) {
            return 'Rental'
        }
        else if (id === 3) {
            return 'Investments'
        }
        else if (id === 4) {
            if (detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) {
                return 'Business Income - Life 1'
            } else {
                return 'Business Income'
            }
        }
        else if (id === 5) {
            return 'Others'
        }
        else if (id === 6) {
            return 'Salary - Life 2'
        }
        else if (id === 7) {
            return 'Business Income - Life 2'
        } else {
            return 'Others'
        }
    }
    const _getRelationship = (id) => {
        if (id === 1) {
            return 'Parent'
        } else if (id === 2) {
            return 'Sibling'
        }
        else if (id === 3) {
            return 'Spouse'
        }
        else if (id === 4) {
            return 'Child'
        }
        else if (id === 5) {
            return 'Aunt/Uncle'
        } else if (id === 6) {
            return 'Cousin'
        } else {
            return 'Others'
        }
    }


    const _getDescription = (type, description) => {
        if (type === 'UNIVERSITY' && description === '') {
            return 'Child_education';
        } else if (type === 'PROPERTY' && description === '') {
            return 'Property';
        } else if (type === 'MARRIAGE' && description === '') {
            return 'Marriage';
        } else if (type === 'OTHERS' && description === '') {
            return 'Others';
        }
        else {
            return description;
        }
    }
    pageno.current = 1;
    return (
        <>
            <Document title={"Personal fact find report for " + detailsObject?.firstName + " " + detailsObject?.lastName}>
//Page 1
                <Page size="A4" style={styles.body}>

                    <Image

                        style={{
                            width: 100,

                            height: 75,

                            marginLeft: "77%",

                            marginRight: 40,

                            marginTop: 10,
                        }}

                        src={logo} alt=" Zurich_Logo" />


                    <View >
                        <Text style={styles.textp1}>Ready for Life Report</Text>
                    </View>
                    <Text style={styles.textp2}>Personal fact find report for {(isCoApplicant === true) ? detailsObject?.firstName + " " + detailsObject?.lastName + " & " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName : detailsObject?.firstName + " " + detailsObject?.lastName}</Text>
                    <Text style={styles.textp3}>Planning in {selectedCurrency + " "}</Text>


  // Include Image
                    <Image
                        style={styles.image1}
                        src={firstpage} alt=" Zurich" />

                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>

                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>

                </Page>

                {/* page 2*/}
                <Page style={styles.body}>

                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>

                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#012169',
                        backgroundColor: '#012169',
                        height: 4,
                        marginLeft: 41,
                        marginRight: 40,
                        borderColor: '#012169'

                    }} />
                    <View>
                        <Text style={{ color: "#005EB8", fontSize: 11, marginLeft: 41, marginTop: 5, marginBottom: 10, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Disclosures & Disclaimers</Text>
                        <Text style={styles.textParaStyle}>Dear Client,</Text>
                        <Text style={styles.textParaStyleOthers1}>Our assessment of your objectives and financial priorities based on the information you have provided in the discussion(s) has led us to recommend the
                            below product proposition which we believe will meet your objectives and needs due to the reasons mentioned below. We would like you to consider
                            that any inaccuracy in the information provided to us could have a material impact on the appropriateness of our recommendation. If you have chosen
                            not to disclose any information that would be relevant to our financial planning this would impair our ability to include this within our financial advice.</Text>

                        <Text style={styles.textParaStyleOthers}>If you have chosen an investment product following our advice and recommendation, you should be aware that there are certain risks associated with the
                            investment products as their value is subject to market and currency fluctuations. Whilst all reasonable advice is given to secure returns, you should be
                            aware that the value of returns will depend on the investment strategy of the underlying fund(s). The product(s) we recommended are designed to be
                            long term investments and you may not get back the amount originally invested in the event of early surrender before the completion of the term.</Text>

                        <Text style={styles.textParaStyleOthers}>The value of investments may fall as well as rise and you may not get back your original investment. Past performance should not be viewed as a guide
                            to future performance. Investment in funds carries the risk of potential total loss of capital. Where an investment involves a foreign currency, it may be
                            subject to fluctuations in value due to movements in exchange rates. These changes to exchange rates may also cause the value of an underlying
                            investment to go down as well as up. On the basis that the Zurich Blue funds are only offered in US dollars, you will be exposed to currency exchange
                            risks if you decide to have your policy based on a currency different than the US Dollar.</Text>
                        <Text style={styles.textParaStyleOthers}>If you have any questions regarding our advice and/or recommendation, or on any of the documents that were provided to you, please contact us
                            immediately.</Text>
                    </View>
                    {clientSignature ? <Image
                        style={styles.signatureSize}
                        src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ? <Image
                        style={styles.CoAppsignatureSize}
                        src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>
                {/* page 3*/}
                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#012169',
                        backgroundColor: '#012169',
                        height: 4,
                        marginLeft: 39,
                        marginRight: 40,
                        borderColor: '#012169'

                    }} />
                    <Text style={{ color: "#005EB8", fontSize: 11, marginBottom: 5, marginLeft: 41, marginTop: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Personal Details</Text>
                    <View style={styles.tablePeraonalLife} >



                        {/* <View style={styles.tableRow}>
                            <View>
                                <Text> </Text>
                            </View>
            </View> */}

                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3}>

                            </View>
                            {/* <View >
                                <Text > </Text>
              </View> */}
                            <View style={styles.tableColheader3}>
                                <Text style={styles.tableCellHeader3}>{detailsObject?.title + "" + detailsObject?.firstName + " " + detailsObject?.lastName}</Text>
                            </View>
                            <View>
                                <Text> </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColheader3}>
                                    <Text style={styles.tableCellHeader3}>{detailsObject?.coApplicant?.title + "" + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                                </View>
                            }
                        </View>

                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Client ID</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{(detailsObject?.clientId)} </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{(detailsObject?.coApplicant?.clientId)}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Title</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.title}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.title}</Text>
                                </View>
                            }

                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>First Name</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.firstName}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.firstName}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Last Name </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.lastName}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.lastName}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Nationality</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{_getCountry(detailsObject?.nationality)}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{_getCountry(detailsObject?.coApplicant?.nationality)}</Text>
                                </View>
                            }
                        </View>

                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>What is your country of residence?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{_getCountry(detailsObject?.residentialCountry)}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{_getCountry(detailsObject?.coApplicant?.residentialCountry)}</Text>
                                </View>
                            }
                        </View>


                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>US tax-payer</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.fatcaDeclaration?.isUSTaxPayer === true ? "Yes" : "No"} </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.coApplicantFatcaDeclaration?.isUSTaxPayer === true ? "Yes" : "No"} </Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>US Citizen</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.fatcaDeclaration?.isUSCitizen === true ? "Yes" : "No"}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.coApplicantFatcaDeclaration?.isUSCitizen === true ? "Yes" : "No"}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>US based number</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.fatcaDeclaration?.isTelephoneUS === true ? "Yes" : "No"} </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.coApplicantFatcaDeclaration?.isTelephoneUS === true ? "Yes" : "No"} </Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Date of Birth</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{_getFormatedDob(detailsObject?.dob)}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{_getFormatedDob(detailsObject?.coApplicant?.dob)}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Gender</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.gender === 'male' ? 'Male' : 'Female'}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.gender === 'male' ? 'Male' : 'Female'}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Marital Status</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.maritalStatus === true ? "Married" : "Single"}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.maritalStatus === true ? "Married" : "Single"}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Phone Number</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.customerContactDetails?.length > 0 && detailsObject?.customerContactDetails[0]?.countryCode + " " + detailsObject?.customerContactDetails[0]?.phoneNo} </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.coApplicantContactDetails?.length > 0 && detailsObject?.coApplicant?.coApplicantContactDetails[0]?.countryCode + " " + detailsObject?.coApplicant?.coApplicantContactDetails[0]?.phoneNo} </Text>
                                </View>
                            }
                        </View>
                        {(detailsObject?.customerContactDetails?.length === 2 || detailsObject?.coApplicant?.coApplicantContactDetails?.length === 2) &&
                            <View style={styles.tableRow8}>
                                <View style={styles.tableColLong3p1}>
                                    <Text style={styles.tableCell3}>Secondary phone number</Text>
                                </View>
                                <View >
                                    <Text > </Text>
                                </View>
                                <View style={styles.tableColLong3p2}>
                                    <Text style={styles.tableCell3}>{detailsObject?.customerContactDetails?.length === 2 ? detailsObject?.customerContactDetails[1]?.countryCode + " " + detailsObject?.customerContactDetails[1]?.phoneNo : '-'} </Text>
                                </View>
                                <View >
                                    <Text > </Text>
                                </View>
                                {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                    <View style={styles.tableColLong3p3}>
                                        <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.coApplicantContactDetails?.length === 2 ? detailsObject?.coApplicant?.coApplicantContactDetails[1]?.countryCode + " " + detailsObject?.coApplicant?.coApplicantContactDetails[1]?.phoneNo : '-'} </Text>
                                    </View>
                                }
                            </View>
                        }
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Email Address</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.email}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.email}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Are you a smoker or use tobacco products?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.isSmoker === true ? "Yes" : "No"}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            {(detailsObject?.coApplicant !== undefined && detailsObject?.coApplicant !== null) &&
                                <View style={styles.tableColLong3p3}>
                                    <Text style={styles.tableCell3}>{detailsObject?.coApplicant?.isSmoker === true ? "Yes" : "No"}</Text>
                                </View>
                            }
                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Number of earning members in the family</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{detailsObject?.noOfEarningMembers}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>

                        </View>
                        <View style={styles.tableRow8}>
                            <View style={styles.tableColLong3p1}>
                                <Text style={styles.tableCell3}>Planning currency</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong3p2}>
                                <Text style={styles.tableCell3}>{selectedCurrency + " "}</Text>
                            </View>
                            <View >
                                <Text ></Text>
                            </View>

                        </View>
                    </View>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>
                <View>
                    {(detailsObject?.dependents?.length > 0) &&
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image style={styles.image4Logo} src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#012169',
                                backgroundColor: '#012169',
                                height: 4,
                                marginLeft: 45,
                                marginRight: 40,
                                borderColor: '#012169'

                            }} />
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Personal Details</Text>
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Dependants</Text>
                            <View style={{ height: '100%', display: 'inlineBlock !important', marginBottom: '60' }}>
                                {
                                    detailsObject?.dependents?.map((element, index) => {
                                        if (index < 4) {
                                            return (
                                                <View style={{ width: '100%', marginLeft: 5, marginRight: 5, marginBottom: (index === 7) ? '30%' : '10px' }} key={index}>
                                                    <View style={styles.table9}>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}><Text style={{ color: "#1a2480", fontSize: 8, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Dependant - {index + 1}</Text></Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View>
                                                                <Text style={styles.tableCell3}></Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>First Name</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{element?.firstName}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Last Name</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{element?.lastName}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Date of Birth</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getFormatedDob(element?.dob)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Gender</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{element?.gender === 'male' ? 'Male' : 'Female'}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Nationality</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getCountry(element?.nationality)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Country of residence</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getCountry(element?.residentialCountry)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Relationship</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getRelationship(element?.relationId)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            )
                                        }
                                    })
                                }
                            </View>
                            {clientSignature ?
                                <Image
                                    style={styles.signatureSize}
                                    src={clientSignature} alt=" client sign" /> : ''}
                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {coApplicantSign ?
                                <Image
                                    style={styles.CoAppsignatureSize}
                                    src={coApplicantSign} alt=" client sign" /> : ''}
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </Page>
                    }

                </View>
                <View>
                    {(detailsObject?.dependents?.length > 4) &&
                        <Page size="A4" style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#012169',
                                backgroundColor: '#012169',
                                height: 4,
                                marginLeft: 45,
                                marginRight: 40,
                                borderColor: '#012169'

                            }} />
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Personal Details</Text>
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Dependants</Text>
                            <View style={{ height: '100%', display: 'inlineBlock !important' }}>
                                {
                                    detailsObject?.dependents?.map((element, index) => {
                                        if (index >= 4) {
                                            return (
                                                <View style={{ width: '100%', marginLeft: 5, marginRight: 5, marginBottom: (index === 7) ? '30%' : '10px' }} key={index}>
                                                    <View style={styles.table9}>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}><Text style={{ color: "#1a2480", fontSize: 8, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Dependant - {index + 1}</Text></Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View>
                                                                <Text style={styles.tableCell3}></Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>First Name</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{element?.firstName}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Last Name</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{element?.lastName}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Date of Birth</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getFormatedDob(element?.dob)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Gender</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{element?.gender === 'male' ? 'Male' : 'Female'}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Nationality</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getCountry(element?.nationality)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Country of residence</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getCountry(element?.residentialCountry)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                        <View style={styles.tableRow8}>
                                                            <View style={styles.tableColLong3p19}>
                                                                <Text style={styles.tableCell3}>Relationship</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                            <View style={styles.tableColLong3p9}>
                                                                <Text style={styles.tableCell3}>{_getRelationship(element?.relationId)}</Text>
                                                            </View>
                                                            <View >
                                                                <Text > </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            )
                                        }
                                    })
                                }
                            </View>
                            {clientSignature ?
                                <Image
                                    style={styles.signatureSize}
                                    src={clientSignature} alt=" client sign" /> : ''}
                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {coApplicantSign ?
                                <Image
                                    style={styles.CoAppsignatureSize}
                                    src={coApplicantSign} alt=" client sign" /> : ''}
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </Page>
                    }

                </View>

                {/*Page 4*/}
                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#012169',
                        backgroundColor: '#012169',
                        height: 4,
                        marginLeft: 41,
                        marginRight: 40,
                        borderColor: '#012169'

                    }} />
                    <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 40, marginTop: 10, marginBottom: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Your Financial Details</Text >
                    <Text style={{
                        color: "black", fontSize: 9, marginLeft: 35,
                        padding: 5,

                        marginRight: 35, marginBottom: 20
                    }}>Creation, Consumption, Conservation of Monthly Income   <text style={{ float: 'right', marginLeft: '15' }}> as of 01/08/2023</text></Text>


                    <View style={styles.tableFinObjectivesPage4}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol3Green}>
                                <Text style={styles.tableCell3find}>Total Monthly Income</Text>
                                <Text style={styles.tableCell3Green}>{income}</Text>
                                {
                                    detailsObject?.customerIncomeSources?.map((element, index) => <Text key={index} style={styles.tableCell3p}>{element?.percentage + "% - " + _getIncomeSource(element?.incomeSourceId)}</Text>)
                                }
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableCol3Red}>
                                <Text style={styles.tableCell3find}>Consumption</Text>
                                <Text style={styles.tableCell3Red}>{expense}</Text>
                                {
                                    detailsObject?.customerExpenses?.map((element, index) => <Text key={index} style={styles.tableCell3p}>{element?.percentage + "% - " + _getExpenseSource(element?.expenseId)}</Text>)
                                }
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableCol3Yellow}>
                                <Text style={styles.tableCell3find}>Conservation</Text>
                                <Text style={styles.tableCell3Yellow}>{savings}</Text>
                                <Text style={styles.tableCell3p}>  {totalSaving} % - Savings & Investment</Text>

                            </View>
                        </View>
                    </View>
                    <Text style={{
                        color: "#005EB8", fontSize: 8, marginLeft: 40,
                        marginRight: 40, marginBottom: 8, marginTop: 20, fontFamily: 'Helvetica-Bold'
                    }}>Continuation of Income</Text>
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        marginLeft: 40,


                        marginRight: 40,
                        borderBottomWidth: 0.5,

                        borderBottomColor: '#005EB8',
                    }} />


                    <Text style={styles.text4}>How much would it impact you if your income stopped for 12 Months?                                                              {(fourCsDetails?.length > 0) ? _getLabel(fourCsDetails[0]?.value) : ''} </Text>
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        marginLeft: 40,

                        marginRight: 40,
                        height: 0.5,
                        borderBottomWidth: 0.5,

                        borderBottomColor: '#005EB8',

                    }} />
                    <Text style={styles.text4}>How much would it impact you if your income stopped for 12 - 24 Months?                                                       {(fourCsDetails?.length > 0) ? _getLabel(fourCsDetails[1]?.value) : ''}</Text>
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        height: 0.5,
                        borderBottomWidth: 0.5,

                        borderBottomColor: '#005EB8',
                        marginLeft: 40,

                        marginRight: 40
                    }} />
                    <Text style={styles.text4}>How much would it impact you if your income stopped permanently?                                                                {(fourCsDetails?.length > 0) ? _getLabel(fourCsDetails[2]?.value) : ''}</Text>
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        height: 0.5,
                        borderBottomWidth: 0.5,

                        borderBottomColor: '#005EB8',
                        marginLeft: 40,

                        marginRight: 40
                    }} />

                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>
                {/* page 5 */}
                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image style={styles.image4Logo} src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#091C65',
                        backgroundColor: '#091C65',
                        height: 4,
                        borderColor: '#091C65',
                        marginLeft: 40,
                        marginRight: 40
                    }} />
                    <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Your Financial Details</Text>
                    <View style={styles.tableFnDetail}>
                        <View style={styles.tableRowFnDetail}>
                            <View style={styles.GapTableCol}>
                                <View style={styles.tablefinobj}>
                                    <View style={styles.tableRow1199}>
                                        <View style={styles.budgettableCol}>
                                            <Text style={styles.tableCell133}>Dying Too Soon (Life Protection)</Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199}>Expected Living Expenses ({_getLifeStyle(fourRiskSummaryDetails?.dyingTooSoon?.lifeStyle)})</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991}>{selectedCurrency + " "}{isNaN((Math.round(fourRiskSummaryDetails?.dyingTooSoon?.expectedLivingExpense * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.dyingTooSoon?.expectedLivingExpense * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199}>+ Large future expenses</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991}></Text>
                                        </View>
                                    </View>
                                    {fourRiskSummaryDetails?.dyingTooSoon?.largeFutureExpenses?.map((element, index) => {
                                        return (
                                            <View style={styles.tableRow199} key={index}>
                                                <View style={styles.budgettableColContent}>
                                                    <Text style={styles.tableCell199}>{element?.targetYear + ": "} {element?.name === 'University' ? 'Education' : element?.name}    {getDependents.filter((x) => x.id == element.dependentId)[0]?.name} </Text>
                                                </View>
                                                <View style={styles.budgettableColContent1}>
                                                    <Text style={styles.tableCell1991}>{selectedCurrency + " "}{Math.round(element?.amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                </View>
                                            </View>)
                                    })}
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199Blue}>Life Protection Needs </Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991Blue}>{selectedCurrency + " "} {isNaN((Math.round(fourRiskSummaryDetails?.dyingTooSoon?.lifeProtectionNeeds * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.dyingTooSoon?.lifeProtectionNeeds * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.GapTableCol}>
                                <View style={styles.tablefinobj}>
                                    <View style={styles.tableRow1199}>
                                        <View style={styles.budgettableCol}>
                                            <Text style={styles.tableCell133}>Living too long (Retirement)</Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199}>Expected Living Expense Per Year in
                                                Retirement (compounded with inflation) </Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991}>{selectedCurrency + " "} {isNaN((Math.round(fourRiskSummaryDetails?.livingTooLong?.annualLivingExpenseAtAgeOfRetirement * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.livingTooLong?.annualLivingExpenseAtAgeOfRetirement * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199}>Number of years in Retirement (Life
                                                expectancy - age at retirement)</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991}>{fourRiskSummaryDetails?.livingTooLong?.noOfYearsInRetirement}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199Blue}>Retirement Needs</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991Blue}>{selectedCurrency + " "} {isNaN((Math.round(fourRiskSummaryDetails?.livingTooLong?.retirementNeeds * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.livingTooLong?.retirementNeeds * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableRowFnDetail}>
                            <View style={styles.GapTableCol}>
                                <View style={styles.tablefinobj}>
                                    <View style={styles.tableRow1199}>
                                        <View style={styles.budgettableCol}>
                                            <Text style={styles.tableCell133}>Disability Cover</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199}>Your Disability needs are matched to the
                                                calculated Life Protection Needs to ensure
                                                optimal coverage.</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991}></Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199Blue}>Disability Protection Needs</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991Blue}>{selectedCurrency + " "}{isNaN((Math.round(fourRiskSummaryDetails?.disability?.amount * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.disability?.amount * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.GapTableCol}>
                                <View style={styles.tablefinobj}>
                                    <View style={styles.tableRow1199}>
                                        <View style={styles.budgettableCol}>
                                            <Text style={styles.tableCell133}>Critical Illness Cover</Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199}>Expected lump sum medical cost </Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991}>{selectedCurrency + " "} {isNaN((Math.round(fourRiskSummaryDetails?.criticalIllness?.lumpSumMedicalCost * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.criticalIllness?.lumpSumMedicalCost * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199}>Expected Income replacement
                                                (100%, {gapAnalysis?.criticalIllness?.details?.lengthOfIncomeReplacement} Months)</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991}>{selectedCurrency + " "} {isNaN((Math.round(fourRiskSummaryDetails?.criticalIllness?.expectedLivingExpense * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.criticalIllness?.expectedLivingExpense * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow199}>
                                        <View style={styles.budgettableColContent}>
                                            <Text style={styles.tableCell199Blue}>Critical Illness Protection Needs</Text>
                                        </View>
                                        <View style={styles.budgettableColContent1}>
                                            <Text style={styles.tableCell1991Blue}>{selectedCurrency + " "} {isNaN((Math.round(fourRiskSummaryDetails?.criticalIllness?.criticalIllnessNeeds * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.criticalIllness?.criticalIllnessNeeds * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                    </View>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>


                </Page>
//Page 6

                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#012169',
                        backgroundColor: '#012169',
                        height: 4,
                        marginLeft: 40,
                        marginRight: 40,
                        borderColor: '#012169'

                    }} />

                    <View>
                        <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 40, marginTop: 5, marginBottom: 10, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Gap and Budgeting Summary</Text>
                    </View>
                    <View>
                        <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 40, marginRight: 40, marginBottom: 8, fontFamily: 'Helvetica-Bold' }}>Protection Gap</Text>
                    </View>

                    <View style={styles.table199}>
                        <View style={styles.protectionGaptableRow1}>
                            <View style={styles.benefittableCol1}>
                                <Text style={styles.benefitTableCell1}>Benefit</Text>
                            </View>
                            {(prevYearDetails?.headers !== undefined && checkKeysExist(previousYear)) &&
                                <View style={styles.protectionGaptableCol2}>
                                    <Text style={styles.benefitTableCell1}>Your Needs-{previousYear}</Text>
                                </View>}
                            {(prevYearDetails?.headers !== undefined && checkKeysExist(currentYear)) &&
                                <View style={styles.protectionGaptableCol2}>
                                    <Text style={styles.benefitTableCell1}>Your Needs-{currentYear}</Text>
                                </View>
                            }

                            <View style={styles.protectionGaptableCol3}>
                                <Text style={styles.benefitTableCell1}>Less Current Portfolio and Assets</Text>
                            </View>
                            <View style={styles.protectionGaptableCol4}>
                                <Text style={styles.benefitTableCell1}>Gap</Text>
                            </View>
                        </View>


                        {/* map starts for 4R*/
                            (prevYearDetails !== undefined && prevYearDetails?.data?.length > 0) &&
                            prevYearDetails?.data?.map((element, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <View style={styles.protectionGaptableRow1Content}>
                                            <View style={styles.protectionGaptableCol1Content}>
                                                <Text style={styles.protectionGaptableCell1Content}>{element?.name}</Text>
                                            </View>
                                            {(prevYearDetails?.headers !== undefined && previousYear in element) &&
                                                <View style={styles.protectionGaptableCol3Content}>
                                                    <Text style={styles.tableCell199}>{element[previousYear] ? (selectedCurrency + " ") + (element[previousYear])?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                                </View>
                                            }
                                            {(prevYearDetails?.headers !== undefined && currentYear in element) &&
                                                <View style={styles.protectionGaptableCol3Content}>
                                                    <Text style={styles.tableCell199}>{element[currentYear] ? (selectedCurrency + " ") + (element[currentYear])?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                                </View>}
                                            <View style={styles.protectionGaptableCol4Content}>
                                                <Text style={styles.tableCell199}>- {element?.currentPortfolioAndAssets ? (selectedCurrency + " ") + (element?.currentPortfolioAndAssets)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                            </View>
                                            <View style={styles.protectionGaptableCol5Content}>
                                                <Text style={styles.tableCell199}>{element?.gapAmount ? (selectedCurrency + " ") + (element?.gapAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                            </View>
                                        </View>
                                    </React.Fragment>)
                            })
                        }
                    </View>

                    <View>
                        <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 40, padding: 5, marginBottom: 2, marginRight: 40, marginTop: 10, fontFamily: 'Helvetica-Bold' }}>Financial Objectives Gap</Text>
                    </View>

                    <View style={styles.table199}>

                        <View style={styles.protectionGaptableRow1}>
                            <View style={styles.benefittableCol1}>
                                <Text style={styles.benefitTableCell1}>Financial Objective</Text>
                            </View>
                            {(prevYearDetails?.headers !== undefined && checkKeysExist(previousYear)) &&
                                <View style={styles.protectionGaptableCol2}>
                                    <Text style={styles.benefitTableCell1}>Your Needs-{previousYear}</Text>
                                </View>}
                            {(prevYearDetails?.headers !== undefined && checkKeysExist(currentYear)) &&
                                <View style={styles.protectionGaptableCol2}>
                                    <Text style={styles.benefitTableCell1}>Your Needs-{currentYear}</Text>
                                </View>
                            }

                            <View style={styles.protectionGaptableCol3}>
                                <Text style={styles.benefitTableCell1}>Less Current Portfolio and Assets</Text>
                            </View>
                            <View style={styles.protectionGaptableCol4}>
                                <Text style={styles.benefitTableCell1}>Gap</Text>
                            </View>
                        </View>

                        {
                            (financialObjectivesGap !== undefined && financialObjectivesGap?.data?.length > 0) &&

                            financialObjectivesGap?.data?.map((element, index) => {
                                return (
                                    <View style={styles.protectionGaptableRow1Content} key={index}>
                                        <View style={styles.protectionGaptableCol1Content}>
                                            <Text style={styles.tableCell199}>{element?.name}   {getDependents.filter((x) => x.id == element.dependentId)[0]?.name}</Text>
                                        </View>
                                        {(financialObjectivesGap?.headers !== undefined && previousYear in element) &&
                                            <View style={styles.protectionGaptableCol3Content}>
                                                <Text style={styles.tableCell199}>{element[previousYear] ? (selectedCurrency + " ") + (element[previousYear]).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                            </View>
                                        }
                                        {(financialObjectivesGap?.headers !== undefined && currentYear in element) &&
                                            <View style={styles.protectionGaptableCol3Content}>
                                                <Text style={styles.tableCell199}>{element[currentYear] ? (selectedCurrency + " ") + (element[currentYear]).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                            </View>}
                                        <View style={styles.protectionGaptableCol4Content}>
                                            <Text style={styles.tableCell199}>- {element?.currentPortfolioAndAssets ? (selectedCurrency + " " + (element?.currentPortfolioAndAssets).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (selectedCurrency + " 0")}</Text>
                                        </View>
                                        <View style={styles.protectionGaptableCol4Content}>
                                            <Text style={styles.tableCell199}>{element?.gapAmount ? (selectedCurrency + " " + (element?.gapAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (selectedCurrency + " 0")}</Text>
                                        </View>
                                    </View>
                                )
                            })
                        }
                        <View style={styles.protectionGaptableRow1Content} >
                            <View style={styles.protectionGaptableCol1Content}>
                                <Text style={styles.tableCell199}>Total</Text>
                            </View>
                            {(totalprevYear) &&
                                <View style={styles.protectionGaptableCol3Content}>
                                    <Text style={styles.tableCell199}>{totalprevYear ? (selectedCurrency + " ") + (totalprevYear).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                </View>
                            }
                            {(totalcurrentYear) &&
                                <View style={styles.protectionGaptableCol3Content}>
                                    <Text style={styles.tableCell199}>{totalcurrentYear ? (selectedCurrency + " ") + (totalcurrentYear).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (selectedCurrency + " 0")}</Text>
                                </View>}
                            <View style={styles.protectionGaptableCol4Content}>
                                <Text style={styles.tableCell199}>- {totalcurrentPortfolioAndAssets ? (selectedCurrency + " " + (totalcurrentPortfolioAndAssets).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (selectedCurrency + " 0")}</Text>
                            </View>
                            <View style={styles.protectionGaptableCol4Content}>
                                <Text style={styles.tableCell199}>{totalgapAmount ? (selectedCurrency + " " + (totalgapAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (selectedCurrency + " 0")}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.budgettable}>
                        <View style={styles.tableRow1199}>
                            <View style={styles.budgettableCol}>
                                <Text style={styles.tableCell133}>Budgeting and Contribution</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199}>Current Monthly Income</Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991}>AED {Math.round(budgetDetailsObject?.currentMonthlyIncome).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199}>Less Current Monthly Expenses </Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991}>- AED {Math.round(budgetDetailsObject?.currentMonthlyExpense).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199BoldFont}>Available Excess Income</Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991BoldFont}>AED {Math.round(budgetDetailsObject?.availableExcessIncome).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199}>Your selected % of contribution</Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991}>{budgetDetailsObject?.selectedPercentageContribution}%</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199BoldFont}>Your selected monthly budget </Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991BoldFont}>AED {Math.round(budgetDetailsObject?.selectedMonthlyBudget).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199BoldFont}>Your selected monthly budget
                                    (Planning Currency: {budgetDetailsObject?.planningCurrency})</Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991BoldFont}>{budgetDetailsObject?.planningCurrency} {Math.round(budgetDetailsObject?.selectedMonthlyBudgetInPlanningCurrency).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199BoldFont}>Your selected monthly budget(Selected Currency: {budgetDetailsObject?.selectedCurrency + " "}) </Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991BoldFont}>{budgetDetailsObject?.selectedCurrency + " "} {Math.round(budgetDetailsObject?.selectedMonthlyBudgetInSelectedCurrency).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow199}>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell199BoldFont}>Single contribution
                                    (Selected Currency: {budgetDetailsObject?.selectedCurrency + " "})</Text>
                            </View>
                            <View style={styles.budgettableColContent}>
                                <Text style={styles.tableCell1991BoldFont}>{budgetDetailsObject?.selectedCurrency + " "} {Math.round(budgetDetailsObject?.singleContribution).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                            </View>
                        </View>
                    </View>

                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>

                {
                    (showProtectionNeeds === true && productDescription?.protectionNeedsTableData?.length > 0) ?

                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image
                                style={styles.image4}
                                src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,
                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <View>
                                <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Your Protection Needs</Text>
                                <View style={styles.tablePeraonalLife}>
                                    <View style={styles.tableRow}>
                                        <View>
                                            <Text></Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColheader}>
                                            <Text style={styles.tableCellHeaderNeeds}>Protection Needs</Text>
                                        </View>
                                        <View>
                                            <Text> </Text>
                                        </View>
                                        <View style={styles.tableColheader}>
                                            <Text style={styles.tableCellHeaderNeeds}>Gap Recommendation</Text>
                                        </View>
                                        <View>
                                            <Text> </Text>
                                        </View>
                                        <View style={styles.tableCol3header}>
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableCellHeaderNeeds}>{productDescription?.protectionProduct?.name ? productDescription?.protectionProduct?.name : productDescription?.savingProduct?.name}</Text>
                                                {(productDescription?.protectionProduct?.isSelected === true) && <Image style={styles.tick} src={tickimage} alt="tickimage" />}
                                                <View></View>
                                            </View>
                                        </View>
                                    </View>
                                    {productDescription?.protectionNeedsTableData?.length > 0 &&
                                        productDescription?.protectionNeedsTableData?.map((element, index) => (
                                            <View style={styles.tableRow} key={index}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCellNeeds}>{element?.name}</Text>
                                                </View>
                                                <View>
                                                    <Text> </Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCellNeeds}>{element?.name === 'Waiver of Premium' ? 'As covered' : (typeof element?.gapBasedSumAssured === 'number' && element?.gapBasedSumAssured !== 0) ? element?.currency + " " + parseFloat(element?.gapBasedSumAssured).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'Not selected'}</Text>
                                                </View>
                                                <View>
                                                    <Text> </Text>
                                                </View>
                                                <View style={styles.table3Col}>
                                                    <Text style={styles.tableCellNeeds}>{element?.name === 'Waiver of Premium' ? 'As covered' : (typeof element?.currentSumAssured === 'number' && element?.currentSumAssured !== 0) ? element?.currency + " " + parseFloat(element?.currentSumAssured).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'Not selected'}</Text>
                                                </View>
                                            </View>
                                        ))
                                    }
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellBold}>Approximate Premium</Text>
                                        </View>
                                        <View>
                                            <Text> </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellBold}>
                                                Annually
                                            </Text>
                                        </View>
                                        <View>
                                            <Text> </Text>
                                        </View>
                                        <View style={styles.table3Col}>

                                            <Text style={styles.tableCellBold}>
                                                {gapCurrency + " "}{(Number(productDescription?.protectionProduct?.monthlyPremium) * 12).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            </Text>
                                        </View>
                                    </View>
                                    {(productDescription?.protectionProduct?.isSelected === true) &&
                                        <View style={styles.clientSelectedProduct}>
                                            <Text style={{ width: '75%' }}></Text>
                                            <Image
                                                style={styles.tick1}
                                                src={tickimage} alt="tickimage" />
                                            <Text>  Client Selected Product</Text>

                                        </View>
                                    }
                                </View>
                            </View>
                            <View style={{ marginLeft: 10, marginRight: 10 }}>
                                <Text style={styles.textpage7}> Advisor Notes for {productDescription?.protectionProduct?.name}</Text>
                                <Text style={styles.tableCellpage7}>{productDescription?.protectionProduct?.notes ? " " + productDescription?.protectionProduct?.notes : ''} </Text>
                            </View>
                            <View style={styles.footer}>
                                {clientSignature ? <Image
                                    style={styles.signatureSize}
                                    src={clientSignature} alt=" client sign" /> : ''}
                                <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                                <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                                {coApplicantSign ? <Image
                                    style={styles.CoAppsignatureSize}
                                    src={coApplicantSign} alt=" client sign" /> : ''}
                                {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                                <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                                </Text>
                            </View>

                        </Page>
                        : ''
                }

                {
                    (productDescription?.protectionProduct?.productAndBenefitDescription !== undefined && productDescription?.protectionProduct?.productAndBenefitDescription.length > 0) ?
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,
                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Product and Benefit Description</Text>
                            <View style={styles.tablePeraonalLife} >
                                <View style={styles.tableRow}>
                                    <View>
                                        <Text> </Text>
                                    </View>
                                </View>
                                {productDescription?.protectionProduct?.productAndBenefitDescription?.length > 0 && productDescription?.protectionProduct?.productAndBenefitDescription?.map((element, index) => {
                                    return (
                                        <View style={styles.tableRow8} key={index}>
                                            <View style={styles.tableColheader}>
                                                <Text style={styles.tableCellHeader8}>{element?.benefit}</Text>
                                            </View>
                                            <View style={styles.tableColLong}>
                                                <Text style={styles.tableCell8}>{element?.value} </Text>
                                            </View>
                                            <View >
                                                <Text > </Text>
                                            </View>
                                        </View>
                                    )
                                }
                                )}

                            </View>
                            {clientSignature ? <Image
                                style={styles.signatureSize}
                                src={clientSignature} alt=" client sign" /> : ''}

                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {coApplicantSign ? <Image
                                style={styles.CoAppsignatureSize}
                                src={coApplicantSign} alt=" client sign" /> : ''}
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>

                        </Page> : ''
                }

                {
                    GapDetailsFinancialObjectives?.length > 0 &&
                    <Page size="A4" style={styles.body}>{
                        GapDetailsFinancialObjectives?.map((element, index) => {
                            return (
                                <View key={index}>
                                    <View>
                                        <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                                        <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                                        <hr style={{
                                            color: '#1a2480',
                                            backgroundColor: '#1a2480',
                                            height: 4,
                                            marginLeft: 40,

                                            marginRight: 40,
                                            borderColor: '#1a2480'

                                        }} />
                                        <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Your Financial Objectives</Text>

                                        <View style={styles.tableFinObjectives} key={index}>

                                            <View style={styles.tableRow}>
                                                <View>
                                                    <Text> </Text>
                                                </View>
                                            </View>

                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColheaderpage9}>
                                                    <Text style={styles.tableCellHeader}>{element?.name}      {getDependents.filter((x) => x.id == element.dependentId)[0]?.name}</Text>
                                                </View>

                                                <View style={styles.tableColheaderpage9}>
                                                    <Text style={styles.tableCellHeader}> </Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>Description</Text>
                                                </View>

                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}> {_getDescription(element?.type, element?.description)}
                                                    </Text>
                                                </View>

                                            </View>
                                            {(element?.type === 'UNIVERSITY' || element?.type === 'PROPERTY') &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>{(element?.type === 'UNIVERSITY') ? 'Country of education' : 'Country'}</Text>
                                                    </View>

                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>{_getCountry(element?.countryCode)} </Text>
                                                    </View>
                                                </View>}
                                            {(element?.type === 'PROPERTY') &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>Price range</Text>
                                                    </View>

                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>{element?.aspiration}</Text>
                                                    </View>
                                                </View>}
                                            {(element?.type === 'UNIVERSITY') &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>No. of years education</Text>
                                                    </View>

                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>{element?.duration}</Text>
                                                    </View>
                                                </View>}
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>Goal Year</Text>
                                                </View>

                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>{element?.targetYear} </Text>
                                                </View>

                                            </View>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>Amount expected</Text>
                                                </View>

                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>{gapCurrency + " "}{element?.amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </Text>
                                                </View>

                                            </View>

                                            {element?.name === 'Retirement' &&
                                                <><View style={styles.tableRow}>
                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>Expected Living expenses per year in Retirement ({_getLifeStyle(fourRiskSummaryDetails?.livingTooLong?.lifeStyle)})</Text>
                                                    </View>

                                                    <View style={styles.tableColpage9}>
                                                        <Text style={styles.tableCellpage9}>{gapCurrency + " "} 45,165 </Text>
                                                    </View>

                                                </View>

                                                    <View style={styles.tableRow}>
                                                        <View style={styles.tableColpage9}>
                                                            <Text style={styles.tableCellpage9}>Retirement Need</Text>
                                                        </View>

                                                        <View style={styles.tableColpage9}>
                                                            <Text style={styles.tableCellpage9}>{gapCurrency} {isNaN((Math.round(fourRiskSummaryDetails?.livingTooLong?.retirementNeeds * 100) / 100)) ? 0 : (Math.round(fourRiskSummaryDetails?.livingTooLong?.retirementNeeds * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                        </View>

                                                    </View></>
                                            }
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>Less Current value of assets for goal</Text>
                                                </View>

                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>- {gapCurrency + " "}{element?.lessFutureValue ? element?.lessFutureValue?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Text>
                                                </View>

                                            </View>

                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>Gap</Text>
                                                </View>

                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>{gapCurrency + " "}{element?.gapAmount ? element?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Text>
                                                </View>

                                            </View>

                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>Importance</Text>
                                                </View>

                                                <View style={styles.tableColpage9}>
                                                    <Text style={styles.tableCellpage9}>
                                                        {_getPriorityImage(element?.priority)}
                                                    </Text>

                                                </View>

                                            </View>

                                        </View>
                                        <View style={{ marginBottom: _getFinObjSpace(element?.type, index) }}> </View>
                                    </View>

                                </View>
                            )
                        })
                    }
                        
                       
                        
                        {clientSignature &&
                        <Image
                            style={{...styles.signatureSize}}
                            src={clientSignature} alt=" client sign" fixed />}
                        <Text style={styles.sign4} fixed>{(isCoApplicant === true) ?  detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                        
                        <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                        {
                            coApplicantSign ? <Image
                                style={styles.CoAppsignatureSize}
                                src={coApplicantSign} alt=" client sign" fixed /> : ''
                        }
                        {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                        <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                        </Text>
                    </Page>
                }


                {/* page 9 - Retirement */}
                <Page size="A4" style={styles.body}>
                    <View>
                        <View style={{ marginBottom: '75%' }}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,

                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Your Financial Objectives</Text>

                            <View style={styles.table}>

                                <View style={styles.tableRow}>
                                    <View>
                                        <Text> </Text>
                                    </View>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableColheaderpage9}>
                                        <Text style={styles.tableCellHeader}>Retirement</Text>
                                    </View>

                                    <View style={styles.tableColheaderpage9}>
                                        <Text style={styles.tableCellHeader}> </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>Goal Year</Text>
                                    </View>

                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>{retirementDetails?.retirementYear} </Text>
                                    </View>

                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>Expected Living expenses per year in Retirement ({_getLifeStyle(fourRiskSummaryDetails?.livingTooLong?.lifeStyle)})</Text>
                                    </View>

                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>{gapCurrency + " "}{retirementDetails?.details?.annualLivingExpenseAtAgeOfRetirement?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </Text>
                                    </View>

                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>Retirement Need</Text>
                                    </View>

                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>{gapCurrency} {retirementDetails?.details?.retirementGoal?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                    </View>

                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>Less Current value of assets for goal</Text>
                                    </View>

                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>- {gapCurrency + " "}{retirementDetails?.details?.valueOfAssetsForGoal ? retirementDetails?.details?.valueOfAssetsForGoal?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Text>
                                    </View>

                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>Gap</Text>
                                    </View>

                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>{gapCurrency + " "}{retirementDetails?.details?.gapAmount ? retirementDetails?.details?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Text>
                                    </View>

                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>Importance</Text>
                                    </View>

                                    <View style={styles.tableColpage9}>
                                        <Text style={styles.tableCellpage9}>
                                            {_getPriorityImage(retirementDetails?.priority)}
                                        </Text>

                                    </View>

                                </View>

                            </View>
                        </View>
                    </View>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}

                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ? <Image
                        style={styles.CoAppsignatureSize}
                        src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>
                {/* page 10 */}

                {
                    (showEducationNeeds && productDescription?.educationNeeds.length > 0) &&
                    productDescription.educationNeeds.map((element) =>
                    (<ESPPlanningNeed element={element} benefit={espToDepBenefitMap.get(element.dependentId)} pageNo={pageno.current += 1}>
                        <Text style={styles.text15Sign}> Advisor notes for Education Savings Plan</Text>
                        <Text style={styles.tableCellpage10}>{_getESPNotes(element?.dependentName)} </Text>
                        <View style={styles.footer}>
                            {clientSignature ? <Image
                                style={styles.signatureSize}
                                src={clientSignature} alt=" client sign" /> : ''}
                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {coApplicantSign ? <Image
                                style={styles.CoAppsignatureSize}
                                src={coApplicantSign} alt=" client sign" /> : ''}
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </View>
                    </ESPPlanningNeed>)
                    )
                }
                {
                    (productDescription?.educationProduct?.productAndBenefitDescription !== undefined && productDescription?.educationProduct?.productAndBenefitDescription.length > 0) ?
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,
                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Product and Benefit Description</Text>
                            <View style={styles.tablePeraonalLife} >
                                <View style={styles.tableRow}>
                                    <View>
                                        <Text> </Text>
                                    </View>
                                </View>
                                {productDescription?.educationProduct?.productAndBenefitDescription?.length > 0 && productDescription?.educationProduct?.productAndBenefitDescription?.map((element, index) => {
                                    return (
                                        <View style={styles.tableRow8} key={index}>
                                            <View style={styles.tableColheader}>
                                                <Text style={styles.tableCellHeader8}>{element?.benefit}</Text>
                                            </View>
                                            <View style={styles.tableColLong}>
                                                <Text style={styles.tableCell8}>{element?.value} </Text>
                                            </View>
                                            <View >
                                                <Text > </Text>
                                            </View>
                                        </View>
                                    )
                                }
                                )}

                            </View>
                            {clientSignature ? <Image
                                style={styles.signatureSize}
                                src={clientSignature} alt=" client sign" /> : ''}

                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {coApplicantSign ? <Image
                                style={styles.CoAppsignatureSize}
                                src={coApplicantSign} alt=" client sign" /> : ''}
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>

                        </Page> : ''
                }
                {
                    (showPlanningNeeds) &&
                    (productDescription?.planningNeeds?.length > 0) &&
                    <Page style={styles.body}>

                        <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                        <Image
                            style={styles.image4}
                            src={logo} alt=" Zurich_Logo" />
                        <hr style={{
                            color: '#091C65',
                            backgroundColor: '#091C65',
                            height: 4,
                            marginLeft: 40,
                            marginRight: 40,
                            borderColor: '#091C65'

                        }} />
                        <Text style={{
                            color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5,

                            marginRight: 40, fontFamily: 'Helvetica-Bold'
                        }}>Your Planning Needs</Text >

                        <View style={styles.planningNeedsTable}>



                            <View style={styles.tableRow}>
                                <View>
                                    <Text> </Text>
                                </View>
                            </View>
                            {(productDescription?.planningNeeds?.length > 0) &&
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColheader10}>
                                        <Text style={styles.tableCellHeader10}>Planning need</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColheader10}>
                                        <Text style={styles.tableCellHeader10}>Gap (Recommendation) </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableCol3header10}>
                                        <View style={styles.tableRow}>


                                            <Text style={styles.tableCellHeader10}>Regular Savings Plan</Text>
                                            {(productDescription?.savingProduct?.isSelected === true) &&
                                                <Image
                                                    style={styles.tick}
                                                    src={selectedImage} alt="tickimage" />}
                                            <View ></View>
                                        </View>
                                    </View>

                                </View>
                            }
                            {productDescription?.planningNeeds?.map((element, index) => {
                                return (
                                    <View style={styles.tableRow} key={index}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellNeeds}>{element?.name}{element?.description ? " (" + element?.description + ")" : ''}</Text>
                                        </View>
                                        <View >
                                            <Text > </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCellNeeds}>{element?.gapBasedSumAssured ? element?.currency + " " + ((element?.gapBasedSumAssured).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (element?.currency + " 0")}</Text>
                                        </View>
                                        <View >
                                            <Text > </Text>
                                        </View>
                                        <View style={styles.table3Col}>
                                            <Text style={styles.tableCellNeeds}>{element?.currentSumAssured ? element?.currency + " " + ((element?.currentSumAssured).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (element?.currency + " 0")}</Text>
                                        </View>
                                    </View>
                                )
                            })
                            }
                            <View style={styles.planningNeedsTable}>
                                <View style={styles.tableRow}>
                                    <View>
                                        <Text> </Text>
                                    </View>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableColheader10b}>
                                        <Text style={styles.tableCellHeader10}>Benefit</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColheader10br}>
                                        <Text style={styles.tableCellHeader10}>                </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>

                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCellNeeds}>Waiver of Premium </Text>
                                </View>
                                <View >
                                    <Text > </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}></Text>
                                </View>
                                <View >
                                    <Text > </Text>
                                </View>
                                <View style={styles.table3Col}>
                                    <Text style={styles.tableCellNeeds}>Not selected </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCellBoldNeeds}>Approximate Premium</Text>
                                </View>
                                <View >
                                    <Text > </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCellBoldNeeds}>Annually</Text>
                                </View>
                                <View >
                                    <Text > </Text>
                                </View>
                                <View style={styles.table3Col}>
                                    <Text style={styles.tableCellBoldNeeds}>{gapCurrency} {(productDescription?.savingProduct?.monthlyPremium * 12).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                </View>
                            </View>
                            <View style={styles.clientSelectedProduct}>
                                <Text style={{ width: '75%', marginTop: '10px' }}></Text>
                                {(productDescription?.savingProduct?.isSelected === true) && <Image
                                    style={styles.tick1}
                                    src={tickimage} alt="tickimage" />}
                                <Text>  Client Selected Product</Text>

                            </View>
                        </View>

                        <Text style={styles.text15Sign}> Advisor notes for Regular Savings Plan</Text>
                        <Text style={styles.tableCellpage10}>{productDescription?.savingProduct?.notes ? " " + productDescription?.savingProduct?.notes : ''} </Text>
                        <View style={styles.footer}>
                            {clientSignature ? <Image
                                style={styles.signatureSize}
                                src={clientSignature} alt=" client sign" /> : ''}
                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {coApplicantSign ? <Image
                                style={styles.CoAppsignatureSize}
                                src={coApplicantSign} alt=" client sign" /> : ''}
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </View>

                    </Page>
                }

                {
                    (productDescription?.savingProduct?.productAndBenefitDescription.length > 0) ?
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,

                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Product  Description</Text>
                            <View style={styles.tablePeraonalLife} >



                                <View style={styles.tableRow}>
                                    <View>
                                        <Text> </Text>
                                    </View>
                                </View>


                                {productDescription?.savingProduct?.productAndBenefitDescription?.length > 0 && productDescription?.savingProduct?.productAndBenefitDescription?.map((element, index) => {
                                    return (
                                        <View style={styles.tableRow8} key={index}>
                                            <View style={styles.tableColheader}>
                                                <Text style={styles.tableCellHeader8}>{element?.benefit}</Text>
                                            </View>
                                            <View style={styles.tableColLong}>
                                                <Text style={styles.tableCell8}>{element?.value} </Text>
                                            </View>
                                            <View >
                                                <Text > </Text>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>
                            {
                                clientSignature ?
                                    <Image
                                        style={styles.signatureSize}
                                        src={clientSignature} alt=" client sign" /> : ''
                            }
                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {
                                coApplicantSign ?
                                    <Image
                                        style={styles.CoAppsignatureSize}
                                        src={coApplicantSign} alt=" client sign" /> : ''
                            }
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>

                        </Page> : ''
                }

                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        height: 4,
                        marginLeft: 40,
                        marginRight: 40,
                        borderColor: '#1a2480',
                    }} />
                    <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 37, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Risk Profile</Text>
                    <Text style={{ color: "black", fontSize: 8, margin: '5px 40px', fontFamily: 'Helvetica-Bold' }}>{detailsObject?.firstName + " " + detailsObject?.lastName}'s Answers {(details?.customerRiskProfile?.isCoApplicantRiskProfileUsed === false && isCoApplicant === true) ? <Image src={selectedRiskProfileIcon} alt='icon' style={styles.img33} /> : ''}</Text>
                    <View style={styles.riskProfileTable}>
                        <View style={styles.tableRow}>
                            <View>
                                <Text> </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow141}>
                            <View style={styles.tableColheaderRiskProfile14}>
                                <Text style={styles.tableCellHeader14}>Questions</Text>
                            </View>

                            <View style={styles.tableColheaderRiskProfile15}>
                                <Text style={styles.tableCellHeader14}>Answers</Text>
                            </View>



                        </View>

                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>How long do you plan to invest for?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>{details?.customerRiskProfile?.investmentYears + " years"}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>

                        </View>
                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>How would you describe your investment knowledge?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>
                                    {details?.customerRiskProfile?.investmentKnowledge === 'LOW' && "Expert: I have a great deal of experience of investments and have in-depth knowledge of how markets work."}
                                    {details?.customerRiskProfile?.investmentKnowledge === 'LOW_MEDIUM' && "High: I have experience with investing and understand the various different types of investment categories and risks."}
                                    {details?.customerRiskProfile?.investmentKnowledge === 'MEDIUM' && "Moderate: I have some experience in investing and I know the basic types of investments."}
                                    {details?.customerRiskProfile?.investmentKnowledge === 'MEDIUM_HIGH' && "Little: I have little knowledge about investments and risks and have very limited experience in investing."}
                                    {details?.customerRiskProfile?.investmentKnowledge === 'HIGH' && "None: I have no investment knowledge."}

                                </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>


                        </View>
                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>What is your investment objective?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>{details?.customerRiskProfile?.investmentObjective === 'LOW' && "I want to achieve the best possible return and I understand that this can come with the highest risk."}
                                    {details?.customerRiskProfile?.investmentObjective === 'LOW_MEDIUM' && "I would like high long term growth but will accept lower rate of growth with reduced fluctuations."}
                                    {details?.customerRiskProfile?.investmentObjective === 'MEDIUM' && "I place equal weight on growth and security."}
                                    {details?.customerRiskProfile?.investmentObjective === 'MEDIUM_HIGH' && "I aim for a return which beats inflation but do not want to be exposed to large fluctuations in value."}
                                    {details?.customerRiskProfile?.investmentObjective === 'HIGH' && "My aim is to protect the capital I have invested."}
                                </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>

                        </View>
                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>How comfortable would you be with a short term decline in your
                                    investment?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>{details?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'LOW' && "I would not be concerned."}
                                    {details?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'LOW_MEDIUM' && "I would only be slightly concerned."}
                                    {details?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'MEDIUM' && "I would be moderately concerned."}
                                    {details?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'MEDIUM_HIGH' && "I would be a little uncomfortable."}
                                    {details?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'HIGH' && "I would be very uncomfortable."}
                                </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                        </View>
                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>Below are a range of returns of an investment of $10,000 over a 5
                                    year period. Looking at the potential loss and gain, which one of
                                    these outcomes would you choose?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>
                                    {details?.customerRiskProfile?.rangeOfInvestmentIn5Years === 'LOW' && "between $6,300 and $17,200."}
                                    {details?.customerRiskProfile?.rangeOfInvestmentIn5Years === 'LOW_MEDIUM' && "between $6,800 and $16,300."}
                                    {details?.customerRiskProfile?.rangeOfInvestmentIn5Years === 'MEDIUM' && "between $7,900 and $14,000."}
                                    {details?.customerRiskProfile?.rangeOfInvestmentIn5Years === 'MEDIUM_HIGH' && "between $8,900 and $12,100."}
                                    {details?.customerRiskProfile?.rangeOfInvestmentIn5Years === 'HIGH' && "between $9,800 and $11,600."}

                                </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>

                        </View>

                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>What is your approach to taking risks?</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>
                                    {details?.customerRiskProfile?.riskApproach === 'LOW' && "be willing to take a lot more risk with all of your money."}
                                    {details?.customerRiskProfile?.riskApproach === 'LOW_MEDIUM' && "be willing to take a little more risk with all of your money."}
                                    {details?.customerRiskProfile?.riskApproach === 'MEDIUM_HIGH' && "consider the potential losses and only be willing to take a little more risk with some of your money."}
                                    {details?.customerRiskProfile?.riskApproach === 'HIGH' && "consider the potential losses and be unwilling to take any more risk."}

                                </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                        </View>
                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>Do you have other savings available to you, should you encounter
                                    a financial emergency:</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>
                                    {details?.customerRiskProfile?.otherSavingsInFinancialEmergency === 'LOW' && "No. I have no other savings I can use in the event of an emergency."}
                                    {details?.customerRiskProfile?.otherSavingsInFinancialEmergency === 'MEDIUM' && "I have some other savings, but nothing specifically for a financial emergency."}
                                    {details?.customerRiskProfile?.otherSavingsInFinancialEmergency === 'HIGH' && "Yes. I have specific savings that I can use in an emergency."}

                                </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                        </View>
                        <View style={styles.tableRow14}>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>If this investment were to perform poorly, what impact would this
                                    have on your financial well-being:</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong14}>
                                <Text style={styles.tableCell149}>
                                    {details?.customerRiskProfile?.impactOnPoorInvestment === 'LOW' && "It would have a big impact. This investment is required for a specific purpose."}
                                    {details?.customerRiskProfile?.impactOnPoorInvestment === 'MEDIUM' && "It would have some impact. I would need to make some adjustments to my future plans."}
                                    {details?.customerRiskProfile?.impactOnPoorInvestment === 'HIGH' && "It would have no impact, as I have other savings."}

                                </Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ marginLeft: 20, marginRight: 20 }}>
                        <Text style={{ color: "blue", fontSize: 8, margin: "15px 20px 10px 25px" }}>  Your Risk Profiler (based on your answers)</Text>
                        {details?.customerRiskProfile?.riskProfileIndex === 3 && <Image style={styles.img3} src={index3} alt=" 3" />}
                        {details?.customerRiskProfile?.riskProfileIndex === 4 && <Image style={styles.img3} src={index4} alt=" 3" />}
                        {details?.customerRiskProfile?.riskProfileIndex === 2 && <Image style={styles.img3} src={index2} alt=" 3" />}
                        {details?.customerRiskProfile?.riskProfileIndex === 1 && <Image style={styles.img3} src={index1} alt=" 3" />}
                        {details?.customerRiskProfile?.riskProfileIndex === 5 && <Image style={styles.img3} src={index5} alt=" 3" />}


                        <Text style={{ color: "black", fontSize: 8, marginTop: '10px', marginLeft: 25, marginBottom: 0 }}>
                            {details?.customerRiskProfile?.riskProfileIndex} -
                            {details?.customerRiskProfile?.riskProfileIndex === 3 && 'Balanced'}
                            {details?.customerRiskProfile?.riskProfileIndex === 4 && 'Performance Driven'}
                            {details?.customerRiskProfile?.riskProfileIndex === 2 && 'Cautious'}
                            {details?.customerRiskProfile?.riskProfileIndex === 1 && 'Defensive'}
                            {details?.customerRiskProfile?.riskProfileIndex === 5 && 'Adventurous'}

                        </Text>
                        {(details?.customerRiskProfile?.riskProfileIndex === 1)
                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                Your answers suggest that your risk appetite is low. This means that you will accept limited risks with your money, but will want to try to avoid large fluctuations in the value of your investment, accepting the potential of more modest returns to achieve this.
                                Funds with a Risk Profile of 1 may include a high proportion of bank deposits, fixed interest investments, such as government bonds and high quality corporate bonds (bonds issued by companies), and may include a small proportion invested in equities (company shares). Over the long term, high returns are unlikely, and the returns may not keep pace with inflation.
                            </Text>
                        }
                        {(details?.customerRiskProfile?.riskProfileIndex === 2)
                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                Your answers suggest that your risk appetite is Medium. This means that you are likely to accept some risk in return for the potential of higher investment gains over the longer term. You will want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the shorter term.
                                Funds with a Risk Profile of 2 are likely to be made up mainly of fixed interest investments such as government bonds and high quality corporate bonds (bonds issued by companies) and bank deposits. These funds are also likely to contain a significant proportion invested in equities (company shares).
                            </Text>
                        }
                        {(details?.customerRiskProfile?.riskProfileIndex === 3)
                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                Your answers suggest that your risk appetite is medium. This means that you are likely to accept significant risk in return for the potential for good investment gains over the longer term. You accept there will be significant fluctuations in the value of your investment, particularly over the shorter term. However, you will want to limit the amount of your money held in more risky investments.

                                Funds with a Risk Profile of 3 may invest in a mix of equities (company shares), and fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                            </Text>
                        }
                        {(details?.customerRiskProfile?.riskProfileIndex === 4)
                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                Your answers suggest that your risk appetite is Medium to high. This means that you understand that the value of your investment can go down and up sharply with the potential for greater returns over the longer term.
                                Funds with a Risk Profile of 4 may include a high proportion of equities (company shares), and a smaller proportion of fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                            </Text>
                        }
                        {(details?.customerRiskProfile?.riskProfileIndex === 5)
                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                Your answers suggest that your risk appetite is high. This means that you aim for high possible returns and accept higher levels of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term.
                                Funds with a Risk Profile of 5 are likely to invest in high risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.
                            </Text>
                        }

                        <Text style={{ color: "black", fontSize: 10, fontFamily: 'Helvetica-Bold', margin: '10px 25px 10px 25px' }}> Your Selected Risk Profile</Text>

                        {details?.customerRiskProfile?.isDifferentRiskLevel === true ? <>
                            <View>
                                <View style={styles.table14}>
                                    <View style={styles.tableRow14}>
                                        <View style={styles.tableColheader19}>
                                            <Text style={styles.tableCellHeader19}>I want to select different risk level from the results of the risk profile questionnaire</Text>
                                        </View>
                                        <View style={styles.tableColheader191}>
                                            <Text style={styles.tableCellHeader19}>Yes</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM' && <Image style={styles.img3} src={index3} alt=" 3" />}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH' && <Image style={styles.img3} src={index4} alt=" 3" />}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM' && <Image style={styles.img3} src={index2} alt=" 3" />}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'LOW' && <Image style={styles.img3} src={index1} alt=" 3" />}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'HIGH' && <Image style={styles.img3} src={index5} alt=" 3" />}
                                </View>
                                <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginRight: 20, marginBottom: 0 }}>
                                    {getRiskIndex(details?.customerRiskProfile?.choosenRiskAppetite)} -
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM' && 'Balanced'}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH' && 'Performance Driven'}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM' && 'Cautious'}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'LOW' && 'Defensive'}
                                    {details?.customerRiskProfile?.choosenRiskAppetite === 'HIGH' && 'Adventurous'}
                                </Text>


                                {(details?.customerRiskProfile?.choosenRiskAppetite === 'LOW')
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is low. This means that you will accept limited risks with your money, but will want to try to avoid large fluctuations in the value of your investment, accepting the potential of more modest returns to achieve this.
                                        Funds with a Risk Profile of 1 may include a high proportion of bank deposits, fixed interest investments, such as government bonds and high quality corporate bonds (bonds issued by companies), and may include a small proportion invested in equities (company shares). Over the long term, high returns are unlikely, and the returns may not keep pace with inflation.
                                    </Text>
                                }

                                {(details?.customerRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM')
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is Medium. This means that you are likely to accept some risk in return for the potential of higher investment gains over the longer term. You will want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the shorter term.
                                        Funds with a Risk Profile of 2 are likely to be made up mainly of fixed interest investments such as government bonds and high quality corporate bonds (bonds issued by companies) and bank deposits. These funds are also likely to contain a significant proportion invested in equities (company shares).
                                    </Text>
                                }
                                {(details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM')
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is medium. This means that you are likely to accept significant risk in return for the potential for good investment gains over the longer term. You accept there will be significant fluctuations in the value of your investment, particularly over the shorter term. However, you will want to limit the amount of your money held in more risky investments.

                                        Funds with a Risk Profile of 3 may invest in a mix of equities (company shares), and fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                                    </Text>
                                }
                                {(details?.customerRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH')
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is Medium to high. This means that you understand that the value of your investment can go down and up sharply with the potential for greater returns over the longer term.
                                        Funds with a Risk Profile of 4 may include a high proportion of equities (company shares), and a smaller proportion of fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                                    </Text>
                                }
                                {(details?.customerRiskProfile?.choosenRiskAppetite === 'HIGH')
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is high. This means that you aim for high possible returns and accept higher levels of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term.
                                        Funds with a Risk Profile of 5 are likely to invest in high risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.
                                    </Text>
                                }
                            </View>
                        </> : <>
                            <View style={styles.table14}>
                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColheader19}>
                                        <Text style={styles.tableCellHeader19}>I want to select different risk level from the results of the risk profile questionnaire</Text>
                                    </View>
                                    <View style={styles.tableColheader191}>
                                        <Text style={styles.tableCellHeader19}>No</Text>
                                    </View>
                                </View>
                            </View>
                        </>
                        }
                    </View>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}

                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>
                {/* coap applicant risk profile */}

                {
                    ((reportData?.customerSessionData?.coApplicant?.coApplicantRiskProfile !== undefined && reportData?.customerSessionData?.coApplicant?.coApplicantRiskProfile !== null)) ?

                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image style={styles.image4} src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,
                                marginRight: 40,
                                borderColor: '#1a2480',
                            }} />

                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 37, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Risk Profile</Text>
                            <Text style={{ color: "black", fontSize: 8, margin: '5px 40px', fontFamily: 'Helvetica-Bold' }}>{detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}'s Answers  {(details?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true) ? <Image src={selectedRiskProfileIcon} alt='icon' style={styles.img33} /> : ''}</Text>
                            <View style={styles.riskProfileTable}>
                                <View style={styles.tableRow}>
                                    <View>
                                        <Text> </Text>
                                    </View>
                                </View>

                                <View style={styles.tableRow141}>
                                    <View style={styles.tableColheaderRiskProfile14}>
                                        <Text style={styles.tableCellHeader14}>Questions</Text>
                                    </View>

                                    <View style={styles.tableColheaderRiskProfile15}>
                                        <Text style={styles.tableCellHeader14}>Answers</Text>
                                    </View>
                                </View>

                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>How long do you plan to invest for?</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>{details?.coApplicant?.coApplicantRiskProfile?.investmentYears + " years"}</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>

                                </View>
                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>How would you describe your investment knowledge?</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentKnowledge === 'LOW' && "Expert: I have a great deal of experience of investments and have in-depth knowledge of how markets work."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentKnowledge === 'LOW_MEDIUM' && "High: I have experience with investing and understand the various different types of investment categories and risks."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentKnowledge === 'MEDIUM' && "Moderate: I have some experience in investing and I know the basic types of investments."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentKnowledge === 'MEDIUM_HIGH' && "Little: I have little knowledge about investments and risks and have very limited experience in investing."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentKnowledge === 'HIGH' && "None: I have no investment knowledge."}

                                        </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>


                                </View>
                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>What is your investment objective?</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>{details?.coApplicant?.coApplicantRiskProfile?.investmentObjective === 'LOW' && "I want to achieve the best possible return and I understand that this can come with the highest risk."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentObjective === 'LOW_MEDIUM' && "I would like high long term growth but will accept lower rate of growth with reduced fluctuations."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentObjective === 'MEDIUM' && "I place equal weight on growth and security."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentObjective === 'MEDIUM_HIGH' && "I aim for a return which beats inflation but do not want to be exposed to large fluctuations in value."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.investmentObjective === 'HIGH' && "My aim is to protect the capital I have invested."}
                                        </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>

                                </View>
                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>How comfortable would you be with a short term decline in your
                                            investment?</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>{details?.coApplicant?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'LOW' && "I would not be concerned."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'LOW_MEDIUM' && "I would only be slightly concerned."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'MEDIUM' && "I would be moderately concerned."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'MEDIUM_HIGH' && "I would be a little uncomfortable."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment === 'HIGH' && "I would be very uncomfortable."}
                                        </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>Below are a range of returns of an investment of $10,000 over a 5
                                            year period. Looking at the potential loss and gain, which one of
                                            these outcomes would you choose?</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>
                                            {details?.coApplicant?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years === 'LOW' && "between $6,300 and $17,200."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years === 'LOW_MEDIUM' && "between $6,800 and $16,300."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years === 'MEDIUM' && "between $7,900 and $14,000."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years === 'MEDIUM_HIGH' && "between $8,900 and $12,100."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years === 'HIGH' && "between $9,800 and $11,600."}

                                        </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>

                                </View>

                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>What is your approach to taking risks?</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>
                                            {details?.coApplicant?.coApplicantRiskProfile?.riskApproach === 'LOW' && "be willing to take a lot more risk with all of your money."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.riskApproach === 'LOW_MEDIUM' && "be willing to take a little more risk with all of your money."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.riskApproach === 'MEDIUM_HIGH' && "consider the potential losses and only be willing to take a little more risk with some of your money."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.riskApproach === 'HIGH' && "consider the potential losses and be unwilling to take any more risk."}

                                        </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>Do you have other savings available to you, should you encounter
                                            a financial emergency:</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>
                                            {details?.coApplicant?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency === 'LOW' && "No. I have no other savings I can use in the event of an emergency."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency === 'MEDIUM' && "I have some other savings, but nothing specifically for a financial emergency."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency === 'HIGH' && "Yes. I have specific savings that I can use in an emergency."}

                                        </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow14}>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>If this investment were to perform poorly, what impact would this
                                            have on your financial well-being:</Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                    <View style={styles.tableColLong14}>
                                        <Text style={styles.tableCell149}>
                                            {details?.coApplicant?.coApplicantRiskProfile?.impactOnPoorInvestment === 'LOW' && "It would have a big impact. This investment is required for a specific purpose."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.impactOnPoorInvestment === 'MEDIUM' && "It would have some impact. I would need to make some adjustments to my future plans."}
                                            {details?.coApplicant?.coApplicantRiskProfile?.impactOnPoorInvestment === 'HIGH' && "It would have no impact, as I have other savings."}

                                        </Text>
                                    </View>
                                    <View >
                                        <Text > </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginLeft: 20, marginRight: 20 }}>
                                <Text style={{ color: "blue", fontSize: 8, margin: "15px 20px 10px 25px" }}>  Your Risk Profiler (based on your answers)</Text>
                                {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 3 && <Image style={styles.img3} src={index3} alt=" 3" />}
                                {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 4 && <Image style={styles.img3} src={index4} alt=" 3" />}
                                {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 2 && <Image style={styles.img3} src={index2} alt=" 3" />}
                                {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 1 && <Image style={styles.img3} src={index1} alt=" 3" />}
                                {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 5 && <Image style={styles.img3} src={index5} alt=" 3" />}


                                <Text style={{ color: "black", fontSize: 8, marginTop: '10px', marginLeft: 25, marginBottom: 0 }}>
                                    {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex} -
                                    {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 3 && 'Balanced'}
                                    {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 4 && 'Performance Driven'}
                                    {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 2 && 'Cautious'}
                                    {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 1 && 'Defensive'}
                                    {details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 5 && 'Adventurous'}

                                </Text>
                                {(details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 1)
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is low. This means that you will accept limited risks with your money, but will want to try to avoid large fluctuations in the value of your investment, accepting the potential of more modest returns to achieve this.
                                        Funds with a Risk Profile of 1 may include a high proportion of bank deposits, fixed interest investments, such as government bonds and high quality corporate bonds (bonds issued by companies), and may include a small proportion invested in equities (company shares). Over the long term, high returns are unlikely, and the returns may not keep pace with inflation.
                                    </Text>
                                }

                                {(details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 2)
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is Medium. This means that you are likely to accept some risk in return for the potential of higher investment gains over the longer term. You will want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the shorter term.
                                        Funds with a Risk Profile of 2 are likely to be made up mainly of fixed interest investments such as government bonds and high quality corporate bonds (bonds issued by companies) and bank deposits. These funds are also likely to contain a significant proportion invested in equities (company shares).
                                    </Text>
                                }
                                {(details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 3)
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is medium. This means that you are likely to accept significant risk in return for the potential for good investment gains over the longer term. You accept there will be significant fluctuations in the value of your investment, particularly over the shorter term. However, you will want to limit the amount of your money held in more risky investments.

                                        Funds with a Risk Profile of 3 may invest in a mix of equities (company shares), and fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                                    </Text>
                                }
                                {(details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 4)
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is Medium to high. This means that you understand that the value of your investment can go down and up sharply with the potential for greater returns over the longer term.
                                        Funds with a Risk Profile of 4 may include a high proportion of equities (company shares), and a smaller proportion of fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                                    </Text>
                                }
                                {(details?.coApplicant?.coApplicantRiskProfile?.riskProfileIndex === 5)
                                    && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                        Your answers suggest that your risk appetite is high. This means that you aim for high possible returns and accept higher levels of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term.
                                        Funds with a Risk Profile of 5 are likely to invest in high risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.
                                    </Text>
                                }


                                <Text style={{ color: "black", fontFamily: 'Helvetica-Bold', fontSize: 10, margin: '10px 25px 10px 25px' }}> Your Selected Risk Profile</Text>

                                {details?.coApplicant?.coApplicantRiskProfile?.isDifferentRiskLevel === true ? <>
                                    <View>
                                        <View style={styles.table14}>
                                            <View style={styles.tableRow14}>
                                                <View style={styles.tableColheader19}>
                                                    <Text style={styles.tableCellHeader19}>I want to select different risk level from the results of the risk profile questionnaire</Text>
                                                </View>
                                                <View style={styles.tableColheader191}>
                                                    <Text style={styles.tableCellHeader19}>Yes</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ marginTop: '10px', marginBottom: '10px' }}>
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM' && <Image style={styles.img3} src={index3} alt=" 3" />}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH' && <Image style={styles.img3} src={index4} alt=" 3" />}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM' && <Image style={styles.img3} src={index2} alt=" 3" />}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW' && <Image style={styles.img3} src={index1} alt=" 3" />}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'HIGH' && <Image style={styles.img3} src={index5} alt=" 3" />}
                                        </View>
                                        <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginRight: 20, marginBottom: 0 }}>
                                            {getRiskIndex(details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite)} -
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM' && 'Balanced'}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH' && 'Performance Driven'}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM' && 'Cautious'}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW' && 'Defensive'}
                                            {details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'HIGH' && 'Adventurous'}
                                        </Text>
                                        {(details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW')
                                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                                Your answers suggest that your risk appetite is low. This means that you will accept limited risks with your money, but will want to try to avoid large fluctuations in the value of your investment, accepting the potential of more modest returns to achieve this.
                                                Funds with a Risk Profile of 1 may include a high proportion of bank deposits, fixed interest investments, such as government bonds and high quality corporate bonds (bonds issued by companies), and may include a small proportion invested in equities (company shares). Over the long term, high returns are unlikely, and the returns may not keep pace with inflation.
                                            </Text>
                                        }

                                        {(details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'LOW_MEDIUM')
                                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                                Your answers suggest that your risk appetite is Medium. This means that you are likely to accept some risk in return for the potential of higher investment gains over the longer term. You will want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the shorter term.
                                                Funds with a Risk Profile of 2 are likely to be made up mainly of fixed interest investments such as government bonds and high quality corporate bonds (bonds issued by companies) and bank deposits. These funds are also likely to contain a significant proportion invested in equities (company shares).
                                            </Text>
                                        }
                                        {(details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM')
                                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                                Your answers suggest that your risk appetite is medium. This means that you are likely to accept significant risk in return for the potential for good investment gains over the longer term. You accept there will be significant fluctuations in the value of your investment, particularly over the shorter term. However, you will want to limit the amount of your money held in more risky investments.

                                                Funds with a Risk Profile of 3 may invest in a mix of equities (company shares), and fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                                            </Text>
                                        }
                                        {(details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'MEDIUM_HIGH')
                                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                                Your answers suggest that your risk appetite is Medium to high. This means that you understand that the value of your investment can go down and up sharply with the potential for greater returns over the longer term.
                                                Funds with a Risk Profile of 4 may include a high proportion of equities (company shares), and a smaller proportion of fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                                            </Text>
                                        }
                                        {(details?.coApplicant?.coApplicantRiskProfile?.choosenRiskAppetite === 'HIGH')
                                            && <Text style={{ color: "black", fontSize: 8, marginTop: 0, marginLeft: 25, marginBottom: 0, marginRight: 20 }}>
                                                Your answers suggest that your risk appetite is high. This means that you aim for high possible returns and accept higher levels of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term.
                                                Funds with a Risk Profile of 5 are likely to invest in high risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.
                                            </Text>
                                        }
                                    </View>
                                </> : <>
                                    <View style={styles.table14}>
                                        <View style={styles.tableRow14}>
                                            <View style={styles.tableColheader19}>
                                                <Text style={styles.tableCellHeader19}>I want to select different risk level from the results of the risk profile questionnaire</Text>
                                            </View>
                                            <View style={styles.tableColheader191}>
                                                <Text style={styles.tableCellHeader19}>No</Text>
                                            </View>
                                        </View>
                                    </View>
                                </>
                                }
                            </View>
                            {clientSignature ?
                                <Image
                                    style={styles.signatureSize}
                                    src={clientSignature} alt=" client sign" /> : ''}

                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {coApplicantSign ?
                                <Image
                                    style={styles.CoAppsignatureSize}
                                    src={coApplicantSign} alt=" client sign" /> : ''}
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </Page> : ''
                }
                {
                    (isCoApplicant === false) ?
                        '' : ''
                }

                {/* Choice Of Products */}

                <Page size="A4" style={styles.body}>
                    {productsArray?.length > 0 &&
                        productsArray?.map((element, index) => {
                            if (element?.fullName === 'Regular Saving Plan') {
                                return <RSPProduct  flagToShowSAP={flagToShowSAP} element={element} detailsObject={detailsObject} isCoApplicant={isCoApplicant} pageNo={pageno.current += 1} getFundAllocationImageSL={isCoApplicant ? _getFundAllocationImageCoAppRSP : _getFundAllocationImageSLRSP}>
                                    <FooterSignature
                                        clientSignature={clientSignature}
                                        coApplicantSign={coApplicantSign}
                                        detailsObject={detailsObject}
                                        isCoApplicant={isCoApplicant}
                                        advisorName={advisorName}
                                        latestDate={latestDate}
                                    ></FooterSignature>
                                </RSPProduct>
                            } else if (element?.fullName === 'Futura') {
                                return <FuturaProduct
                                    flagToShowSAP={flagToShowSAP}
                                    element={element}
                                    detailsObject={detailsObject}
                                    isCoApplicant={isCoApplicant}
                                    pageNo={isCoApplicant ? pageno.current += 2 : pageno.current += 1}
                                    getFundAllocationImageSL={isCoApplicant ? _getFundAllocationImageCoApp : _getFundAllocationImageSL}
                                    shouldDisplay={_shouldDisplay}
                                >
                                    <FooterSignature
                                        clientSignature={clientSignature}
                                        coApplicantSign={coApplicantSign}
                                        detailsObject={detailsObject}
                                        isCoApplicant={isCoApplicant}
                                        advisorName={advisorName}
                                        latestDate={latestDate}
                                    ></FooterSignature>
                                </FuturaProduct>
                            } else if (element?.fullName === 'Education Saving Plan') {
                                return <ESPProduct
                                    element={element}
                                    detailsObject={detailsObject}
                                    isCoApplicant={isCoApplicant}
                                    pageNo={pageno.current += 1}
                                    getFundAllocationImageSL={isCoApplicant ? _getFundAllocationImageCoApp : _getFundAllocationImageSL}
                                    shouldDisplay={_shouldDisplay}
                                >
                                    <FooterSignature
                                        clientSignature={clientSignature}
                                        coApplicantSign={coApplicantSign}
                                        detailsObject={detailsObject}
                                        isCoApplicant={isCoApplicant}
                                        advisorName={advisorName}
                                        latestDate={latestDate}
                                    ></FooterSignature>
                                </ESPProduct>
                            } else {
                                return <ITAProduct
                                    element={element}
                                    detailsObject={detailsObject}
                                    isCoApplicant={isCoApplicant}
                                    pageNo={pageno.current += 1}
                                    getFundAllocationImageSL={_getFundAllocationImageSL}
                                    shouldDisplay={_shouldDisplay}
                                >
                                    <FooterSignature
                                        clientSignature={clientSignature}
                                        coApplicantSign={coApplicantSign}
                                        detailsObject={detailsObject}
                                        isCoApplicant={isCoApplicant}
                                        advisorName={advisorName}
                                        latestDate={latestDate}
                                    ></FooterSignature>
                                </ITAProduct>
                            }
                        })
                    }
                </Page>
                {/* End Choice of Products */}
    //Below Page is for RSP Benefit Illustration
                {
                    (JSON.stringify(illustrationDetails) !== '{}' && illustrationDetails !== undefined) ?
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image
                                style={styles.image4}
                                src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,

                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Withdrawal Schedule for Regular Savings Plan</Text >

                            <Text style={styles.text4RSP}>The table below illustrates what you would get back from your investment if it grew at the rate of {_getGrowthRate()}% a year.</Text>
                            <View style={styles.table1}>
                                <View style={styles.tableRow}>
                                    <View><Text> </Text></View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>End of policy year</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Total premiums paid to date</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Fund value</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Surrender value</Text>
                                    </View>
                                </View>
                                {Object.keys(illustrationDetails).map((key, value) => {
                                    return (
                                        <>
                                            {(key <= 24) &&
                                                < View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{illustrationDetails[key]['At the end of year']}</Text>
                                                    </View>

                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Premiums paid'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Fund Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Encashment Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                    )
                                })
                                }
                                <View>


                                </View>
                            </View>
                            {
                                clientSignature ?
                                    <Image
                                        style={styles.signatureSize}
                                        src={clientSignature} alt=" client sign" /> : ''
                            }

                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {
                                coApplicantSign ?
                                    <Image
                                        style={styles.CoAppsignatureSize}
                                        src={coApplicantSign} alt=" client sign" /> : ''
                            }
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </Page> : ''
                }
                {
                    (JSON.stringify(illustrationDetails) !== '{}' && illustrationDetails !== undefined) ?
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image
                                style={styles.image4}
                                src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,

                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <View style={styles.table1}>
                                <View style={styles.tableRow}>
                                    <View><Text> </Text></View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>End of policy year</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Total premiums paid to date</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Fund value</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Surrender value</Text>
                                    </View>
                                </View>
                                {Object.keys(illustrationDetails).map((key, value) => {
                                    return (
                                        <>
                                            {(key >= 25 && key <= 54) &&
                                                < View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{illustrationDetails[key]['At the end of year']}</Text>
                                                    </View>

                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Premiums paid'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Fund Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Encashment Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                    )
                                })
                                }
                                <View>


                                </View>
                            </View>
                            {
                                clientSignature ?
                                    <Image
                                        style={styles.signatureSize}
                                        src={clientSignature} alt=" client sign" /> : ''
                            }

                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {
                                coApplicantSign ?
                                    <Image
                                        style={styles.CoAppsignatureSize}
                                        src={coApplicantSign} alt=" client sign" /> : ''
                            }
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </Page> : ''
                }
                {
                    (JSON.stringify(illustrationDetails) !== '{}' && illustrationDetails !== undefined) ?
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image
                                style={styles.image4}
                                src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,

                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <View style={styles.table1}>
                                <View style={styles.tableRow}>
                                    <View><Text> </Text></View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>End of policy year</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Total premiums paid to date</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Fund value</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Surrender value</Text>
                                    </View>
                                </View>
                                {Object.keys(illustrationDetails).map((key, value) => {
                                    return (
                                        <>
                                            {(key >= 55 && key <= 78) &&
                                                < View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{illustrationDetails[key]['At the end of year']}</Text>
                                                    </View>

                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Premiums paid'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Fund Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.ceil(illustrationDetails[key]['Encashment Value'])?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                    )
                                })
                                }
                                <View>


                                </View>
                            </View>
                            {
                                clientSignature ?
                                    <Image
                                        style={styles.signatureSize}
                                        src={clientSignature} alt=" client sign" /> : ''
                            }

                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {
                                coApplicantSign ?
                                    <Image
                                        style={styles.CoAppsignatureSize}
                                        src={coApplicantSign} alt=" client sign" /> : ''
                            }
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </Page> : ''
                }
                {
                    (JSON.stringify(illustrationDetails) !== '{}' && illustrationDetails !== undefined) ?
                        <Page style={styles.body}>
                            <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                            <Image
                                style={styles.image4}
                                src={logo} alt=" Zurich_Logo" />
                            <hr style={{
                                color: '#1a2480',
                                backgroundColor: '#1a2480',
                                height: 4,
                                marginLeft: 40,

                                marginRight: 40,
                                borderColor: '#1a2480'

                            }} />
                            <View style={styles.table1}>
                                <View style={styles.tableRow}>
                                    <View><Text> </Text></View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>End of policy year</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Total premiums paid to date</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Fund value</Text>
                                    </View>
                                    <View style={styles.tableColheader}>
                                        <Text style={styles.tableCellHeader}>Surrender value</Text>
                                    </View>
                                </View>
                                {Object.keys(illustrationDetails).map((key, value) => {
                                    return (
                                        <>
                                            {(key >= 79 && key <= 92) &&
                                                < View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{illustrationDetails[key]['At the end of year']}</Text>
                                                    </View>

                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.round(illustrationDetails[key]['Premiums paid'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.round(illustrationDetails[key]['Fund Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell14RSP}>{Math.round(illustrationDetails[key]['Encashment Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                    )
                                })
                                }
                                <View>
                                    <Text style={{ padding: '5px', fontSize: '8px' }} fixed>The above table is only an estimate and is not intended to substitute the formal illustration document that will be provided to yourself once the product
                                        choice is made. Please take note that such estimates are not a reliable guide to future performance of an investment. Investing in this product attracts
                                        various risks where you may end up receiving less than what you have invested. Your final return will be based on the underlying fund performance in
                                        addition to a number of other factors such as early surrender penalties, ongoing product charges and exchange rates.
                                    </Text>

                                </View>
                            </View>
                            {
                                clientSignature ?
                                    <Image
                                        style={styles.signatureSize}
                                        src={clientSignature} alt=" client sign" /> : ''
                            }

                            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                            {
                                coApplicantSign ?
                                    <Image
                                        style={styles.CoAppsignatureSize}
                                        src={coApplicantSign} alt=" client sign" /> : ''
                            }
                            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                            </Text>
                        </Page> : ''
                }
    //Changes for RSP DONE
                //ESp table changes
                {
                    espillustrationDetails?.length > 0 &&
                    espillustrationDetails?.map(element => {
                        return (JSON.stringify(espillustrationDetails) !== '{}' && espillustrationDetails !== undefined) ?
                            <Page style={styles.body}>
                                <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                                <Image
                                    style={styles.image4}
                                    src={logo} alt=" Zurich_Logo" />
                                <hr style={{
                                    color: '#1a2480',
                                    backgroundColor: '#1a2480',
                                    height: 4,
                                    marginLeft: 40,

                                    marginRight: 40,
                                    borderColor: '#1a2480'

                                }} />
                                <Text style={{ color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Withdrawal Schedule for Education Savings Plan - {(element !== undefined || element !== null) ? element['dependentName'] : ''}</Text >

                                <Text style={styles.text4RSP}>The table below illustrates what you would get back from your investment if it grew at the rate of {_getGrowthRate()}% a year.</Text>
                                <View style={styles.table1}>
                                    <View style={styles.tableRow}>
                                        <View><Text> </Text></View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColheader}>
                                            <Text style={styles.tableCellHeader}>End of policy year</Text>
                                        </View>
                                        <View style={styles.tableColheader}>
                                            <Text style={styles.tableCellHeader}>Total premiums paid to date</Text>
                                        </View>
                                        <View style={styles.tableColheader}>
                                            <Text style={styles.tableCellHeader}>Fund value</Text>
                                        </View>
                                        <View style={styles.tableColheader}>
                                            <Text style={styles.tableCellHeader}>Surrender value</Text>
                                        </View>
                                    </View>
                                    {Object.keys(element).map((key, value) => {
                                        return (
                                            <>
                                                {(key <= 24) &&
                                                    < View style={styles.tableRow}>
                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell14RSP}>{element[key]['At the end of year']}</Text>
                                                        </View>

                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell14RSP}>{Math.round(element[key]['Premiums paid'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                        </View>
                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell14RSP}>{Math.round(element[key]['Fund Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                        </View>
                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell14RSP}>{Math.round(element[key]['Encashment Value'])?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                                                        </View>
                                                    </View>
                                                }
                                            </>
                                        )
                                    })
                                    }
                                    <View>
                                        <Text style={{ padding: '5px', fontSize: '8px' }} fixed>The above table is only an estimate and is not intended to substitute the formal illustration document that will be provided to yourself once the product
                                            choice is made. Please take note that such estimates are not a reliable guide to future performance of an investment. Investing in this product attracts
                                            various risks where you may end up receiving less than what you have invested. Your final return will be based on the underlying fund performance in
                                            addition to a number of other factors such as early surrender penalties, ongoing product charges and exchange rates.
                                        </Text>

                                    </View>
                                </View>
                                {
                                    clientSignature ?
                                        <Image
                                            style={styles.signatureSize}
                                            src={clientSignature} alt=" client sign" /> : ''
                                }

                                <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                                <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                                {
                                    coApplicantSign ?
                                        <Image
                                            style={styles.CoAppsignatureSize}
                                            src={coApplicantSign} alt=" client sign" /> : ''
                                }
                                {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                                <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                                </Text>
                            </Page> : ''
                    })
                }


    //end esp table changes
                //Page 15
                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#012169',
                        backgroundColor: '#012169',
                        height: 4,
                        marginLeft: 41,

                        marginRight: 40,
                        borderColor: '#012169'

                    }} />
                    <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 41, padding: 5, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Declaration & Sign off  </Text >
                    <Text style={styles.text4declaration}>Client's declaration</Text>
                    <Text style={styles.text4declarationsub} fixed>I acknowledge that the information provided in the financial fact find report is complete, true and accurate to the best of my/our knowledge (except
                        where I have indicated that I have chosen not to provide this information).
                    </Text>
                    <Text style={styles.text4declarationsub} fixed>I understand and accept that my personal data will be processed and held in line with the Privacy Notice as per Zurich International's Privacy Policy. For
                        further information, please click on the following link <Link src="https://www.zurichinternational.com/im/legal/privacy" style={{ fontSize: '9', fontFamily: 'Helvetica-Bold', color: '#005EB8' }} alt="link">https://www.zurichinternational.com/im/legal/privacy</Link>
                    </Text>
                    <Text style={styles.text4declarationsub} fixed>I understand and acknowledge that by either not fully or accurately completing the financial fact find, any recommendation or advice given by the
                        financial professional in these circumstances may be inappropriate to my needs and that I risk making a financial commitment to a policy which may be
                        inappropriate for my needs. I further confirm that all discussions have been conducted in the language that I am familiar with and that no undue
                        influence was exerted on me
                    </Text>
                    <Text style={styles.text4declarationsub} fixed>I have read and understood the financial fact find report and the applicable documents details the product(s) that I am applying for:
                    </Text>
                    <Text style={styles.text4declarationsubNew} fixed>1. Key Features Document
                    </Text>
                    <Text style={styles.text4declarationsubNew} fixed>2. Fund details

                    </Text>
                    <Text style={styles.text4declarationsub} fixed>I declare and agree that I fully understand and accept the following relating to my application(s) for this insurance policy:
                    </Text>
                    <Text style={styles.text4declarationsubNew} fixed>• any loss that I may suffer as a result of early surrender of my policy(s);

                    </Text>
                    <Text style={styles.text4declarationsubNew} fixed>• any cash withdrawal; premium reduction; and any permissible premium suspension/premium holiday entitlement;
                    </Text>
                    <Text style={styles.text4declarationsubNew} fixed>• I have read the notice on page 2 of this application and understand that investment returns are not guaranteed;

                    </Text>
                    <Text style={styles.text4declarationsubNew} fixed>• potential loss associated with any market value adjustment;
                    </Text>
                    <Text style={styles.text4declarationsubNew} fixed>• the potential risks as disclosed in the risk disclosure statements, returns, and losses associated with my investment(s).

                    </Text>
                    <Text style={styles.text4declarationsub} fixed>I acknowledge I should not purchase this policy and/or the selected mix of underlying investment choices (if applicable) unless I understand these and
                        their suitability has been explained to me and that the final decision is mine.

                    </Text>
                    <Text style={styles.text4declarationsub} fixed>I understand that Zurich or any Zurich Insurance Group companies will retain copy(ies) of the completed financial fact find and risk profile questionnaire
                        for record in line with the regulatory and legal requirements but will have no responsibility for reviewing/assessing whether a particular insurance
                        product and any underlying investment choices are suitable for me in light of my personal circumstances.
                    </Text>
                    <View style={{ display: 'table' }}>
                        <View style={styles.tableRowDeclaration}>
                            <Text style={styles.tableColheader15declarationcheckbox} fixed></Text><Text style={{ fontSize: '9', fontFamily: 'Helvetica-Bold', marginTop: 5 }} fixed>I confirm that I have read and understood the terms and conditions above. </Text>
                        </View>
                    </View>
                    <View style={styles.table} >



                        <View style={styles.tableRow}>
                            <View>
                                <Text> </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow8declaration}>
                            <View style={styles.tableColheader15}>
                                <Text style={styles.tableCellHeader15}>{detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName}
                                </Text>
                            </View>
                            <View>
                                {clientSignature ? <Image
                                    style={styles.declarationsignatureSize}
                                    src={clientSignature} alt=" client sign" /> : ''}
                            </View>
                            <View style={styles.tableColLong161}>

                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.text151} fixed>Client's Name                                                                                              Client's Signature

                    </Text>
                    <View>
                        {
                            (isCoApplicant === true) ?
                                <View style={styles.table}>
                                    <View style={styles.tableRow8declaration}>
                                        <View style={styles.tableColheader15}>
                                            <Text style={styles.tableCellHeader15}>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}
                                            </Text>
                                        </View>
                                        <View>
                                            {coApplicantSign ?
                                                <Image
                                                    style={styles.declarationsignatureSize}
                                                    src={coApplicantSign} alt=" client sign" /> : ''}
                                        </View>
                                        <View style={styles.tableColLong161}>

                                        </View>
                                        <View >
                                            <Text > </Text>
                                        </View>
                                    </View>
                                </View> : ''
                        }
                        {
                            (isCoApplicant === true) ?
                                <Text style={styles.text151} fixed>Client's Name                                                                                              Client's Signature
                                </Text> : ''
                        }
                    </View>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>


                </Page>

                {/* page 16 */}
                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#091C65',
                        backgroundColor: '#091C65',
                        height: 4,
                        marginLeft: 40,

                        marginRight: 40,
                        borderColor: '#091C65'

                    }} />
                    <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 41, marginTop: 5, marginBottom: 10, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Declaration & Sign off  </Text >

                    <Text style={styles.text4declaration}>Advisor's declaration</Text>
                    <Text style={styles.text4declarationsub1} fixed>The recommendation generated is based on the information that the client has disclosed to me.
                    </Text>
                    <Text style={styles.text4declarationsub} fixed>Additional Notes

                    </Text>
                    <Text style={styles.tableCellpage15}>{notesDetails}  </Text>
                    <View style={{ display: 'table' }}>
                        <View style={styles.tableRowDeclaration}>
                            <Text style={styles.tableColheader15declarationcheckbox} fixed></Text><Text style={{ fontSize: '8', marginTop: 5 }} fixed>The recommendation generated is based on the information that the client has disclosed to me. </Text>
                        </View>
                    </View>

                    <View style={styles.table} >



                        <View style={styles.tableRow}>
                            <View>
                                <Text> </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow8declarationSign}>
                            <View style={styles.tableColheader15}>
                                <Text style={styles.tableCellHeader15}>{advisorName}
                                </Text>
                            </View>
                            <View >
                                {advisorSign ?
                                    <Image
                                        style={styles.advisorSign}
                                        src={advisorSign} alt=" advisor sign" /> : ''}
                                <Text > </Text>
                            </View>
                            <View style={styles.tableColLong161}>

                            </View>

                        </View>
                    </View>
                    <Text style={styles.text151} fixed>Advisor's Name                                                                                              Advisor's Signature

                    </Text>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>

                </Page>

                {/* page 17 */}

                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        height: 4,
                        marginLeft: 40,

                        marginRight: 40,
                        borderColor: '#1a2480'

                    }} />
                    <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 41, marginTop: 5, marginBottom: 10, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Privacy Notice </Text >

                    <Text style={styles.text4declarationsub} fixed>The personal information collected by the adviser is required in order to assess client objectives and financial priorities and provide appropriate
                        recommendations. Personal information is held by Zurich International Life Limted as Data Controller in line with the Privacy Notice. Full details can be
                        found online at <Link src="https://www.zurichinternational.com/im/legal/privacy" style={{ fontSize: '9', fontFamily: 'Helvetica-Bold', color: '#005EB8' }} alt="link">https://www.zurichinternational.com/im/legal/privacy</Link>

                    </Text>
                    <Text style={styles.text4declarationsub} fixed>I have received a copy of the illustration document and understand that any non-guaranteed elements included in the document are subject to change
                        and could be either higher or lower. The broker or bank has informed me that they are not guaranteed. Further, I confirm that the broker or bank has not
                        made any verbal or written communication, electronic file or any other material that is different from this illustration document.

                    </Text>
                    <Text style={styles.text4declarationsub} fixed>
                    </Text>
                    <Text style={styles.text4declarationsub12} fixed>Signature of applicant                                                                                     Signature of applicant 2 (if applicable)
                    </Text>
                    {clientSignature ? <Image
                        style={styles.mainApplicantSign}
                        src={clientSignature} alt=" client sign" /> : ''}

                    <View style={styles.table} >
                        <View style={styles.tableRow}>
                            <View>
                                <Text></Text>
                            </View>
                            <View>
                                {coApplicantSign ?
                                    <Image
                                        style={styles.mainApplicantSign1}
                                        src={coApplicantSign} alt=" client sign" /> : ''}
                            </View>
                        </View>

                        <View style={styles.tableRow8t1}>

                            <View style={styles.tableColLong16}>
                            </View>
                            <View >
                                <Text ></Text>
                            </View>
                            <View style={styles.tableColLong16}>

                            </View>
                            <View >
                                <Text >

                                </Text>
                            </View>
                        </View>
                    </View>
                    {/* <Text style={styles.text15s} ></Text> */}
                    <Text style={styles.text51} fixed>Day/Month/Year                                                                                               Day/Month/Year  (if applicable)

                    </Text>

                    <View style={styles.table} >


                        <View style={styles.tableRow8t1}>

                            <View style={styles.tableColLong16day}>
                                <Text style={styles.text511} fixed>{clientSignature ? _getFormatedDob(mainAppSignDate) : ''}</Text>
                            </View>
                            <View >


                            </View>
                            <View style={styles.tableColLong16day2}>
                                <Text style={styles.text511} fixed>{coApplicantSign ? _getFormatedDob(coAppSignDate) : ''}</Text>
                            </View>
                            <View >
                                <Text > </Text>
                            </View>
                        </View>
                    </View>
                    <Text style={{
                        fontSize: 9, marginLeft: 40,
                        marginTop: 20,
                        textcolor: "black",
                    }}>The following section to be completed by the relevant financial professional</Text>


                    <Text style={styles.text5s} fixed>I certify that this document has been presented to the applicant and that I have explained non-guaranteed elements illustrated are subject to change.
                        Further, I confirm that I have disclosed all charges and Fund Management Charges to the client. I have made no statements in any form that are
                        inconsistent with this illustration document.

                    </Text>
                    <Text style={styles.text5s} ></Text>
                    <Text style={styles.text5} fixed>Signature of financial professional                                                                   Day/Month/Year (if applicable)

                    </Text>

                    <View style={styles.table} >

                        <View style={styles.tableRow8t1}>
                            <View style={styles.tableColLong16}>
                                {advisorSign ?
                                    <Image
                                        style={styles.advisorSign}
                                        src={advisorSign} alt=" advisor sign" /> : ''}
                            </View>
                            <View >

                            </View>
                            <View style={styles.tableColLong162}>
                                <Text style={styles.text5113} fixed>{advisorSign ? _getFormatedDob(advisorSignDate) : ''}</Text>
                            </View>
                            <View >
                            </View>
                        </View>
                    </View>
                    <Text style={{
                        fontSize: 9, marginLeft: 40,
                        marginTop: 5,
                        textcolor: "black",
                    }}>Agency Name                  Sales Team 10 ZAN-Direct
                    </Text>
                    <Text style={{
                        fontSize: 9, marginLeft: 40,
                        marginTop: 5,
                        textcolor: "black",
                    }}>Financial Professional      {advisorName}
                    </Text>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ? <Image
                        style={styles.CoAppsignatureSize}
                        src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>


                </Page>
                {/* page 18 */}

                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        height: 4,
                        marginLeft: 40,

                        marginRight: 40,
                        borderColor: '#091C65'

                    }} />


                    <Text style={styles.text4declarationsub} fixed>Zurich International Life Limited is registered (Registration Number 63) under UAE Federal Law Number 6 of 2007, and its activities in the UAE are
                        governed by such law.</Text>
                    <Text style={styles.text4declarationsub} fixed>Zurich International Life is a business name of Zurich International Life Limited which provides life assurance, investment and protection products and is
                        authorised by the Isle of Man Financial Services Authority.</Text>
                    <Text style={styles.text4declarationsub} fixed>Registered in the Isle of Man number 20126C.
                    </Text>
                    <Text style={styles.text4declarationsub} fixed>Registered office: Zurich House, Isle of Man Business Park, Douglas, Isle of Man, IM2 2QZ, British Isles. Telephone +44 1624 662266 Telefax +44 1624
                        662038</Text>
                    <Text style={styles.text4declarationsub} fixed>
                        <Link src="https://www.zurichinternational.com" style={{ fontSize: '9', fontFamily: 'Helvetica-Bold', color: '#005EB8' }} alt="link">www.zurichinternational.com</Link></Text>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>
                {/* page 19 */}

                <Page style={styles.body}>
                    <Text style={styles.pageNumber}>{pageno.current += 1}</Text>
                    <Image
                        style={styles.image4}
                        src={logo} alt=" Zurich_Logo" />
                    <hr style={{
                        color: '#1a2480',
                        backgroundColor: '#1a2480',
                        height: 4,
                        marginLeft: 40,

                        marginRight: 40,
                        borderColor: '#1a2480'

                    }} />
                    <Text style={{ color: "#005EB8", fontSize: 9, marginLeft: 40, marginTop: 5, marginBottom: 10, marginRight: 40, fontFamily: 'Helvetica-Bold' }}>Data Sources
                    </Text >

                    <Text style={styles.text15s} fixed>Our approach includes the usage of average costs, inflation rates and other economic and financial information which we have gathered from reliable
                        sources that could be viewed above. This information is based on available historic and currents data and is used for indicative purposes only. In general,
                        past and current financial information are given for indicative purposes only and should not be taken as guaranteed forecast/profits for the future.
                    </Text>
                    <Text style={styles.text4declarationsub} fixed>To learn more about the sources of our research used in your recommendation, please follow the link below
                    </Text>

                    <Text style={styles.text4declarationsub} fixed>
                        <Link src="https://www.zurich.ae/media/2567/factfind-data-sources-1120.pdf" style={{ fontSize: '9', fontFamily: 'Helvetica-Bold', color: '#005EB8' }} alt="link">zurich/datasource/november2020</Link>
                    </Text>
                    {clientSignature ?
                        <Image
                            style={styles.signatureSize}
                            src={clientSignature} alt=" client sign" /> : ''}
                    <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
                    <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
                    {coApplicantSign ?
                        <Image
                            style={styles.CoAppsignatureSize}
                            src={coApplicantSign} alt=" client sign" /> : ''}
                    {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
                    <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
                    </Text>
                </Page>
            </Document >
        </>
    );
}

const ESPPlanningNeed = ({ element, benefit, pageNo, isSelected, children }) => {
    return <>
        <Page style={styles.body}>
            <HeaderLogoAndPageNo pageNo={pageNo} />
            <Text style={{
                color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5,

                marginRight: 40, fontFamily: 'Helvetica-Bold'
            }}>Your Planning Needs</Text >

            <View style={styles.planningNeedsTable}>
                <View style={styles.tableRow}>
                    <View>
                        <Text> </Text>
                    </View>
                </View>
                {/* Table Header */}
                <View style={styles.tableRow}>
                    <View style={styles.tableColheader10}>
                        <Text style={styles.tableCellHeader10}>Planning need</Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.tableColheader10}>
                        <Text style={styles.tableCellHeader10}>Gap (Recommendation) </Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.tableCol3header10}>
                        <View style={styles.tableRow}>


                            <Text style={styles.tableCellHeader10}>Education Savings Plan</Text>
                            {element?.isSelected &&
                                <Image
                                    style={styles.tick}
                                    src={selectedImage} alt="tickimage" />}
                            <View ></View>
                        </View>
                    </View>

                </View>
                {/* End Table Header */}

                {/* Table Body */}
                <View style={styles.tableRow} key={0}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellNeeds}>{element?.name}  {element?.dependentName} </Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellNeeds}>{element?.gapBasedSumAssured ? element?.currency + " " + ((element?.gapBasedSumAssured).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (element?.currency + " 0")}</Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.table3Col}>
                        <Text style={styles.tableCellNeeds}>{element?.currentSumAssured ? element?.currency + " " + ((element?.currentSumAssured).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : (element?.currency + " 0")}</Text>
                    </View>
                </View>
                {/* End Table Body */}
                <View style={styles.planningNeedsTable}>
                    <View style={styles.tableRow}>
                        <View>
                            <Text> </Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableColheader10b}>
                            <Text style={styles.tableCellHeader10}>Benefit</Text>
                        </View>
                        <View >
                            <Text > </Text>
                        </View>
                        <View style={styles.tableColheader10br}>
                            <Text style={styles.tableCellHeader10}> </Text>
                        </View>
                        <View >
                            <Text > </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellNeeds}>Future of Premium </Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}></Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.table3Col}>
                        <Text style={styles.tableCellNeeds}>{benefit?.isSelected ? 'Covered' : 'Not selected'} </Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellBoldNeeds}>Approximate Premium</Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellBoldNeeds}>Annually</Text>
                    </View>
                    <View >
                        <Text > </Text>
                    </View>
                    <View style={styles.table3Col}>
                        <Text style={styles.tableCellBoldNeeds}>{element?.currency} {(element?.premium).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                    </View>
                </View>
                <View style={styles.clientSelectedProduct}>
                    <Text style={{ width: '75%', marginTop: '10px' }}></Text>
                    {element?.isSelected &&
                        <Image
                            style={styles.tick1}
                            src={tickimage} alt="tickimage" />}
                    <Text>  Client Selected Product</Text>

                </View>
            </View>
            {children}

        </Page>
    </>
}

const ITAProduct = ({ element, detailsObject, isCoApplicant, pageNo, getFundAllocationImageSL, shouldDisplay, children }) => {
    return <>
        <View style={{ height: '100%' }}>
            <View>
                <HeaderLogoAndPageNo pageNo={pageNo} />

                <Text style={{
                    color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
                }}>Your Choice of Products </Text >

                <Text style={{
                    color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
                }}>{element?.fullName} {element?.dependentName}</Text>
                <View>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol19}>
                                <Text style={styles.tableCell}> <Text style={styles.text4}>Policy Owner</Text></Text>
                            </View>
                            <View style={styles.tableCol9}>
                                <Text style={styles.tableCell}>
                                    <Text style={styles.text4}>{detailsObject?.firstName + " " + detailsObject.lastName}</Text>
                                </Text>
                                {(detailsObject?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true) &&
                                    <Text style={styles.tableCell}>
                                        <Text style={styles.text4}>{detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                                    </Text>
                                }
                            </View>
                            <View style={styles.tableCol19}>
                                <Text style={styles.tableCell}><Text style={styles.text4}>Life assured</Text></Text>
                            </View>

                            <View style={styles.tableCol9}>
                                <Text style={styles.tableCell}> <Text style={styles.text4}>{element?.fullName === "Education Saving Plan" ? element?.dependentName : detailsObject.firstName + " " + detailsObject.lastName}</Text></Text>
                                {(isCoApplicant === true) &&
                                    <Text style={styles.tableCell}> <Text style={styles.text4}>{detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                                    </Text>}
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol19}>
                                <Text style={styles.tableCell}> <Text style={styles.text4}>Ownership Type</Text></Text>
                            </View>
                            <View style={styles.tableCol9}>
                                <Text style={styles.tableCell}>
                                    <Text style={styles.text4}>{(element.ownershipType === 'JL') ? 'Joint' : 'Single'}</Text>
                                </Text>
                            </View>
                            <View style={styles.tableCol19}>
                                <Text style={styles.tableCell}><Text style={styles.text4}>Joint Life Type</Text></Text>
                            </View>
                            <View style={styles.tableCol9}>
                                <Text style={styles.tableCell}> <Text style={styles.text4}>{(element.jointLifeType === 'JLFD') ? 'Joint Life First Death' : 'Single Life'}</Text></Text>

                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol19}>
                                <Text style={styles.tableCellchoice}> <Text style={styles.text4}>Policy Term</Text></Text>
                            </View>

                            <View style={styles.tableCol9}>
                                <Text style={styles.tableCellchoice}>
                                    <Text style={styles.text4}>{element?.policyTerm === 'Whole of Life' ? element.policyTerm : element?.policyTerm.toString().concat(" ").concat("years")}</Text>
                                </Text>
                            </View>

                            <View style={styles.tableCol19}>
                                <Text style={styles.tableCell}><Text style={styles.text4}>{(element?.name === 'Futura') ? 'Vanishing premium' : ''}</Text></Text>
                            </View>
                            <View style={styles.tableCol9}>
                                <Text style={styles.tableCell}> <Text style={styles.text4}>{(element?.name === 'Futura') ? 'Yes' : ''}</Text></Text>

                            </View>
                        </View>
                    </View>
                </View>

                <hr style={{
                    color: '#1a2480',
                    backgroundColor: '#1a2480',
                    height: .3,
                    marginLeft: 40,

                    marginRight: 40,
                    borderColor: '#1a2480'

                }} />
                {(shouldDisplay(element?.benefits) === true) &&

                    <Text style={styles.text4}>Benefits list for Life assured {detailsObject.firstName + " " + detailsObject.lastName}
                    </Text>
                }
                <View style={{ marginBottom: (element?.fullName === 'Futura') ? '10' : '28%' }}>
                    {(shouldDisplay(element?.benefits) === true) ?

                        <View style={styles.tablePeraonalLife}>
                            <View style={styles.tableRow}>

                                <View >
                                </View>

                                <View ></View>

                            </View>



                            <View style={styles.tableRow}>

                                <View>

                                    <Text> </Text>

                                </View>

                            </View>
                            <BenefitTableHeaderOfProtProduct />
                            {element?.benefits?.map((detail, key) => {
                                if (detail?.isSelected === true) {
                                    return (
                                        <React.Fragment key={key}>
                                            <View style={styles.tableRow}>

                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell14}>{detail?.name} </Text>
                                                </View>

                                                <View >

                                                    <Text > </Text>

                                                </View>

                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell141}>{detail?.currentSumAssured ? Number(detail?.currentSumAssured)?.toFixed(0)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'As covered'}  </Text>
                                                </View>

                                                <View >

                                                    <Text> </Text>

                                                </View>

                                                <View style={styles.table3Col}>
                                                    <Text style={styles.tableCell141}>{detail?.gapBasedSumAssured ? Number(detail?.gapBasedSumAssured)?.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'Not selected'}  </Text>
                                                </View>

                                            </View>
                                        </React.Fragment>
                                    )
                                }
                            })}
                        </View> : <View style={styles.noTable}>ESP</View>
                    }

                    <Text style={{ marginBottom: '10%' }}></Text>

                </View>

                {/* co applicant */}


            </View>
            {children}
        </View>
    </>
}

const BenefitTableHeaderOfProtProduct = () => {
    return <>
        <View style={styles.tableRow}>
            <View style={styles.tableColheader}>
                <Text style={styles.tableCellHeader199}>Benefit </Text>
            </View>
            <View >
                <Text > </Text>
            </View>
            <View style={styles.tableColheader}>
                <Text style={styles.tableCellHeader199}>Recommended Sum Assured </Text>
            </View>
            <View >
                <Text > </Text>
            </View>
            <View style={styles.tableCol3header}>
                <Text style={styles.tableCellHeader199}>Your Choice of Sum Assured</Text>
            </View>
        </View>
    </>
}

const FuturaProductInsured = ({ detailsObject, element, isCoApplicant }) => {
    return <>
        <Text style={{
            color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
        }}>{element?.fullName} {element?.dependentName}</Text>
        <View>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>Policy Owner</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}>
                            <Text style={styles.text4}>{detailsObject?.firstName + " " + detailsObject.lastName}</Text>
                        </Text>
                        {(detailsObject?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true) &&
                            <Text style={styles.tableCell}>
                                <Text style={styles.text4}>{detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                            </Text>
                        }
                    </View>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}><Text style={styles.text4}>Life assured</Text></Text>
                    </View>

                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>{element?.fullName === "Education Saving Plan" ? element?.dependentName : detailsObject.firstName + " " + detailsObject.lastName}</Text></Text>
                        {(isCoApplicant === true) &&
                            <Text style={styles.tableCell}> <Text style={styles.text4}>{detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                            </Text>}
                    </View>
                </View>

                <View style={styles.tableRow}>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>Ownership Type</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}>
                            <Text style={styles.text4}>{(element.ownershipType === 'JL') ? 'Joint' : 'Single'}</Text>
                        </Text>
                    </View>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}><Text style={styles.text4}>Joint Life Type</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>{(element.jointLifeType === 'JLFD') ? 'Joint Life First Death' : 'Single Life'}</Text></Text>

                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCellchoice}> <Text style={styles.text4}>Policy Term</Text></Text>
                    </View>

                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCellchoice}>
                            <Text style={styles.text4}>{element?.policyTerm === 'Whole of Life' ? element.policyTerm : element?.policyTerm.toString().concat(" ").concat("years")}</Text>
                        </Text>
                    </View>

                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}><Text style={styles.text4}>{(element?.name === 'Futura') ? 'Vanishing premium' : ''}</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>{(element?.name === 'Futura') ? 'Yes' : ''}</Text></Text>

                    </View>
                </View>
            </View>
        </View>

        <hr style={{
            color: '#1a2480',
            backgroundColor: '#1a2480',
            height: .3,
            marginLeft: 40,

            marginRight: 40,
            borderColor: '#1a2480'

        }} />
    </>
}
const FuturaProduct = ({ flagToShowSAP, element, detailsObject, isCoApplicant, pageNo, getFundAllocationImageSL, shouldDisplay, children }) => {

    return <>
        <View style={{ height: '100%' }}>
            <View>
                <HeaderLogoAndPageNo pageNo={isCoApplicant ? pageNo - 1 : pageNo} />

                <Text style={{
                    color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
                }}>Your Choice of Products </Text >

                <FuturaProductInsured
                    isCoApplicant={isCoApplicant}
                    detailsObject={detailsObject}
                    element={element}
                />
                {(shouldDisplay(element?.benefits) === true) &&

                    <Text style={styles.text4}>Benefits list for Life assured {detailsObject.firstName + " " + detailsObject.lastName}
                    </Text>
                }
                <View style={{ marginBottom: (element?.fullName === 'Futura') ? '10' : '28%', marginTop: '10' }}>
                    {(shouldDisplay(element?.benefits) === true) ?
                        <View style={styles.tablePeraonalLife}>
                            <BenefitTableHeaderOfProtProduct />
                            {element?.benefits?.map((detail, key) => {
                                if (detail?.isSelected === true) {
                                    return (
                                        <React.Fragment key={key}>
                                            <View style={styles.tableRow}>

                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell14}>{detail?.name} </Text>
                                                </View>

                                                <View >

                                                    <Text > </Text>

                                                </View>

                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell141}>{detail?.currentSumAssured ? Number(detail?.currentSumAssured)?.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'As covered'}  </Text>
                                                </View>

                                                <View >

                                                    <Text> </Text>

                                                </View>

                                                <View style={styles.table3Col}>
                                                    <Text style={styles.tableCell141}>{detail?.gapBasedSumAssured ? Number(detail?.gapBasedSumAssured)?.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'Not selected'}  </Text>
                                                </View>

                                            </View>
                                        </React.Fragment>
                                    )
                                }
                            })}
                        </View> : <View style={styles.noTable}>ESP</View>
                    }
                </View>
                {!isCoApplicant &&
                    <View style={{ display: (element?.name === 'ITA' || element?.name === 'Education Saving Plan') ? 'none' : 'block' }}>
                        {(element?.name !== 'ITA') &&
                            <Text style={styles.text4114}>Fund Allocation for Life assured 1 {" " + detailsObject.firstName + " " + detailsObject.lastName}
                            </Text>
                        }
                        {
                            getFundAllocationImageSL(flagToShowSAP)
                        }
                        {(element?.name !== 'ITA') &&
                            <Text style={{ textAlign: 'right', fontSize: '8', marginRight: '40px' }}> *Funds selected based on your risk profile</Text>

                        }
                    </View>
                }

                {isCoApplicant &&
                    <View>
                        {(shouldDisplay(element?.benefits) === true) &&

                            <Text style={styles.text4}>Benefits list for Life assured {detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}
                            </Text>
                        }
                        <View style={{ marginBottom: (element?.fullName === 'Futura') ? '10' : '28%', marginTop: '10' }}>
                            {(shouldDisplay(element?.benefits) === true) ?
                                <View style={styles.tablePeraonalLife}>
                                    <BenefitTableHeaderOfProtProduct />
                                    {element?.benefits?.map((detail, key) => {
                                        if (detail?.isSelected === true) {
                                            return (
                                                <React.Fragment key={key}>
                                                    <View style={styles.tableRow}>

                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell14}>{detail?.name} </Text>
                                                        </View>

                                                        <View >

                                                            <Text > </Text>

                                                        </View>

                                                        <View style={styles.tableCol}>
                                                            <Text style={styles.tableCell141}>{detail?.currentSumAssured ? Number(detail?.currentSumAssured)?.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'As covered'}  </Text>
                                                        </View>

                                                        <View >

                                                            <Text> </Text>

                                                        </View>

                                                        <View style={styles.table3Col}>
                                                            <Text style={styles.tableCell141}>{detail?.gapBasedSumAssured ? Number(detail?.gapBasedSumAssured)?.toFixed(0).toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'Not selected'}  </Text>
                                                        </View>

                                                    </View>
                                                </React.Fragment>
                                            )
                                        }
                                    })}
                                </View> : <View style={styles.noTable}>ESP</View>
                            }
                        </View>
                    </View>}

            </View>
            {children}
        </View>
        {isCoApplicant &&
            <View style={{ height: '100%' }}>
                <HeaderLogoAndPageNo pageNo={pageNo} />
                <Text style={{
                    color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
                }}>Your Choice of Products </Text >
                <FuturaProductInsured
                    isCoApplicant={isCoApplicant}
                    detailsObject={detailsObject}
                    element={element}
                />
                <View>
                    {(element?.name !== 'ITA') &&
                        <Text style={styles.text4114}>Fund Allocation for Life assured 1 {" " + detailsObject.firstName + " " + detailsObject.lastName}
                        </Text>
                    }
                    {
                        getFundAllocationImageSL(flagToShowSAP)
                    }
                    {(element?.name !== 'ITA') &&
                        <Text style={{ textAlign: 'right', fontSize: '8', marginRight: '40px' }}> *Funds selected based on your risk profile</Text>

                    }
                </View>
                <View>
                    <Text style={styles.text4114}>Fund Allocation for Life assured 2 {" " + detailsObject.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}
                    </Text>
                    {getFundAllocationImageSL(flagToShowSAP)}
                    <Text style={{ textAlign: 'right', fontSize: '8', marginRight: '40px' }}> *Funds selected based on your risk profile</Text>
                </View>
                {children}
            </View>
        }
    </>
}

const ESPProduct = ({ element, detailsObject, isCoApplicant, pageNo, getFundAllocationImageSL, shouldDisplay, children }) => {
    return <>
        <View style={{ height: '100%' }}>
            <View>
                <HeaderLogoAndPageNo pageNo={pageNo}></HeaderLogoAndPageNo>
                <Text style={{
                    color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
                }}>Your Choice of Products </Text >

                <ProductInsuredDetails element={element} detailsObject={detailsObject} isCoApplicant={isCoApplicant}></ProductInsuredDetails>
                <View style={{ marginBottom: (element?.fullName === 'Futura') ? '10' : '28%' }}>
                    <View>
                        <Text style={styles.text4114}>Fund Allocation for Life assured 1 {element?.fullName === "Education Saving Plan" ? element?.dependentName : detailsObject.firstName + " " + detailsObject.lastName}
                        </Text>

                        {
                            getFundAllocationImageSL(true)
                        }
                        <Text style={{ textAlign: 'right', fontSize: '8', marginRight: '40px' }}> *Funds selected based on your risk profile</Text>
                    </View>

                    {/* co applicant section */}
                    {isCoApplicant &&
                        <View>
                            <Text style={styles.text4114}>Fund Allocation for Life assured 2 {" " + detailsObject.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                            {
                                getFundAllocationImageSL(true)
                            }
                            <Text style={{ textAlign: 'right', fontSize: '8', marginRight: '40px' }}> *Funds selected based on your risk profile</Text>
                        </View>
                    }
                </View>
            </View>
            {children}
        </View>
    </>
}
const RSPProduct = ({ flagToShowSAP,element, detailsObject, isCoApplicant, pageNo, getFundAllocationImageSL, children }) => {
    return <>
        <View style={{ height: '100%' }}>
            <View>
                <HeaderLogoAndPageNo pageNo={pageNo} />
                <Text style={{
                    color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
                }}>Your Choice of Products</Text >

                <ProductInsuredDetails element={element} detailsObject={detailsObject} isCoApplicant={isCoApplicant}></ProductInsuredDetails>
                <hr style={{
                    color: '#1a2480',
                    backgroundColor: '#1a2480',
                    height: .3,
                    marginLeft: 40,

                    marginRight: 40,
                    borderColor: '#1a2480'

                }} />

                <View style={styles.tablePeraonalLife}>
                    <View style={styles.tableRow}>
                        <View >
                        </View>
                        <View ></View>
                    </View>



                    <View style={styles.tableRow}>

                        <View>

                            <Text> </Text>

                        </View>

                    </View>



                    <View style={styles.tableRow}>

                        <View style={styles.tableColheader}>

                            <Text style={styles.tableCellHeader199}>Benefit </Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.tableColheader}>

                            <Text style={styles.tableCellHeader199}>Recommended Sum Assured </Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.tableCol3header}>



                            <Text style={styles.tableCellHeader199}>Your Choice of Sum Assured</Text>

                        </View>



                    </View>
                    <View style={styles.tableRow}>

                        <View style={styles.tableCol}>

                            <Text style={styles.tableCell14}>Waiver of Premium</Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.tableCol}>

                            <Text style={styles.tableCell14}>As covered</Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.table3Col}>

                            <Text style={styles.tableCell14}>Not selected</Text>

                        </View>

                    </View>
                    <View style={styles.tableRow}>

                        <View style={styles.tableCol}>

                            <Text style={styles.tableCell14}>Ownership Type</Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.tableCol}>

                            <Text style={styles.tableCell14}>{(element.jointLifeType === 'JLFD') ? 'Joint Life First Death' : 'Single Life'}</Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.table3Col}>

                            <Text style={styles.tableCell14}>{(element.jointLifeType === 'JLFD') ? 'Joint Life First Death' : 'Single Life'}</Text>

                        </View>

                    </View>
                    <View style={styles.tableRow}>

                        <View style={styles.tableCol}>

                            <Text style={styles.tableCell14}></Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.tableCol}>

                            <Text style={styles.tableCell14}>Total Premium*       Yearly</Text>

                        </View>

                        <View >

                            <Text > </Text>

                        </View>

                        <View style={styles.table3Col}>

                            <Text style={styles.tableCell14}>{element?.annualPremium ? element?.annualPremium?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Text>

                        </View>

                    </View>
                </View>
                <View>
                    <Text style={styles.text4114}>Fund Allocation </Text>
                    {
                        getFundAllocationImageSL(flagToShowSAP)
                    }
                    <Text style={{ textAlign: 'right', fontSize: '8', marginRight: '40px' }}> *Funds allocated based on your risk profile</Text>
                    <Text style={{ textAlign: 'right', fontSize: '8', marginRight: '40px' }}> by Sales Team 10 ZAN-Direct</Text>
                </View>

            </View>
            {children}
        </View>
    </>
}
const HeaderLogoAndPageNo = ({ pageNo }) => {
    return <View>
        <Text style={styles.pageNumber}>{pageNo}</Text>
        <Image
            style={styles.image4}
            src={logo}
            alt="Zurich_Logo"
        />
        <hr style={{
            color: '#1a2480',
            backgroundColor: '#1a2480',
            height: 4,
            marginLeft: 40,
            marginRight: 40,
            borderColor: '#1a2480'

        }} />
    </View>
}

const ProductInsuredDetails = ({ element, detailsObject, isCoApplicant }) => {
    return <>
        <Text style={{
            color: "#1a2480", fontSize: 9, marginLeft: 40, padding: 5, fontFamily: 'Helvetica-Bold', marginRight: 40
        }}>{element?.fullName} {element?.dependentName}</Text>
        <View>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>Policy Owner</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}>
                            <Text style={styles.text4}>{detailsObject?.firstName + " " + detailsObject.lastName}</Text>
                        </Text>
                        {(detailsObject?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true && detailsObject?.coApplicant?.firstName) &&
                            <Text style={styles.tableCell}>
                                <Text style={styles.text4}>{detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                            </Text>
                        }
                    </View>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}><Text style={styles.text4}>Life assured</Text></Text>
                    </View>

                    <View style={styles.tableCol9}>
                        {element?.fullName === "Education Saving Plan" && <Text style={styles.tableCell}> <Text style={styles.text4}>{element?.dependentName}</Text></Text>}
                        <Text style={styles.tableCell}> <Text style={styles.text4}>{detailsObject.firstName + " " + detailsObject.lastName}</Text>
                        </Text>
                        {(isCoApplicant === true) &&
                            <Text style={styles.tableCell}> <Text style={styles.text4}>{detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>
                            </Text>}
                    </View>
                </View>

                <View style={styles.tableRow}>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>Ownership Type</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}>
                            <Text style={styles.text4}>{(element.ownershipType === 'JL') ? 'Joint' : 'Single'}</Text>
                        </Text>
                    </View>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}><Text style={styles.text4}>Joint Life Type</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>{(element.jointLifeType === 'JLFD') ? 'Joint Life First Death' : 'Single Life'}</Text></Text>

                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCellchoice}> <Text style={styles.text4}>Policy Term</Text></Text>
                    </View>

                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCellchoice}>
                            <Text style={styles.text4}>{element?.policyTerm === 'Whole of Life' ? element.policyTerm : element?.policyTerm.toString().concat(" ").concat("years")}</Text>
                        </Text>
                    </View>

                    <View style={styles.tableCol19}>
                        <Text style={styles.tableCell}><Text style={styles.text4}>{(element?.name === 'Futura') ? 'Vanishing premium' : ''}</Text></Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}> <Text style={styles.text4}>{(element?.name === 'Futura') ? 'Yes' : ''}</Text></Text>

                    </View>
                </View>
            </View>
        </View>

        <hr style={{
            color: '#1a2480',
            backgroundColor: '#1a2480',
            height: .3,
            marginLeft: 40,

            marginRight: 40,
            borderColor: '#1a2480'

        }} />
    </>
}

const FooterSignature = ({ detailsObject, clientSignature, coApplicantSign, isCoApplicant, latestDate, advisorName }) => {
    return <>
        <View style={styles.footer}>
            {clientSignature ?
                <Image
                    style={styles.signatureSize}
                    src={clientSignature} alt=" client sign" /> : ''}

            <Text style={styles.sign4} fixed>{(isCoApplicant === true) ? detailsObject?.title + " " + detailsObject?.firstName + " " + detailsObject?.lastName : 'Client\'s Signature'}</Text>
            <Text style={styles.textl4} fixed>Generated on {latestDate} </Text>
            {coApplicantSign ?
                <Image
                    style={styles.CoAppsignatureSize}
                    src={coApplicantSign} alt=" client sign" /> : ''}
            {(isCoApplicant === true) && <Text style={styles.sign5} fixed>{detailsObject?.coApplicant?.title + " " + detailsObject?.coApplicant?.firstName + " " + detailsObject?.coApplicant?.lastName}</Text>}
            <Text style={styles.textR4} fixed>Agent: {advisorName}, Zurich Advisory Network
            </Text>
        </View>
    </>
}
export default BasicDocument;

